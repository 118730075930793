import '../styles/markdown-content.scss';
import TextContent from '@amzn/awsui-components-react/polaris/text-content';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkSqueezeParagraphs from 'remark-squeeze-paragraphs';
import remarkBreaks from 'remark-breaks';

// Safe default plugins to make the markdown fancier
const DefaultPlugins = [remarkGfm, remarkBreaks, remarkSqueezeParagraphs];

/**
 * Given the input text, `ReactMarkdown` is used to parse the raw text into markdown/react-node elements
 * which are sanitized (cuz security). The output is wrapped in the {@link https://refresh.polaris.a2z.com/components/text-content/?tabId=api TextContent}
 * component. This applies all AWSUI standard styles and design specs to the html.
 */
const MarkdownContent = ({ children }: { children: string }): JSX.Element => (
  <TextContent>
    <ReactMarkdown remarkPlugins={DefaultPlugins} linkTarget="_blank" className="markdown-content">
      {children}
    </ReactMarkdown>
  </TextContent>
);

export default MarkdownContent;
