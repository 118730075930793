/* eslint-disable no-underscore-dangle */
import { useCallback } from 'react';
import { Hub } from 'aws-amplify';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from './queryKeys';
import {
  GetUserProfileQueryVariables,
  GetUserProfileQuery,
  CreateUserProfileInput,
  CreateUserProfileMutation,
  UpdateUserProfileInput,
  UpdateUserProfileMutation,
  UserProfile as UserProfileModel,
  VisualMode,
  Density,
  GetUserProfileWithIdentityQuery,
  UserProfileStatus,
} from './API';
import { getUserProfile, getUserProfileWithIdentity } from '@/graphql/queries';
import { createUserProfile, updateUserProfile } from '@/graphql/mutations';
import { OptionalString, PromoUserProfile } from '@/models';
import { AbstractModel, getConcreteModels, useApiMutation, useApiQuery } from '@/backend/api';
import { useCognitoAuth } from '@/common/hooks/use-auth';

const ITEM_TYPE = 'profile';

window.LOG_LEVEL = 'DEBUG';

type Model =
  | UserProfileModel
  | NonNullable<GetUserProfileQuery['getUserProfile']>
  | NonNullable<GetUserProfileWithIdentityQuery['getUserProfile']>;

function isValidModel(model: AbstractModel<Model>): boolean {
  return !!model && !model._deleted;
}

function getPromoUserFromModel(model: Model): PromoUserProfile {
  return {
    alias: model.alias,
    email: model.email,
    firstName: model.name?.split(' ')[0] ?? '',
    lastName: model.name?.split(' ').pop() ?? '',
    jobLevel: model.jobLevel,
    jobTitle: model.jobTitle ?? '',
    isManager: model.isManager,
    manager: {
      alias: model.manager,
      name: model.managerProfile?.name ?? '',
    },
    name: model.name ?? '',
    identityId: 'identityId' in model ? model.identityId : undefined,
    orgName: model.orgName ?? '',
    personId: model.personId ?? '',
    version: model._version ?? 1,
    isValidUser: true,
    status: model.status ?? UserProfileStatus.ACTIVE,
    hasLoggedIn: model.hasLoggedIn ?? false,
    preferences: {
      visualMode: model.preferences?.visualMode,
      density: model.preferences?.density,
    },
  };
}

export function useUserProfile(alias?: OptionalString, isCurrentUser = false) {
  const { getItem } = useApiQuery();
  const queryClient = useQueryClient();

  const getQueryParams = useCallback(
    (userAlias: OptionalString, includeIdentity: boolean) => ({
      meta: { errorMessage: `Error fetching profile for user ${userAlias}@` },
      queryKey: QueryKeys.profile.alias(userAlias),
      queryFn: async () => {
        if (!userAlias) return null;
        const data = await getItem<GetUserProfileQueryVariables, GetUserProfileQuery>({
          query: includeIdentity ? getUserProfileWithIdentity : getUserProfile,
          input: { alias: userAlias },
        });
        const models = getConcreteModels(data?.getUserProfile, isValidModel);
        return models?.length ? getPromoUserFromModel(models[0]) : null;
      },
    }),
    [getItem]
  );

  const query = useQuery({
    ...getQueryParams(alias, isCurrentUser),
    enabled: !!alias,
  });

  const getProfile = useCallback(
    async (userAlias: OptionalString, includeIdentity = false) =>
      queryClient.fetchQuery({ ...getQueryParams(userAlias, includeIdentity) }),
    [queryClient, getQueryParams]
  );

  const onFetchLatest = useCallback(async () => (await query.refetch()).data, [query]);

  return {
    getProfile,
    profile: query.data,
    isProfileLoading: query.isLoading,
    fetchLatest: onFetchLatest,
  };
}

export function useProfileActions(alias?: OptionalString) {
  const { updateItem, createItem } = useApiMutation();
  const { session } = useCognitoAuth();
  const { getProfile, isProfileLoading } = useUserProfile(alias, session?.user?.alias === alias);

  type UpdateParams = Partial<Omit<UpdateUserProfileInput, '_version'>>;

  const createMutation = useMutation({
    mutationFn: async (params: CreateUserProfileInput) => {
      const input: CreateUserProfileInput = { ...params, status: params.status ?? UserProfileStatus.ACTIVE };
      const data = await createItem<CreateUserProfileMutation>(createUserProfile, input, ITEM_TYPE);
      return data?.createUserProfile ? getPromoUserFromModel(data.createUserProfile as UserProfileModel) : null;
    },
  });

  const updateMutation = useMutation({
    mutationFn: async (params: UpdateParams) => {
      const profile = await getProfile(params.alias ?? alias);
      if (!profile) return null;
      const input: UpdateUserProfileInput = { ...params, alias: profile.alias, _version: profile?.version ?? 1 };
      const data = await updateItem<UpdateUserProfileMutation>(updateUserProfile, input, ITEM_TYPE);
      return data?.updateUserProfile ? getPromoUserFromModel(data.updateUserProfile as UserProfileModel) : null;
    },
  });

  const savePreferences = useCallback(
    async (visualMode: VisualMode, density: Density) => {
      const data = await updateMutation.mutateAsync({ preferences: { visualMode, density } });
      if (data) {
        Hub.dispatch('PromoNotification', {
          event: 'success',
          message: `Successfully updated user preferences.`,
        });
      }
    },
    [updateMutation]
  );

  const createProfile = useCallback(
    async (params: CreateUserProfileInput) => createMutation.mutateAsync(params),
    [createMutation]
  );

  const updateProfile = useCallback(
    async (params: UpdateParams) => updateMutation.mutateAsync(params),
    [updateMutation]
  );

  return {
    savePreferences,
    createProfile,
    updateProfile,
    isProfileMutating: createMutation.isLoading || updateMutation.isLoading || isProfileLoading,
  };
}
