import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@amzn/awsui-components-react/polaris/box';
import ContentLayout from '@amzn/awsui-components-react/polaris/content-layout';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Tabs from '@amzn/awsui-components-react/polaris/tabs';
import { CrossTableContextProvider } from '@/contexts/CrossTableContext';
import WorkSummaryReviewTable from '../components/WorkSummary/WorkSummaryReviewTable';
import PromoPathProgressTable from '../components/PromoPathProgress/PromoPathProgressTable';
import { useAppContext } from '@/contexts/AppContext';
import { useDirectReports } from '@/api/amzn-people';
import PromoPathTable from '../components/PromoPath/PromoPathTable';
import { allPages, CandidateProgressPage, ReviewWorkSummariesPage, ManagePromoPathsPage } from '@/common/pages';
import { DirectReportRecord, Nullable, PromoUserProfile } from '@/models';
import useNavigator from '@/common/hooks/use-navigator';

const PageHeader = () => <Header variant="h1">Manager dashboard</Header>;

interface ContentParams {
  directReports: DirectReportRecord[] | undefined;
  manager: Nullable<PromoUserProfile>;
  landingTabId: string;
  onChangeTabId: (v: string) => void;
  isLoading: boolean;
}

const Content = ({
  directReports,
  manager,
  onChangeTabId,
  isLoading,
  landingTabId = CandidateProgressPage.id,
}: ContentParams) => {
  return (
    <SpaceBetween direction="vertical" size="s">
      <Box>
        <Header variant="h3">{`Hi ${manager ? manager?.firstName : ''}!`}</Header>
        <Box variant="p">
          <span>
            From this page you can create, edit, share, assign, and monitor Promo Path activities of your reportees.
          </span>
        </Box>
      </Box>
      <Tabs
        variant="container"
        activeTabId={landingTabId}
        onChange={({ detail }) => onChangeTabId(detail.activeTabId)}
        tabs={[
          {
            label: 'Candidate progress',
            id: CandidateProgressPage.id,
            content: (
              <PromoPathProgressTable
                directReports={directReports ?? []}
                isParentLoading={isLoading}
                manager={manager}
              />
            ),
          },
          {
            label: 'Work summary reviews',
            id: ReviewWorkSummariesPage.id,
            content: (
              <WorkSummaryReviewTable
                directReports={directReports ?? []}
                isParentLoading={isLoading}
                manager={manager}
              />
            ),
          },
          {
            label: 'Manage promo paths',
            id: ManagePromoPathsPage.id,
            content: <PromoPathTable user={manager} />,
          },
        ]}
      />
    </SpaceBetween>
  );
};

interface StateParams {
  tabId?: string;
}
type LocationState = { state: StateParams };

interface ManagerDashboardParams {
  tabId: string;
}

const ManagerDashboard = ({ tabId }: ManagerDashboardParams) => {
  const { currentUser, spoofUser } = useAppContext();
  const { state }: LocationState = useLocation();
  const activeTabId = useMemo(() => state?.tabId ?? tabId ?? CandidateProgressPage.id, [state?.tabId, tabId]);
  const { directReports, isDirectReportsLoading } = useDirectReports(spoofUser?.alias ?? currentUser?.alias);
  const { goToPage } = useNavigator();

  const onSetActiveTabId = useCallback(
    (requestedTabId: string) => {
      const page = allPages.find((opt) => opt.id === requestedTabId);
      goToPage(page, { tabId: requestedTabId });
    },
    [goToPage]
  );

  return (
    <CrossTableContextProvider>
      <ContentLayout disableOverlap header={<PageHeader />}>
        <Content
          directReports={directReports}
          manager={spoofUser ?? currentUser}
          landingTabId={activeTabId}
          onChangeTabId={onSetActiveTabId}
          isLoading={isDirectReportsLoading}
        />
      </ContentLayout>
    </CrossTableContextProvider>
  );
};

export default ManagerDashboard;
