import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks/dist/mjs/interfaces';
import { DEFAULT_FILES_PER_PAGE, RESOURCES } from '@/common/constants';
import {
  getPageSizes,
  getPreferences,
  getFilteredColumns,
  getTableDefinition,
} from '@/components/common/table/table-config-helper';
import { ColumnDefinition, PromoTableConfig, FileRecordItem, PromoTableDefinition, PropertyDefinition } from '@/models';
import { getFormattedDate } from '@/common/utils';
import {
  DatePropertyFilter,
  NumericPropertyFilter,
  StringPropertyFilter,
} from '@/components/common/table/table-filters';
import { formatFileSize } from '@/components/common/file-upload-helper';

/** Identifier for this table. Also used as the key for tracking context. */
export const TABLE_KEY = 'Files';

const StringKeys: (keyof FileRecordItem)[] = ['id', 'name'];
const NumericKeys: (keyof FileRecordItem)[] = ['size'];
const DateKeys: (keyof FileRecordItem)[] = ['fileDateModified', 'uploadedAt'];

const properties: { [Property in keyof Partial<FileRecordItem>]: PropertyDefinition } = {
  name: {
    key: 'name',
    label: 'File name',
  },
  size: {
    key: 'size',
    label: 'Size',
  },
  uploadedAt: {
    key: 'uploadedAt',
    label: 'Uploaded',
  },
  fileDateModified: {
    key: 'fileDateModified',
    label: 'File last modified',
  },
};

/** Columns to render on the `PromoPathProgress` management table. Columns are displayed in order. */
const STATIC_COLUMN_DEFINITIONS: ColumnDefinition<FileRecordItem>[] = [
  {
    id: properties.name?.key as string,
    header: properties.name?.label as string,
    cell: (e) => e.name,
    sortingField: properties.name?.key,
    minWidth: 250,
  },
  {
    id: properties.size?.key as string,
    header: properties.size?.label as string,
    cell: (e) => (e.size > 0 ? formatFileSize(e.size) : '-'),
    sortingField: properties.size?.key,
    width: 120,
  },
  {
    id: properties.uploadedAt?.key as string,
    header: properties.uploadedAt?.label as string,
    cell: (e) => getFormattedDate(e.uploadedAt) || '-',
    sortingField: properties.uploadedAt?.key,
  },
  {
    id: properties.fileDateModified?.key as string,
    header: properties.fileDateModified?.label as string,
    cell: (e) => getFormattedDate(e.fileDateModified) || '-',
    sortingField: properties.fileDateModified?.key,
  },
];

const ColumnDefinitions = (): ColumnDefinition<FileRecordItem>[] => {
  return [...STATIC_COLUMN_DEFINITIONS];
};

export function getPropertyFilters() {
  const stringFilters = StringKeys.map((key) => StringPropertyFilter(properties[key]?.key, properties[key]?.label));
  const numericFilters = NumericKeys.map((key) => {
    if (key === 'size') {
      return NumericPropertyFilter(
        properties[key]?.key,
        properties[key]?.label,
        (v: number) => formatFileSize(v) ?? ''
      );
    }
    return NumericPropertyFilter(properties[key]?.key, properties[key]?.label);
  });
  const dateFilters = DateKeys.map((key) => DatePropertyFilter(properties[key]?.key, properties[key]?.label));
  return [...stringFilters, ...numericFilters, ...dateFilters].filter(
    (i): i is PropertyFilterProperty => i !== undefined
  );
}

const FileRecordTableDef = getTableDefinition(TABLE_KEY, RESOURCES.FILERECORD);

// Type alias for readability in code. Contains same props as parent.
type FileRecordTableConfig = PromoTableConfig<PromoTableDefinition, FileRecordItem>;

/**
 * Class to set the table configuration for `PromoPathProgress` table.
 * See parent: {@link PromoTableConfig}
 */
const CreateTableConfig = (): FileRecordTableConfig => {
  return {
    tableDef: FileRecordTableDef,
    allColumns: ColumnDefinitions(),
    pageSizes: getPageSizes(),
    displayableColumns: getFilteredColumns(ColumnDefinitions()),
    defaultPreferences: getPreferences(DEFAULT_FILES_PER_PAGE, getFilteredColumns(ColumnDefinitions())),
  };
};

/** Hook to fetch a new instance of the table config. */
export function useTableConfig() {
  return CreateTableConfig();
}
