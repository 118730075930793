/* eslint-disable no-underscore-dangle */
import { useCallback, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  CreateCategoryMutation,
  UpdateCategoryMutation,
  ListCategoriesByRoleQuery,
  ListCategoriesByRoleQueryVariables,
  Category as CategoryModel,
  GetCategoryQueryVariables,
  GetCategoryQuery,
  DeleteCategoryMutation,
  DeleteCategoryInput,
  UpdateCategoryInput,
  CategoryStatus,
  CreateCategoryInput,
} from './API';
import { listCategoriesByRole, getCategory as getCategoryQuery } from '../graphql/queries';
import {
  createCategory as createCategoryMutation,
  updateCategory as updateCategoryMutation,
  deleteCategory as deleteCategoryMutation,
} from '../graphql/mutations';
import { CategoryItem, CategoryResource, OptionalString } from '@/models';
import { useApiMutation, useApiQuery } from '@/backend/api';
import { QueryKeys } from './queryKeys';
import { CategoryActions, UseCategoriesResult, UseCategoryActionsResult, UseCategoryResult } from './models';
import { getCompactId } from '@/common/utils';
import { useNotifications } from '@/contexts';

type Model = CategoryModel | NonNullable<GetCategoryQuery['getCategory']>;

function getItemFromModel(model: Partial<CategoryModel>): CategoryItem {
  return {
    id: model.id ?? '',
    label: model.name ?? '',
    version: model._version ?? 1,
  };
}

function getResourceFromModel(model: Model): CategoryResource {
  return {
    ...getItemFromModel(model),
    description: model.description,
    isDefault: !!model.isDefault,
  };
}

export function useCategory(id: OptionalString): UseCategoryResult {
  const { getItem } = useApiQuery();

  const queryClient = useQueryClient();

  const getQueryParams = useCallback(
    (categoryId: OptionalString) => ({
      meta: { errorMessage: `Error fetching category: ${getCompactId(categoryId)}` },
      queryKey: QueryKeys.category.id(categoryId),
      queryFn: async () => {
        if (!categoryId) return null;
        const data = await getItem<GetCategoryQueryVariables, GetCategoryQuery>({
          query: getCategoryQuery,
          input: { id: categoryId },
        });
        return data?.getCategory ? getResourceFromModel(data.getCategory) : null;
      },
    }),
    [getItem]
  );

  const query = useQuery({
    ...getQueryParams(id),
    enabled: !!id,
  });

  const getCategory = useCallback(
    async (categoryId: OptionalString) => queryClient.fetchQuery({ ...getQueryParams(categoryId) }),
    [queryClient, getQueryParams]
  );

  const onFetchLatest = useCallback(async () => (await query.refetch()).data, [query]);

  return {
    getCategory,
    category: query.data,
    isCategoryLoading: !!id && query.isLoading,
    fetchLatest: onFetchLatest,
  };
}

export function useCategories(role: string): UseCategoriesResult {
  const { getItems } = useApiQuery();

  const defaultQuery = useQuery({
    meta: { errorMessage: `Error fetching categories for ${role} role` },
    queryKey: QueryKeys.category.role(role),
    queryFn: async () => {
      const data = await getItems<ListCategoriesByRoleQueryVariables, ListCategoriesByRoleQuery>({
        query: listCategoriesByRole,
        input: { role, isDefault: { eq: 'true' }, filter: { status: { ne: CategoryStatus.DELETED } } },
      });
      return (data?.listCategoriesByRole?.items ?? [])
        .filter((item): item is CategoryModel => !!item && !item._deleted)
        .map((item) => getResourceFromModel(item));
    },
    enabled: !!role,
  });

  return {
    defaultCategories: defaultQuery.data ?? [],
    isCategoriesLoading: !!role && defaultQuery.isLoading,
  };
}

export function useCategoryActions(id?: OptionalString): UseCategoryActionsResult {
  const { createItem, updateItem, deleteItem } = useApiMutation();
  const { getCategory } = useCategory(id);
  const { addNotification } = useNotifications();
  const queryClient = useQueryClient();

  type CreateParams = Parameters<CategoryActions['create']>[0];
  type UpdateParams = Parameters<CategoryActions['update']>[0];

  const onNotifySuccess = useCallback(
    (action: string, name: OptionalString, categoryId: OptionalString) => {
      addNotification({
        type: 'success',
        header: `Successfully ${action}ed category: ${name} (${getCompactId(categoryId)})`,
      });
    },
    [addNotification]
  );

  const createMutation = useMutation({
    mutationFn: async (params: CreateParams) => {
      const input: CreateCategoryInput = { ...params, status: CategoryStatus.ACTIVE };
      const result = await createItem<CreateCategoryMutation>(createCategoryMutation, input, 'category');
      return result?.createCategory ? getResourceFromModel(result.createCategory as CategoryModel) : null;
    },
    onSuccess: (result) => {
      onNotifySuccess('create', result?.label, result?.id);
      queryClient.invalidateQueries(QueryKeys.category.all);
    },
  });

  const updateMutation = useMutation({
    mutationFn: async (params: UpdateParams) => {
      const category = await getCategory(params.id ?? id);
      if (!category) return null;
      const input: UpdateCategoryInput = { ...params, id: category.id, _version: category.version ?? 1 };
      const result = await updateItem<UpdateCategoryMutation>(updateCategoryMutation, input, 'category');
      return result?.updateCategory ? getResourceFromModel(result.updateCategory as CategoryModel) : null;
    },
    onSuccess: (result) => {
      onNotifySuccess('update', result?.label, result?.id);
      queryClient.invalidateQueries(QueryKeys.category.all);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async (categoryId: OptionalString) => {
      const category = await getCategory(categoryId ?? id);
      if (!category) return null;
      const updateParams: UpdateCategoryInput = {
        id: category.id,
        status: CategoryStatus.DELETED,
        _version: category?.version,
      };
      const updateResult = await updateItem<UpdateCategoryMutation>(updateCategoryMutation, updateParams);
      const input: DeleteCategoryInput = { id: category.id, _version: updateResult?.updateCategory?._version };
      const result = await deleteItem<DeleteCategoryMutation>(deleteCategoryMutation, input, 'category');
      return result?.deleteCategory ?? null;
    },
    onSuccess: (result) => {
      onNotifySuccess('delete', result?.name, result?.id);
      queryClient.invalidateQueries(QueryKeys.category.all);
    },
  });

  const onCreate = useCallback(async (params: CreateParams) => createMutation.mutateAsync(params), [createMutation]);
  const onUpdate = useCallback(async (params: UpdateParams) => updateMutation.mutateAsync(params), [updateMutation]);
  const onDelete = useCallback(async (catId: OptionalString) => deleteMutation.mutateAsync(catId), [deleteMutation]);

  const actions = useMemo(
    () => ({ create: onCreate, update: onUpdate, delete: onDelete }),
    [onCreate, onUpdate, onDelete]
  );

  return {
    actions,
    isMutating: createMutation.isLoading || updateMutation.isLoading || deleteMutation.isLoading,
  };
}

export const getCategoryItemFromModel = getItemFromModel;

export const getCategoryResourceFromModel = getResourceFromModel;
