import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { PeerReviewStatus } from '@/api/API';
import { RESOURCES } from '@/common/constants';
import { getNameDefinition } from '@/common/labels';
import {
  PeerReviewItem,
  TableHeaderParams,
  PromoTableDefinition,
  HeaderActionSetParams,
  ItemAction,
  ActionDefinition,
} from '@/models';
import { PromoButton } from '@/components/common/Buttons';
import TableHeader from '@/components/common/table/TableHeader';
import { EditPeerReviewPage } from '@/common/pages';

const CounterText = (items: readonly PeerReviewItem[]) => {
  const totalItems = items && items.length > 0 ? items.length : 0;
  // Per AWS Style Guide always set to 0 if no items.
  if (totalItems === 0) {
    return '(0)';
  }
  return `(${totalItems})`;
};

export function getReviewAction(): ActionDefinition {
  return {
    action: 'review',
    targetType: 'route',
    actionParams: { page: EditPeerReviewPage },
  };
}

const Resource = RESOURCES.PEER_REVIEW;
const ResourceName = getNameDefinition(Resource);

/** Params - {@link HeaderActionSetParams} */
export const ActionSet = ({ selectedItem, actionDefs }: HeaderActionSetParams<PeerReviewItem>) => {
  const actionToIsDisabled: Partial<{ [K in ItemAction]: boolean }> = {
    create: false,
    review: !selectedItem || selectedItem.reviewStatus !== PeerReviewStatus.REQUESTED,
    submit: !selectedItem || selectedItem.reviewStatus !== PeerReviewStatus.REQUESTED,
  };
  const actions: ItemAction[] = ['review', 'submit'];
  // Action buttons have a specific order of precedence, which the caller doesn't need to know about
  // so deliberately order based on the setup specific to this action set.
  const orderedActionDefs: ActionDefinition[] = [];
  actions.forEach((action) => {
    let actionDef = actionDefs.find((def) => def.action === action);
    if (actionDef) {
      if (actionDef.action === 'review') {
        actionDef = {
          ...actionDef,
          actionParams: {
            ...actionDef.actionParams,
            itemId: selectedItem?.id,
          },
        };
      }
      orderedActionDefs.push(actionDef);
    }
  });

  return (
    <SpaceBetween direction="horizontal" size="s">
      {orderedActionDefs.map((def) => (
        <PromoButton
          key={`feedbacklist.action.${def.action}`}
          action={def.action}
          variant={def.action === 'review' ? 'primary' : undefined}
          actionParams={def.actionParams}
          isDisabled={actionToIsDisabled[def.action]}
          resourceName={ResourceName}
          targetType={def.targetType}
        />
      ))}
    </SpaceBetween>
  );
};

/**
 * Table header component for a `WorkSummary` table.
 * Header text consists of the `tableKey` from {@link PromoTableDefinition}, and the total item count.
 * When all available summaries are being shown, the header will indicate the total `Approved` summaries.
 *
 * Returns a {@link https://refresh.polaris.a2z.com/components/header/?tabId=playground Polaris Header}
 */
const PeerReviewHeader = ({
  items,
  ...params
}: TableHeaderParams<PromoTableDefinition, PeerReviewItem>): JSX.Element => {
  return <TableHeader {...params} counter={CounterText(items || [])} actionSet={ActionSet} />;
};

export const getHeaderComponent = () => PeerReviewHeader;
