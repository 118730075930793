import Select from '@amzn/awsui-components-react/polaris/select';
import Input from '@amzn/awsui-components-react/polaris/input';
import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks';
import { DEFAULT_FEEDBACKS_PER_PAGE, RESOURCES } from '@/common/constants';
import {
  getPageSizes,
  getFilteredColumns,
  getTableDefinition,
  getPreferences,
} from '@/components/common/table/table-config-helper';

import {
  ColumnDefinition,
  PropertyDefinition,
  PromoTableDefinition,
  PromoTableConfig,
  WorkSummaryAdminItem,
} from '@/models';
import { getFormattedDate } from '@/common/utils';
import { DatePropertyFilter, StringPropertyFilter } from '@/components/common/table/table-filters';
import { WorkSummaryStatus } from '@/api/API';

/** Identifier for this table. Also used as the key for tracking context. */
export const TABLE_KEY = 'Work summaries';

type ItemDefinition = WorkSummaryAdminItem;

const StringKeys: (keyof WorkSummaryAdminItem)[] = ['title', 'alias', 'managerAlias', 'status', 'createdAt'];
const DateKeys: (keyof WorkSummaryAdminItem)[] = ['createdAt'];

const workSummaryStatuses = [
  WorkSummaryStatus.DRAFT,
  WorkSummaryStatus.PEER_REVIEW,
  WorkSummaryStatus.MANAGER_REVIEW,
  WorkSummaryStatus.MANAGER_PEER_REVIEW,
  WorkSummaryStatus.APPROVED,
  WorkSummaryStatus.DELETED,
];

const properties: { [Property in keyof Partial<WorkSummaryAdminItem>]: PropertyDefinition } = {
  title: {
    key: 'title',
    label: 'Work summary title',
  },
  alias: {
    key: 'alias',
    label: 'Candidate alias',
  },
  managerAlias: {
    key: 'managerAlias',
    label: 'Manager alias',
  },
  status: {
    key: 'status',
    label: 'Status',
  },
  createdAt: {
    key: 'createdAt',
    label: 'Created date',
  },
};

const STATIC_COLUMN_DEFINITIONS: ColumnDefinition<ItemDefinition>[] = [
  {
    id: properties.title?.key as string,
    header: properties.title?.label as string,
    cell: (e) => e.title,
    minWidth: 10,
    sortingField: properties.title?.key as string,
  },
  {
    id: properties.alias?.key as string,
    header: properties.alias?.label as string,
    cell: (e) => e.alias,
    minWidth: 12,
    sortingField: properties.alias?.key as string,
  },
  {
    id: properties.managerAlias?.key as string,
    header: properties.managerAlias?.label as string,
    cell: (e) => e.managerAlias,
    minWidth: 12,
    sortingField: properties.managerAlias?.key as string,
    editConfig: {
      ariaLabel: 'Manager alias',
      editIconAriaLabel: 'editable',
      errorIconAriaLabel: 'Manager alias error',
      editingCell: (item, { currentValue, setValue }) => {
        return (
          <Input
            autoFocus={false}
            value={currentValue ?? item.managerAlias}
            onChange={(event) => setValue(event.detail.value)}
          />
        );
      },
    },
  },
  {
    id: properties.status?.key as string,
    header: properties.status?.label as string,
    cell: (e) => e.status,
    minWidth: 15,
    sortingField: properties.status?.key as string,
    editConfig: {
      ariaLabel: 'Status',
      editIconAriaLabel: 'editable',
      errorIconAriaLabel: 'Status Error',
      editingCell: (item, { currentValue, setValue }) => {
        const value = currentValue ?? item.status;
        return (
          <Select
            autoFocus={false}
            expandToViewport
            selectedOption={
              workSummaryStatuses.map((key) => ({ label: key, value: key })).find((option) => option.value === value) ??
              null
            }
            onChange={(event) => {
              setValue(event.detail.selectedOption.value ?? item.status);
            }}
            options={workSummaryStatuses.map((key) => ({ label: key, value: key }))}
          />
        );
      },
    },
  },
  {
    id: properties.createdAt?.key as string,
    header: properties.createdAt?.label as string,
    cell: (e) => getFormattedDate(e.createdAt) || '-',
    sortingField: properties.createdAt?.key as string,
  },
];

const ColumnDefinitions = <ItemDef extends ItemDefinition>(): ColumnDefinition<ItemDef>[] => {
  return [...STATIC_COLUMN_DEFINITIONS];
};

/**
 * Definition of the keys and resource(s) used in the `AdminWorkSummaryTable`.
 * See parent: {@link PromoTableDefinition}
 */
const AdminWorkSummaryTableDef = getTableDefinition(TABLE_KEY, RESOURCES.WORKSUMMARY);

type AdminWorkSummaryTableConfig = PromoTableConfig<PromoTableDefinition, WorkSummaryAdminItem>;

/**
 * Functional Component to set the table configuration for all tables.
 * The {@link ViewModeDefinition viewMode} specified will define what/how things are displayed.
 * See parent: {@link PromoTableConfig}
 */
const CreateTableConfig = (): AdminWorkSummaryTableConfig => {
  return {
    tableDef: AdminWorkSummaryTableDef,
    allColumns: ColumnDefinitions(),
    pageSizes: getPageSizes(),
    displayableColumns: getFilteredColumns(ColumnDefinitions()),
    defaultPreferences: getPreferences(DEFAULT_FEEDBACKS_PER_PAGE, getFilteredColumns(ColumnDefinitions())),
  };
};

export function getPropertyFilters() {
  const stringFilters = StringKeys.map((key) => StringPropertyFilter(properties[key]?.key, properties[key]?.label));
  const dateFilters = DateKeys.map((key) => DatePropertyFilter(properties[key]?.key, properties[key]?.label));

  return [...stringFilters, ...dateFilters].filter((i): i is PropertyFilterProperty => i !== undefined);
}

/** Hook to fetch a new instance of the table config. */
export function useTableConfig() {
  return CreateTableConfig();
}
