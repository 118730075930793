import { Hub, Logger, Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { Nullable, OptionalString } from '@/models';

type StorageAccessLevel = 'private' | 'protected';
const logger = new Logger('API');
const DefaultStorageLevel: StorageAccessLevel = 'private';

/**
 * Type declaration for Amplify's S3 Download config object
 * The real type isn't exposed for import so copied.
 */
type S3DownloadConfig = {
  download: boolean;
  level: 'public' | 'private' | 'protected';
  contentDisposition?: string;
  identityId?: string;
};

/**
 * Retrieves an S3 presigned link for the given object key.
 * See also {@link https://docs.amplify.aws/lib/storage/download/q/platform/js/ Amplify Docs}
 * @param s3Key Relative S3 key to download
 * @param fileName Object's file name from the `FileRecord` DynamoDb table - this is how the user knows the file.
 * @param userIdentityId (optional) Cognito Identity ID of the user who owns the file. Required if downloading someone else's file
 * @returns Presigned S3 URL
 */
export async function getDownloadLink(
  s3Key: string,
  fileName: string,
  userIdentityId?: OptionalString
): Promise<OptionalString> {
  const config: S3DownloadConfig = {
    download: false, // ensures we just get a link and not the data
    level: DefaultStorageLevel,
    // Pass-thru - Sets this in the content disposition response header.
    // This ensures when the file downloads, it gets the actual file name, and not the S3 key's GUID.
    contentDisposition: `attachment; filename="${fileName}"`,
  };
  let presignedUrl: OptionalString;
  // Pass the identity ID in if provided. Otherwise Amplify uses the current user.
  config.identityId = userIdentityId || undefined;
  try {
    presignedUrl = await Storage.get(s3Key, config);
  } catch (ex) {
    logger.error('Error getting presigned link.', ex);
    Hub.dispatch('PromoNotification', {
      event: 'error',
      message: `Error getting presigned link for file: ${fileName}. ${ex}`,
    });
  }
  return presignedUrl;
}

/**
 * Upload a file from the user's local storage to the S3 artifacts bucket.
 * The file is uploaded with the `private` storage level, meaning only the uploading user can retrieve it
 * OR someone with the uploading user's unique cognito ID.
 * @param item The input file the user selected to upload
 * @param itemId A GUID which uniquely represents the file. (optional - created if not specified)
 * @returns The relative S3 key of the uploaded file, or `undefined` if upload failed / wasn't attempted
 */
export async function uploadFile(item: Nullable<File>, itemId?: string): Promise<OptionalString> {
  if (!item) return undefined;
  // Extract the file extension from the name. This assumes the extension is everythign after the last dot '.'
  const extension = item.name.split('.').length > 1 ? `.${item.name.split('.').slice(-1)}` : '';
  // Create the S3 Key as the item's GUID + extension. This ensures data privacy as file names are PII
  const keyName = `${itemId || uuidv4()}${extension}`;
  let s3Key: string | undefined;
  let resultType = 'success';
  let resultMessage: string;
  try {
    const result = await Storage.put(keyName, item, {
      contentType: item.type,
      level: DefaultStorageLevel,
      metadata: {
        // set the original name as an S3 metadata value for later reference.
        originalFileName: item.name,
        // set the file's modified date as an S3 metadata value for later reference.
        originalDateModified: item.lastModified.toString(),
      },
    });
    resultMessage = `Successfully uploaded file: ${item.name}.`;
    s3Key = result.key;
  } catch (ex) {
    resultType = 'error';
    resultMessage = `Error uploading file: ${item.name}. ${ex}`;
    logger.error('Error uploading to S3.', ex);
  }
  // Push the result to the notification event listener to deliver a toast to the user.
  Hub.dispatch('PromoNotification', {
    event: resultType,
    message: resultMessage,
  });
  return s3Key;
}

/**
 * Uploads multiple files to S3, providing a map of the file name to the S3 key created.
 * @param items List of `PromoFile` objects to upload to S3
 * @returns A list of dictionary-like objects mapping the file name to the S3 Key
 */
export async function uploadFiles(items: Nullable<File[]>): Promise<Map<string, string>> {
  if (!items?.length) return new Map();
  const fileNameToKey = new Map<string, string>();
  const results = await Promise.allSettled(
    items.map(async (item) => {
      const s3Key = await uploadFile(item);
      return [item.name, s3Key] as const;
    })
  );
  results
    .filter(
      (result): result is PromiseFulfilledResult<readonly [string, OptionalString]> => result.status === 'fulfilled'
    )
    .forEach((result) => {
      if (result.status[1]) {
        fileNameToKey.set(result.status[0], result.status[1]);
      }
    });
  return fileNameToKey;
}

/**
 * Removes a file from S3 storage bucket.
 * @param s3Key The relative S3 Key of the object
 * @param fileName The file name of the object (how the user knows the file)
 */
export async function deleteFile(s3Key: string, fileName: string, notifyOnSuccess = true) {
  if (!s3Key) return;
  let resultType = 'success';
  let resultMessage: string;
  try {
    // By default all objects are private. Storage level must be specified as
    // it determines where in the bucket Amplify stored the S3 key (the key is a relative path)
    await Storage.remove(s3Key, { level: DefaultStorageLevel });
    resultMessage = `Successfully deleted file: ${fileName}.`;
  } catch (ex) {
    resultType = 'error';
    resultMessage = `Error deleting file: ${fileName}. ${ex}`;
    logger.error('Error deleting from S3.', ex);
  }
  // Push the result to the notification event listener to deliver a toast to the user.
  if (resultType === 'error' || notifyOnSuccess) {
    Hub.dispatch('PromoNotification', { event: resultType, message: resultMessage });
  }
}
