import Button from '@amzn/awsui-components-react/polaris/button';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import {
  FileRecordItem,
  HeaderActionSetParams,
  TableHeaderParams,
  PromoTableDefinition,
  ActionDefinition,
} from '@/models';
import TableHeader from '@/components/common/table/TableHeader';
import { PromoButton } from '@/components/common/Buttons';
import { useFileRecordActions } from '@/api/file-record';
import { useAppContext } from '@/contexts';

export function getActionDefinitions(onCreate: () => void, onDelete: () => void) {
  const actionDefs: ActionDefinition[] = [
    {
      action: 'upload',
      targetType: 'function',
      actionParams: {
        doAction: onCreate,
      },
    },
    {
      action: 'delete',
      targetType: 'function',
      actionParams: {
        doAction: onDelete,
      },
    },
  ];
  return actionDefs;
}

/** Params - {@link HeaderActionSetParams} */
const ActionSet = ({ selectedItem, resourceName, actionDefs }: HeaderActionSetParams<FileRecordItem>) => {
  // Dynamically set the button state based on whether an item from the table is selected
  const { spoofUser } = useAppContext();
  const { actions, isPreparing } = useFileRecordActions(selectedItem?.id);

  return (
    <SpaceBetween direction="horizontal" size="s">
      <Button
        variant="normal"
        disabled={!selectedItem}
        iconName="download"
        loading={isPreparing}
        onClick={() => actions.download()}
      >
        Download
      </Button>
      <PromoButton
        action="delete"
        targetType={actionDefs.find((def) => def.action === 'delete')?.targetType || 'function'}
        resourceName={resourceName}
        isDisabled={!selectedItem}
        actionParams={actionDefs.find((def) => def.action === 'delete')?.actionParams}
      />
      <PromoButton
        action="upload"
        isDisabled={!!spoofUser}
        targetType={actionDefs.find((def) => def.action === 'upload')?.targetType || 'function'}
        resourceName={resourceName}
        actionParams={actionDefs.find((def) => def.action === 'upload')?.actionParams}
      />
    </SpaceBetween>
  );
};

/**
 * Table header component for the `FileRecord` table.
 * Returns a {@link https://polaris.a2z.com/components/header/?tabId=playground Polaris Header}
 */
export const FileRecordHeader = (params: TableHeaderParams<PromoTableDefinition, FileRecordItem>): JSX.Element => {
  return <TableHeader {...params} actionSet={ActionSet} />;
};

export const getHeaderComponent = () => FileRecordHeader;
