import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

const RETRYABLE_ERRORS = ['no current user', 'invalid_grant', 'refresh token has expired'];
const FEDERATED_IDENTITY_PROVIDER = 'AmazonFederate';

const oauthParams: Record<string, string | string[]> = {
  responseType: 'code',
  scope: ['aws.cognito.signin.user.admin', 'openid', 'email', 'profile'],
  redirectSignIn: 'http://localhost:3000/',
  redirectSignOut: 'http://localhost:3000/',
};

switch (import.meta.env.REACT_APP_BUILD_ENV) {
  case 'prod':
    oauthParams.redirectSignIn = 'https://promohub.support.aws.dev/';
    oauthParams.redirectSignOut = 'https://promohub.support.aws.dev/';
    break;
  case 'staging':
    oauthParams.redirectSignIn = 'https://staging.promohub.support.aws.dev/';
    oauthParams.redirectSignOut = 'https://staging.promohub.support.aws.dev/';
    break;
  case 'beta':
    oauthParams.redirectSignIn = 'https://beta.promohub.support.aws.dev/';
    oauthParams.redirectSignOut = 'https://beta.promohub.support.aws.dev/';
    break;
  case 'dev':
    oauthParams.redirectSignIn = 'https://dev.promohub.support.aws.dev/';
    oauthParams.redirectSignOut = 'https://dev.promohub.support.aws.dev/';
    break;
  default:
    break;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function populateAuthParams(baseAwsExports: Record<string, any>): Record<string, any> {
  const { oauth: oauthDefaults } = baseAwsExports;
  const oauth = { ...oauthDefaults, ...oauthParams };
  if (!oauth.domain) {
    oauth.domain = `promohub-${import.meta.env.REACT_APP_BUILD_ENV || 'dev'}.auth.us-west-2.amazoncognito.com`;
  }
  return {
    ...baseAwsExports,
    oauth,
  };
}

/**
 * sets up cognito auth for the app, and also refreshes it silently.
 */
const setupCognitoSession = async (): Promise<CognitoUserSession> => {
  try {
    // Auth.currentSession() will automatically refresh the accessToken and idToken if expired.
    // This method will refresh the session.
    return await Auth.currentSession();
  } catch (err) {
    // If we got an error from Cognito, parse the object. Some errors are fine and we can workaround
    let errorMessage = '';
    if (typeof err === 'string') {
      errorMessage = err;
    }
    // If the error is an object, it should have a 'message' property we can use.
    if (err && typeof err === 'object' && 'message' in err) {
      errorMessage = (err as { message: string }).message;
    }
    errorMessage = errorMessage.toLowerCase();
    // If it's one of the errors that indicates we need a new login, push the user to Midway
    if (RETRYABLE_ERRORS.some((error) => errorMessage.includes(error))) {
      await Auth.federatedSignIn({ customProvider: FEDERATED_IDENTITY_PROVIDER });
    }
  }
  throw new Error('User is not authenticated!');
};

export default setupCognitoSession;
