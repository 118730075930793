/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateWorkSummaryInput = {
  createdAt?: string | null,
  id?: string | null,
  owner?: string | null,
  coOwners?: Array< string > | null,
  updatedAt?: string | null,
  alias: string,
  candidatePathId?: string | null,
  categoryIds?: Array< string > | null,
  content: string,
  contentVersion?: number | null,
  leadershipPrinciples?: Array< string > | null,
  status?: WorkSummaryStatus | null,
  statusReason?: WorkSummaryStatusReason | null,
  submittedAt?: string | null,
  title: string,
  type: WorkSummaryType,
  _version?: number | null,
};

export enum WorkSummaryStatus {
  DRAFT = "DRAFT",
  PEER_REVIEW = "PEER_REVIEW",
  MANAGER_REVIEW = "MANAGER_REVIEW",
  MANAGER_PEER_REVIEW = "MANAGER_PEER_REVIEW",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  DELETED = "DELETED",
}


export enum WorkSummaryStatusReason {
  RETURNED_FOR_UPDATES = "RETURNED_FOR_UPDATES",
  CONTENT_NOT_USABLE = "CONTENT_NOT_USABLE",
  MANAGER_INITIAL_APPROVAL = "MANAGER_INITIAL_APPROVAL",
  MANAGER_FINAL_APPROVAL = "MANAGER_FINAL_APPROVAL",
  ADMIN_STATUS_CHANGE = "ADMIN_STATUS_CHANGE",
  PROMO_PATH_ASSIGNMENT_CHANGE = "PROMO_PATH_ASSIGNMENT_CHANGE",
  PEER_REVIEW_COMPLETE = "PEER_REVIEW_COMPLETE",
}


export enum WorkSummaryType {
  CUSTOMER = "CUSTOMER",
  AMAZON = "AMAZON",
}


export type ModelWorkSummaryConditionInput = {
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  coOwners?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  alias?: ModelStringInput | null,
  candidatePathId?: ModelIDInput | null,
  categoryIds?: ModelIDInput | null,
  content?: ModelStringInput | null,
  contentVersion?: ModelIntInput | null,
  leadershipPrinciples?: ModelStringInput | null,
  status?: ModelWorkSummaryStatusInput | null,
  statusReason?: ModelWorkSummaryStatusReasonInput | null,
  submittedAt?: ModelStringInput | null,
  title?: ModelStringInput | null,
  type?: ModelWorkSummaryTypeInput | null,
  and?: Array< ModelWorkSummaryConditionInput | null > | null,
  or?: Array< ModelWorkSummaryConditionInput | null > | null,
  not?: ModelWorkSummaryConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelWorkSummaryStatusInput = {
  eq?: WorkSummaryStatus | null,
  ne?: WorkSummaryStatus | null,
};

export type ModelWorkSummaryStatusReasonInput = {
  eq?: WorkSummaryStatusReason | null,
  ne?: WorkSummaryStatusReason | null,
};

export type ModelWorkSummaryTypeInput = {
  eq?: WorkSummaryType | null,
  ne?: WorkSummaryType | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type WorkSummary = {
  __typename: "WorkSummary",
  createdAt: string,
  id: string,
  owner?: string | null,
  coOwners?: Array< string > | null,
  updatedAt: string,
  alias: string,
  candidatePathId?: string | null,
  categoryIds?: Array< string > | null,
  content: string,
  contentVersion?: number | null,
  leadershipPrinciples?: Array< string > | null,
  status?: WorkSummaryStatus | null,
  statusReason?: WorkSummaryStatusReason | null,
  submittedAt?: string | null,
  title: string,
  type: WorkSummaryType,
  candidatePath?: PromoPath | null,
  categories?: ModelCategoryConnection | null,
  comments?: ModelCommentConnection | null,
  documents?: ModelWorkSummaryFilesConnection | null,
  peerReviews?: ModelPeerReviewConnection | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  lastModifiedBy: string,
};

export type PromoPath = {
  __typename: "PromoPath",
  createdAt: string,
  id: string,
  owner?: string | null,
  updatedAt: string,
  allowAttachments?: boolean | null,
  categoryIds?: Array< string > | null,
  name?: string | null,
  pathType: PathDefinitionType,
  role?: string | null,
  startLevel?: number | null,
  status: PromoPathStatus,
  suggestedLPs?: Array< string > | null,
  targetLevel?: number | null,
  candidate?: string | null,
  targetDate?: string | null,
  totalCompleted?: number | null,
  totalRequired?: number | null,
  manager?: string | null,
  templateId?: string | null,
  categories?: ModelCategoryConnection | null,
  descendants?: ModelPromoPathConnection | null,
  peerReviews?: ModelPeerReviewConnection | null,
  template?: PromoPath | null,
  workSummaries?: ModelWorkSummaryConnection | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  lastModifiedBy: string,
};

export enum PathDefinitionType {
  CANDIDATE = "CANDIDATE",
  MANAGER = "MANAGER",
  ORGANIZATION = "ORGANIZATION",
  SYSTEM = "SYSTEM",
}


export enum PromoPathStatus {
  ACTIVE = "ACTIVE",
  ASSIGNED = "ASSIGNED",
  COMPLETED = "COMPLETED",
  DELETED = "DELETED",
}


export type ModelCategoryConnection = {
  __typename: "ModelCategoryConnection",
  items:  Array<Category | null >,
  nextToken?: string | null,
};

export type Category = {
  __typename: "Category",
  createdAt: string,
  id: string,
  owner?: string | null,
  updatedAt: string,
  description: string,
  isDefault?: string | null,
  name: string,
  role: string,
  status: CategoryStatus,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  lastModifiedBy: string,
};

export enum CategoryStatus {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
}


export type ModelPromoPathConnection = {
  __typename: "ModelPromoPathConnection",
  items:  Array<PromoPath | null >,
  nextToken?: string | null,
};

export type ModelPeerReviewConnection = {
  __typename: "ModelPeerReviewConnection",
  items:  Array<PeerReview | null >,
  nextToken?: string | null,
};

export type PeerReview = {
  __typename: "PeerReview",
  createdAt: string,
  id: string,
  owner?: string | null,
  coOwners?: Array< string > | null,
  updatedAt: string,
  content?: string | null,
  requiredBy: string,
  reviewedContentVersion?: number | null,
  reviewStatus?: PeerReviewStatus | null,
  reviewType: PeerReviewType,
  candidatePathId?: string | null,
  candidatePath?: PromoPath | null,
  reviewerAlias: string,
  reviewer: UserProfile,
  workSummaryId?: string | null,
  workSummary?: WorkSummary | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  lastModifiedBy: string,
};

export enum PeerReviewStatus {
  REQUESTED = "REQUESTED",
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  RETURNED_FOR_REVISION = "RETURNED_FOR_REVISION",
  DELETED = "DELETED",
}


export enum PeerReviewType {
  CANDIDATE = "CANDIDATE",
  MANAGER = "MANAGER",
}


export type UserProfile = {
  __typename: "UserProfile",
  alias: string,
  createdAt: string,
  owner?: string | null,
  updatedAt: string,
  email: string,
  hasLoggedIn?: boolean | null,
  hireDate?: string | null,
  identityId?: string | null,
  isManager: boolean,
  jobLevel: number,
  jobTitle?: string | null,
  manager: string,
  managerProfile?: UserProfile | null,
  name?: string | null,
  orgName?: string | null,
  personId?: string | null,
  preferences?: UserPreferences | null,
  status?: UserProfileStatus | null,
  sharedFiles?: ModelSharedUserFilesConnection | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  lastModifiedBy: string,
};

export type UserPreferences = {
  __typename: "UserPreferences",
  landingPage?: string | null,
  visualMode?: VisualMode | null,
  density?: Density | null,
};

export enum VisualMode {
  AUTO = "AUTO",
  DARK = "DARK",
  LIGHT = "LIGHT",
}


export enum Density {
  COMFORTABLE = "COMFORTABLE",
  COMPACT = "COMPACT",
}


export enum UserProfileStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  DELETED = "DELETED",
}


export type ModelSharedUserFilesConnection = {
  __typename: "ModelSharedUserFilesConnection",
  items:  Array<SharedUserFiles | null >,
  nextToken?: string | null,
};

export type SharedUserFiles = {
  __typename: "SharedUserFiles",
  createdAt: string,
  id: string,
  owner?: string | null,
  coOwners?: Array< string > | null,
  updatedAt: string,
  fileRecordID: string,
  fileRecord: FileRecord,
  userProfileAlias: string,
  userProfile: UserProfile,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type FileRecord = {
  __typename: "FileRecord",
  createdAt: string,
  id: string,
  owner?: string | null,
  updatedAt: string,
  fileDateModified: number,
  fileName: string,
  fileSizeBytes: number,
  s3Key: string,
  storageIdKey: string,
  status: FileRecordStatus,
  workSummaries?: ModelWorkSummaryFilesConnection | null,
  users?: ModelSharedUserFilesConnection | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  lastModifiedBy: string,
};

export enum FileRecordStatus {
  ACTIVE = "ACTIVE",
  HIDDEN = "HIDDEN",
  DELETED = "DELETED",
}


export type ModelWorkSummaryFilesConnection = {
  __typename: "ModelWorkSummaryFilesConnection",
  items:  Array<WorkSummaryFiles | null >,
  nextToken?: string | null,
};

export type WorkSummaryFiles = {
  __typename: "WorkSummaryFiles",
  createdAt: string,
  id: string,
  owner?: string | null,
  updatedAt: string,
  fileRecordID: string,
  fileRecord: FileRecord,
  workSummaryID: string,
  workSummary: WorkSummary,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  lastModifiedBy: string,
};

export type ModelWorkSummaryConnection = {
  __typename: "ModelWorkSummaryConnection",
  items:  Array<WorkSummary | null >,
  nextToken?: string | null,
};

export type ModelCommentConnection = {
  __typename: "ModelCommentConnection",
  items:  Array<Comment | null >,
  nextToken?: string | null,
};

export type Comment = {
  __typename: "Comment",
  createdAt: string,
  id: string,
  owner?: string | null,
  updatedAt: string,
  alias: string,
  content: string,
  status: CommentStatus,
  type: CommentType,
  workSummaryId?: string | null,
  author: UserProfile,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  lastModifiedBy: string,
};

export enum CommentStatus {
  ACTIVE = "ACTIVE",
  RESTRICTED = "RESTRICTED",
  DELETED = "DELETED",
}


export enum CommentType {
  CANDIDATE = "CANDIDATE",
  PEER_REVIEW = "PEER_REVIEW",
}


export type UpdateWorkSummaryInput = {
  createdAt?: string | null,
  id: string,
  owner?: string | null,
  coOwners?: Array< string > | null,
  updatedAt?: string | null,
  alias?: string | null,
  candidatePathId?: string | null,
  categoryIds?: Array< string > | null,
  content?: string | null,
  contentVersion?: number | null,
  leadershipPrinciples?: Array< string > | null,
  status?: WorkSummaryStatus | null,
  statusReason?: WorkSummaryStatusReason | null,
  submittedAt?: string | null,
  title?: string | null,
  type?: WorkSummaryType | null,
  _version?: number | null,
};

export type DeleteWorkSummaryInput = {
  id: string,
  _version?: number | null,
};

export type CreatePeerReviewInput = {
  createdAt?: string | null,
  id?: string | null,
  owner?: string | null,
  coOwners?: Array< string > | null,
  updatedAt?: string | null,
  content?: string | null,
  requiredBy: string,
  reviewedContentVersion?: number | null,
  reviewStatus?: PeerReviewStatus | null,
  reviewType: PeerReviewType,
  candidatePathId?: string | null,
  reviewerAlias: string,
  workSummaryId?: string | null,
  _version?: number | null,
};

export type ModelPeerReviewConditionInput = {
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  coOwners?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  content?: ModelStringInput | null,
  requiredBy?: ModelStringInput | null,
  reviewedContentVersion?: ModelIntInput | null,
  reviewStatus?: ModelPeerReviewStatusInput | null,
  reviewType?: ModelPeerReviewTypeInput | null,
  candidatePathId?: ModelIDInput | null,
  reviewerAlias?: ModelStringInput | null,
  workSummaryId?: ModelIDInput | null,
  and?: Array< ModelPeerReviewConditionInput | null > | null,
  or?: Array< ModelPeerReviewConditionInput | null > | null,
  not?: ModelPeerReviewConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelPeerReviewStatusInput = {
  eq?: PeerReviewStatus | null,
  ne?: PeerReviewStatus | null,
};

export type ModelPeerReviewTypeInput = {
  eq?: PeerReviewType | null,
  ne?: PeerReviewType | null,
};

export type UpdatePeerReviewInput = {
  createdAt?: string | null,
  id: string,
  owner?: string | null,
  coOwners?: Array< string > | null,
  updatedAt?: string | null,
  content?: string | null,
  requiredBy?: string | null,
  reviewedContentVersion?: number | null,
  reviewStatus?: PeerReviewStatus | null,
  reviewType?: PeerReviewType | null,
  candidatePathId?: string | null,
  reviewerAlias?: string | null,
  workSummaryId?: string | null,
  _version?: number | null,
};

export type DeletePeerReviewInput = {
  id: string,
  _version?: number | null,
};

export type CreateFileRecordInput = {
  createdAt?: string | null,
  id?: string | null,
  owner?: string | null,
  updatedAt?: string | null,
  fileDateModified: number,
  fileName: string,
  fileSizeBytes: number,
  s3Key: string,
  storageIdKey: string,
  status: FileRecordStatus,
  _version?: number | null,
};

export type ModelFileRecordConditionInput = {
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  fileDateModified?: ModelIntInput | null,
  fileName?: ModelStringInput | null,
  fileSizeBytes?: ModelIntInput | null,
  s3Key?: ModelStringInput | null,
  storageIdKey?: ModelStringInput | null,
  status?: ModelFileRecordStatusInput | null,
  and?: Array< ModelFileRecordConditionInput | null > | null,
  or?: Array< ModelFileRecordConditionInput | null > | null,
  not?: ModelFileRecordConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelFileRecordStatusInput = {
  eq?: FileRecordStatus | null,
  ne?: FileRecordStatus | null,
};

export type UpdateFileRecordInput = {
  createdAt?: string | null,
  id: string,
  owner?: string | null,
  updatedAt?: string | null,
  fileDateModified?: number | null,
  fileName?: string | null,
  fileSizeBytes?: number | null,
  s3Key?: string | null,
  storageIdKey?: string | null,
  status?: FileRecordStatus | null,
  _version?: number | null,
};

export type DeleteFileRecordInput = {
  id: string,
  _version?: number | null,
};

export type CreatePromoPathInput = {
  createdAt?: string | null,
  id?: string | null,
  owner?: string | null,
  updatedAt?: string | null,
  allowAttachments?: boolean | null,
  categoryIds?: Array< string > | null,
  name?: string | null,
  pathType: PathDefinitionType,
  role?: string | null,
  startLevel?: number | null,
  status: PromoPathStatus,
  suggestedLPs?: Array< string > | null,
  targetLevel?: number | null,
  candidate?: string | null,
  targetDate?: string | null,
  totalCompleted?: number | null,
  totalRequired?: number | null,
  manager?: string | null,
  templateId?: string | null,
  _version?: number | null,
};

export type ModelPromoPathConditionInput = {
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  allowAttachments?: ModelBooleanInput | null,
  categoryIds?: ModelIDInput | null,
  name?: ModelStringInput | null,
  pathType?: ModelPathDefinitionTypeInput | null,
  role?: ModelStringInput | null,
  startLevel?: ModelIntInput | null,
  status?: ModelPromoPathStatusInput | null,
  suggestedLPs?: ModelStringInput | null,
  targetLevel?: ModelIntInput | null,
  candidate?: ModelStringInput | null,
  targetDate?: ModelStringInput | null,
  totalCompleted?: ModelIntInput | null,
  totalRequired?: ModelIntInput | null,
  manager?: ModelStringInput | null,
  templateId?: ModelIDInput | null,
  and?: Array< ModelPromoPathConditionInput | null > | null,
  or?: Array< ModelPromoPathConditionInput | null > | null,
  not?: ModelPromoPathConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelPathDefinitionTypeInput = {
  eq?: PathDefinitionType | null,
  ne?: PathDefinitionType | null,
};

export type ModelPromoPathStatusInput = {
  eq?: PromoPathStatus | null,
  ne?: PromoPathStatus | null,
};

export type UpdatePromoPathInput = {
  createdAt?: string | null,
  id: string,
  owner?: string | null,
  updatedAt?: string | null,
  allowAttachments?: boolean | null,
  categoryIds?: Array< string > | null,
  name?: string | null,
  pathType?: PathDefinitionType | null,
  role?: string | null,
  startLevel?: number | null,
  status?: PromoPathStatus | null,
  suggestedLPs?: Array< string > | null,
  targetLevel?: number | null,
  candidate?: string | null,
  targetDate?: string | null,
  totalCompleted?: number | null,
  totalRequired?: number | null,
  manager?: string | null,
  templateId?: string | null,
  _version?: number | null,
};

export type DeletePromoPathInput = {
  id: string,
  _version?: number | null,
};

export type CreateCategoryInput = {
  createdAt?: string | null,
  id?: string | null,
  owner?: string | null,
  updatedAt?: string | null,
  description: string,
  isDefault?: string | null,
  name: string,
  role: string,
  status: CategoryStatus,
  _version?: number | null,
};

export type ModelCategoryConditionInput = {
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  description?: ModelStringInput | null,
  isDefault?: ModelStringInput | null,
  name?: ModelStringInput | null,
  role?: ModelStringInput | null,
  status?: ModelCategoryStatusInput | null,
  and?: Array< ModelCategoryConditionInput | null > | null,
  or?: Array< ModelCategoryConditionInput | null > | null,
  not?: ModelCategoryConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCategoryStatusInput = {
  eq?: CategoryStatus | null,
  ne?: CategoryStatus | null,
};

export type UpdateCategoryInput = {
  createdAt?: string | null,
  id: string,
  owner?: string | null,
  updatedAt?: string | null,
  description?: string | null,
  isDefault?: string | null,
  name?: string | null,
  role?: string | null,
  status?: CategoryStatus | null,
  _version?: number | null,
};

export type DeleteCategoryInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserProfileInput = {
  alias: string,
  createdAt?: string | null,
  owner?: string | null,
  updatedAt?: string | null,
  email: string,
  hasLoggedIn?: boolean | null,
  hireDate?: string | null,
  identityId?: string | null,
  isManager: boolean,
  jobLevel: number,
  jobTitle?: string | null,
  manager: string,
  name?: string | null,
  orgName?: string | null,
  personId?: string | null,
  preferences?: UserPreferencesInput | null,
  status?: UserProfileStatus | null,
  _version?: number | null,
};

export type UserPreferencesInput = {
  landingPage?: string | null,
  visualMode?: VisualMode | null,
  density?: Density | null,
};

export type ModelUserProfileConditionInput = {
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  email?: ModelStringInput | null,
  hasLoggedIn?: ModelBooleanInput | null,
  hireDate?: ModelStringInput | null,
  identityId?: ModelStringInput | null,
  isManager?: ModelBooleanInput | null,
  jobLevel?: ModelIntInput | null,
  jobTitle?: ModelStringInput | null,
  manager?: ModelStringInput | null,
  name?: ModelStringInput | null,
  orgName?: ModelStringInput | null,
  personId?: ModelStringInput | null,
  status?: ModelUserProfileStatusInput | null,
  and?: Array< ModelUserProfileConditionInput | null > | null,
  or?: Array< ModelUserProfileConditionInput | null > | null,
  not?: ModelUserProfileConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUserProfileStatusInput = {
  eq?: UserProfileStatus | null,
  ne?: UserProfileStatus | null,
};

export type UpdateUserProfileInput = {
  alias: string,
  createdAt?: string | null,
  owner?: string | null,
  updatedAt?: string | null,
  email?: string | null,
  hasLoggedIn?: boolean | null,
  hireDate?: string | null,
  identityId?: string | null,
  isManager?: boolean | null,
  jobLevel?: number | null,
  jobTitle?: string | null,
  manager?: string | null,
  name?: string | null,
  orgName?: string | null,
  personId?: string | null,
  preferences?: UserPreferencesInput | null,
  status?: UserProfileStatus | null,
  _version?: number | null,
};

export type DeleteUserProfileInput = {
  alias: string,
  _version?: number | null,
};

export type CreateWorkSummaryFilesInput = {
  createdAt?: string | null,
  id?: string | null,
  owner?: string | null,
  updatedAt?: string | null,
  fileRecordID: string,
  workSummaryID: string,
  _version?: number | null,
};

export type ModelWorkSummaryFilesConditionInput = {
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  fileRecordID?: ModelIDInput | null,
  workSummaryID?: ModelIDInput | null,
  and?: Array< ModelWorkSummaryFilesConditionInput | null > | null,
  or?: Array< ModelWorkSummaryFilesConditionInput | null > | null,
  not?: ModelWorkSummaryFilesConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateWorkSummaryFilesInput = {
  createdAt?: string | null,
  id: string,
  owner?: string | null,
  updatedAt?: string | null,
  fileRecordID?: string | null,
  workSummaryID?: string | null,
  _version?: number | null,
};

export type DeleteWorkSummaryFilesInput = {
  id: string,
  _version?: number | null,
};

export type CreateSharedUserFilesInput = {
  createdAt?: string | null,
  id?: string | null,
  owner?: string | null,
  coOwners?: Array< string > | null,
  updatedAt?: string | null,
  fileRecordID: string,
  userProfileAlias: string,
  _version?: number | null,
};

export type ModelSharedUserFilesConditionInput = {
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  coOwners?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  fileRecordID?: ModelIDInput | null,
  userProfileAlias?: ModelStringInput | null,
  and?: Array< ModelSharedUserFilesConditionInput | null > | null,
  or?: Array< ModelSharedUserFilesConditionInput | null > | null,
  not?: ModelSharedUserFilesConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateSharedUserFilesInput = {
  createdAt?: string | null,
  id: string,
  owner?: string | null,
  coOwners?: Array< string > | null,
  updatedAt?: string | null,
  fileRecordID?: string | null,
  userProfileAlias?: string | null,
  _version?: number | null,
};

export type DeleteSharedUserFilesInput = {
  id: string,
  _version?: number | null,
};

export type CreateCommentInput = {
  createdAt?: string | null,
  id?: string | null,
  owner?: string | null,
  updatedAt?: string | null,
  alias: string,
  content: string,
  status: CommentStatus,
  type: CommentType,
  workSummaryId?: string | null,
  _version?: number | null,
};

export type ModelCommentConditionInput = {
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  alias?: ModelStringInput | null,
  content?: ModelStringInput | null,
  status?: ModelCommentStatusInput | null,
  type?: ModelCommentTypeInput | null,
  workSummaryId?: ModelIDInput | null,
  and?: Array< ModelCommentConditionInput | null > | null,
  or?: Array< ModelCommentConditionInput | null > | null,
  not?: ModelCommentConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCommentStatusInput = {
  eq?: CommentStatus | null,
  ne?: CommentStatus | null,
};

export type ModelCommentTypeInput = {
  eq?: CommentType | null,
  ne?: CommentType | null,
};

export type UpdateCommentInput = {
  createdAt?: string | null,
  id: string,
  owner?: string | null,
  updatedAt?: string | null,
  alias?: string | null,
  content?: string | null,
  status?: CommentStatus | null,
  type?: CommentType | null,
  workSummaryId?: string | null,
  _version?: number | null,
};

export type DeleteCommentInput = {
  id: string,
  _version?: number | null,
};

export type CreateAuditTrailsInput = {
  id?: string | null,
  changedFields?: Array< string > | null,
  changeJustification?: string | null,
  humanReadableLog: Array< string >,
  modifiedBy?: string | null,
  rawNewRecord: string,
  rawOldRecord?: string | null,
  recordId: string,
  recordType: AuditTrailRecordType,
  _version?: number | null,
};

export enum AuditTrailRecordType {
  WORKSUMMARY = "WORKSUMMARY",
  PEERREVIEW = "PEERREVIEW",
  COMMENT = "COMMENT",
  PROMOPATH = "PROMOPATH",
}


export type ModelAuditTrailsConditionInput = {
  changedFields?: ModelStringInput | null,
  changeJustification?: ModelStringInput | null,
  humanReadableLog?: ModelStringInput | null,
  modifiedBy?: ModelStringInput | null,
  rawNewRecord?: ModelStringInput | null,
  rawOldRecord?: ModelStringInput | null,
  recordId?: ModelIDInput | null,
  recordType?: ModelAuditTrailRecordTypeInput | null,
  and?: Array< ModelAuditTrailsConditionInput | null > | null,
  or?: Array< ModelAuditTrailsConditionInput | null > | null,
  not?: ModelAuditTrailsConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelAuditTrailRecordTypeInput = {
  eq?: AuditTrailRecordType | null,
  ne?: AuditTrailRecordType | null,
};

export type AuditTrails = {
  __typename: "AuditTrails",
  id: string,
  changedFields?: Array< string > | null,
  changeJustification?: string | null,
  humanReadableLog: Array< string >,
  modifiedBy?: string | null,
  rawNewRecord: string,
  rawOldRecord?: string | null,
  recordId: string,
  recordType: AuditTrailRecordType,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateAuditTrailsInput = {
  id: string,
  changedFields?: Array< string > | null,
  changeJustification?: string | null,
  humanReadableLog?: Array< string > | null,
  modifiedBy?: string | null,
  rawNewRecord?: string | null,
  rawOldRecord?: string | null,
  recordId?: string | null,
  recordType?: AuditTrailRecordType | null,
  _version?: number | null,
};

export type DeleteAuditTrailsInput = {
  id: string,
  _version?: number | null,
};

export type ManagerProfile = {
  __typename: "ManagerProfile",
  alias: string,
  directReports?:  Array<DirectReport > | null,
};

export type DirectReport = {
  __typename: "DirectReport",
  alias: string,
  hireDate: string,
  personId: string,
  jobLevel: number,
  isManager: boolean,
  firstName: string,
  lastName: string,
  email: string,
  hrJobTitle: string,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelUserProfileFilterInput = {
  alias?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  email?: ModelStringInput | null,
  hasLoggedIn?: ModelBooleanInput | null,
  hireDate?: ModelStringInput | null,
  identityId?: ModelStringInput | null,
  isManager?: ModelBooleanInput | null,
  jobLevel?: ModelIntInput | null,
  jobTitle?: ModelStringInput | null,
  manager?: ModelStringInput | null,
  name?: ModelStringInput | null,
  orgName?: ModelStringInput | null,
  personId?: ModelStringInput | null,
  status?: ModelUserProfileStatusInput | null,
  and?: Array< ModelUserProfileFilterInput | null > | null,
  or?: Array< ModelUserProfileFilterInput | null > | null,
  not?: ModelUserProfileFilterInput | null,
};

export type ModelUserProfileConnection = {
  __typename: "ModelUserProfileConnection",
  items:  Array<UserProfile | null >,
  nextToken?: string | null,
};

export type PeopleProfile = {
  __typename: "PeopleProfile",
  alias: string,
  jobLevel: number,
  isManager: boolean,
  firstName: string,
  lastName: string,
  email: string,
  hrJobTitle: string,
  managerAlias?: string | null,
  managerName?: string | null,
};

export enum FileRecordType {
  WORKSUMMARY = "WORKSUMMARY",
  SHARED_USER = "SHARED_USER",
}


export type DownloadableFile = {
  __typename: "DownloadableFile",
  fileRecordId?: string | null,
  referenceId?: string | null,
  fileRecordType?: FileRecordType | null,
  presignedURL?: string | null,
};

export type ModelPeerReviewFilterInput = {
  createdAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  coOwners?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  content?: ModelStringInput | null,
  requiredBy?: ModelStringInput | null,
  reviewedContentVersion?: ModelIntInput | null,
  reviewStatus?: ModelPeerReviewStatusInput | null,
  reviewType?: ModelPeerReviewTypeInput | null,
  candidatePathId?: ModelIDInput | null,
  reviewerAlias?: ModelStringInput | null,
  workSummaryId?: ModelIDInput | null,
  and?: Array< ModelPeerReviewFilterInput | null > | null,
  or?: Array< ModelPeerReviewFilterInput | null > | null,
  not?: ModelPeerReviewFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelWorkSummaryFilterInput = {
  createdAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  coOwners?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  alias?: ModelStringInput | null,
  candidatePathId?: ModelIDInput | null,
  categoryIds?: ModelIDInput | null,
  content?: ModelStringInput | null,
  contentVersion?: ModelIntInput | null,
  leadershipPrinciples?: ModelStringInput | null,
  status?: ModelWorkSummaryStatusInput | null,
  statusReason?: ModelWorkSummaryStatusReasonInput | null,
  submittedAt?: ModelStringInput | null,
  title?: ModelStringInput | null,
  type?: ModelWorkSummaryTypeInput | null,
  and?: Array< ModelWorkSummaryFilterInput | null > | null,
  or?: Array< ModelWorkSummaryFilterInput | null > | null,
  not?: ModelWorkSummaryFilterInput | null,
};

export type ModelFileRecordFilterInput = {
  createdAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  fileDateModified?: ModelIntInput | null,
  fileName?: ModelStringInput | null,
  fileSizeBytes?: ModelIntInput | null,
  s3Key?: ModelStringInput | null,
  storageIdKey?: ModelStringInput | null,
  status?: ModelFileRecordStatusInput | null,
  and?: Array< ModelFileRecordFilterInput | null > | null,
  or?: Array< ModelFileRecordFilterInput | null > | null,
  not?: ModelFileRecordFilterInput | null,
};

export type ModelFileRecordConnection = {
  __typename: "ModelFileRecordConnection",
  items:  Array<FileRecord | null >,
  nextToken?: string | null,
};

export type ModelPromoPathFilterInput = {
  createdAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  allowAttachments?: ModelBooleanInput | null,
  categoryIds?: ModelIDInput | null,
  name?: ModelStringInput | null,
  pathType?: ModelPathDefinitionTypeInput | null,
  role?: ModelStringInput | null,
  startLevel?: ModelIntInput | null,
  status?: ModelPromoPathStatusInput | null,
  suggestedLPs?: ModelStringInput | null,
  targetLevel?: ModelIntInput | null,
  candidate?: ModelStringInput | null,
  targetDate?: ModelStringInput | null,
  totalCompleted?: ModelIntInput | null,
  totalRequired?: ModelIntInput | null,
  manager?: ModelStringInput | null,
  templateId?: ModelIDInput | null,
  and?: Array< ModelPromoPathFilterInput | null > | null,
  or?: Array< ModelPromoPathFilterInput | null > | null,
  not?: ModelPromoPathFilterInput | null,
};

export type ModelCategoryFilterInput = {
  createdAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  description?: ModelStringInput | null,
  isDefault?: ModelStringInput | null,
  name?: ModelStringInput | null,
  role?: ModelStringInput | null,
  status?: ModelCategoryStatusInput | null,
  and?: Array< ModelCategoryFilterInput | null > | null,
  or?: Array< ModelCategoryFilterInput | null > | null,
  not?: ModelCategoryFilterInput | null,
};

export type ModelCommentFilterInput = {
  createdAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  alias?: ModelStringInput | null,
  content?: ModelStringInput | null,
  status?: ModelCommentStatusInput | null,
  type?: ModelCommentTypeInput | null,
  workSummaryId?: ModelIDInput | null,
  and?: Array< ModelCommentFilterInput | null > | null,
  or?: Array< ModelCommentFilterInput | null > | null,
  not?: ModelCommentFilterInput | null,
};

export type ModelAuditTrailsConnection = {
  __typename: "ModelAuditTrailsConnection",
  items:  Array<AuditTrails | null >,
  nextToken?: string | null,
};

export type CreateWorkSummaryMutationVariables = {
  input: CreateWorkSummaryInput,
  condition?: ModelWorkSummaryConditionInput | null,
};

export type CreateWorkSummaryMutation = {
  createWorkSummary?:  {
    __typename: "WorkSummary",
    createdAt: string,
    id: string,
    coOwners?: Array< string > | null,
    owner?: string | null,
    updatedAt: string,
    alias: string,
    candidatePathId?: string | null,
    content: string,
    leadershipPrinciples?: Array< string > | null,
    status?: WorkSummaryStatus | null,
    statusReason?: WorkSummaryStatusReason | null,
    submittedAt?: string | null,
    title: string,
    type: WorkSummaryType,
    categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        name: string,
      } | null >,
    } | null,
    documents?:  {
      __typename: "ModelWorkSummaryFilesConnection",
      items:  Array< {
        __typename: "WorkSummaryFiles",
        id: string,
        fileRecord:  {
          __typename: "FileRecord",
          id: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          fileName: string,
          s3Key: string,
          status: FileRecordStatus,
          fileSizeBytes: number,
          fileDateModified: number,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    peerReviews?:  {
      __typename: "ModelPeerReviewConnection",
      items:  Array< {
        __typename: "PeerReview",
        createdAt: string,
        id: string,
        coOwners?: Array< string > | null,
        updatedAt: string,
        content?: string | null,
        reviewerAlias: string,
        reviewer:  {
          __typename: "UserProfile",
          name?: string | null,
        },
        reviewStatus?: PeerReviewStatus | null,
        reviewType: PeerReviewType,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateWorkSummaryMutationVariables = {
  input: UpdateWorkSummaryInput,
  condition?: ModelWorkSummaryConditionInput | null,
};

export type UpdateWorkSummaryMutation = {
  updateWorkSummary?:  {
    __typename: "WorkSummary",
    createdAt: string,
    id: string,
    coOwners?: Array< string > | null,
    updatedAt: string,
    alias: string,
    candidatePathId?: string | null,
    content: string,
    leadershipPrinciples?: Array< string > | null,
    status?: WorkSummaryStatus | null,
    statusReason?: WorkSummaryStatusReason | null,
    submittedAt?: string | null,
    title: string,
    type: WorkSummaryType,
    categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        name: string,
      } | null >,
    } | null,
    documents?:  {
      __typename: "ModelWorkSummaryFilesConnection",
      items:  Array< {
        __typename: "WorkSummaryFiles",
        id: string,
        fileRecord:  {
          __typename: "FileRecord",
          id: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          fileName: string,
          s3Key: string,
          status: FileRecordStatus,
          fileSizeBytes: number,
          fileDateModified: number,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    peerReviews?:  {
      __typename: "ModelPeerReviewConnection",
      items:  Array< {
        __typename: "PeerReview",
        createdAt: string,
        id: string,
        coOwners?: Array< string > | null,
        updatedAt: string,
        content?: string | null,
        reviewerAlias: string,
        reviewer:  {
          __typename: "UserProfile",
          name?: string | null,
        },
        reviewStatus?: PeerReviewStatus | null,
        reviewType: PeerReviewType,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteWorkSummaryMutationVariables = {
  input: DeleteWorkSummaryInput,
  condition?: ModelWorkSummaryConditionInput | null,
};

export type DeleteWorkSummaryMutation = {
  deleteWorkSummary?:  {
    __typename: "WorkSummary",
    id: string,
    title: string,
    alias: string,
    _version: number,
  } | null,
};

export type CreatePeerReviewMutationVariables = {
  input: CreatePeerReviewInput,
  condition?: ModelPeerReviewConditionInput | null,
};

export type CreatePeerReviewMutation = {
  createPeerReview?:  {
    __typename: "PeerReview",
    createdAt: string,
    id: string,
    coOwners?: Array< string > | null,
    updatedAt: string,
    content?: string | null,
    requiredBy: string,
    reviewerAlias: string,
    reviewer:  {
      __typename: "UserProfile",
      name?: string | null,
    },
    reviewedContentVersion?: number | null,
    reviewStatus?: PeerReviewStatus | null,
    reviewType: PeerReviewType,
    candidatePath?:  {
      __typename: "PromoPath",
      id: string,
      candidate?: string | null,
      manager?: string | null,
      name?: string | null,
      targetDate?: string | null,
      targetLevel?: number | null,
      template?:  {
        __typename: "PromoPath",
        id: string,
        name?: string | null,
        targetLevel?: number | null,
      } | null,
    } | null,
    workSummary?:  {
      __typename: "WorkSummary",
      id: string,
      alias: string,
      content: string,
      title: string,
      contentVersion?: number | null,
      type: WorkSummaryType,
      categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          description: string,
        } | null >,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
        } | null >,
      } | null,
      documents?:  {
        __typename: "ModelWorkSummaryFilesConnection",
        items:  Array< {
          __typename: "WorkSummaryFiles",
          id: string,
          fileRecord:  {
            __typename: "FileRecord",
            createdAt: string,
            id: string,
            owner?: string | null,
            updatedAt: string,
            fileDateModified: number,
            fileName: string,
            fileSizeBytes: number,
            status: FileRecordStatus,
            s3Key: string,
            _deleted?: boolean | null,
          },
          _deleted?: boolean | null,
        } | null >,
      } | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdatePeerReviewMutationVariables = {
  input: UpdatePeerReviewInput,
  condition?: ModelPeerReviewConditionInput | null,
};

export type UpdatePeerReviewMutation = {
  updatePeerReview?:  {
    __typename: "PeerReview",
    createdAt: string,
    id: string,
    coOwners?: Array< string > | null,
    updatedAt: string,
    content?: string | null,
    requiredBy: string,
    reviewerAlias: string,
    reviewer:  {
      __typename: "UserProfile",
      name?: string | null,
    },
    reviewedContentVersion?: number | null,
    reviewStatus?: PeerReviewStatus | null,
    reviewType: PeerReviewType,
    candidatePath?:  {
      __typename: "PromoPath",
      id: string,
      candidate?: string | null,
      manager?: string | null,
      name?: string | null,
      targetDate?: string | null,
      targetLevel?: number | null,
      template?:  {
        __typename: "PromoPath",
        id: string,
        name?: string | null,
        targetLevel?: number | null,
      } | null,
    } | null,
    workSummary?:  {
      __typename: "WorkSummary",
      id: string,
      alias: string,
      content: string,
      title: string,
      contentVersion?: number | null,
      type: WorkSummaryType,
      categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          description: string,
        } | null >,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
        } | null >,
      } | null,
      documents?:  {
        __typename: "ModelWorkSummaryFilesConnection",
        items:  Array< {
          __typename: "WorkSummaryFiles",
          id: string,
          fileRecord:  {
            __typename: "FileRecord",
            createdAt: string,
            id: string,
            owner?: string | null,
            updatedAt: string,
            fileDateModified: number,
            fileName: string,
            fileSizeBytes: number,
            status: FileRecordStatus,
            s3Key: string,
            _deleted?: boolean | null,
          },
          _deleted?: boolean | null,
        } | null >,
      } | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeletePeerReviewMutationVariables = {
  input: DeletePeerReviewInput,
  condition?: ModelPeerReviewConditionInput | null,
};

export type DeletePeerReviewMutation = {
  deletePeerReview?:  {
    __typename: "PeerReview",
    id: string,
    reviewerAlias: string,
    workSummary?:  {
      __typename: "WorkSummary",
      title: string,
    } | null,
    _version: number,
  } | null,
};

export type CreateFileRecordMutationVariables = {
  input: CreateFileRecordInput,
  condition?: ModelFileRecordConditionInput | null,
};

export type CreateFileRecordMutation = {
  createFileRecord?:  {
    __typename: "FileRecord",
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    fileName: string,
    s3Key: string,
    status: FileRecordStatus,
    fileSizeBytes: number,
    fileDateModified: number,
    workSummaries?:  {
      __typename: "ModelWorkSummaryFilesConnection",
      items:  Array< {
        __typename: "WorkSummaryFiles",
        id: string,
        workSummaryID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateFileRecordMutationVariables = {
  input: UpdateFileRecordInput,
  condition?: ModelFileRecordConditionInput | null,
};

export type UpdateFileRecordMutation = {
  updateFileRecord?:  {
    __typename: "FileRecord",
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    fileName: string,
    s3Key: string,
    status: FileRecordStatus,
    fileSizeBytes: number,
    fileDateModified: number,
    workSummaries?:  {
      __typename: "ModelWorkSummaryFilesConnection",
      items:  Array< {
        __typename: "WorkSummaryFiles",
        id: string,
        workSummaryID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteFileRecordMutationVariables = {
  input: DeleteFileRecordInput,
  condition?: ModelFileRecordConditionInput | null,
};

export type DeleteFileRecordMutation = {
  deleteFileRecord?:  {
    __typename: "FileRecord",
    id: string,
    fileName: string,
    owner?: string | null,
    _version: number,
  } | null,
};

export type CreateCandidatePathMutationVariables = {
  input: CreatePromoPathInput,
  condition?: ModelPromoPathConditionInput | null,
};

export type CreateCandidatePathMutation = {
  createPromoPath?:  {
    __typename: "PromoPath",
    id: string,
    createdAt: string,
    updatedAt: string,
    categoryIds?: Array< string > | null,
    allowAttachments?: boolean | null,
    status: PromoPathStatus,
    name?: string | null,
    manager?: string | null,
    candidate?: string | null,
    role?: string | null,
    startLevel?: number | null,
    suggestedLPs?: Array< string > | null,
    targetLevel?: number | null,
    template?:  {
      __typename: "PromoPath",
      createdAt: string,
      id: string,
      updatedAt: string,
      allowAttachments?: boolean | null,
      categoryIds?: Array< string > | null,
      name?: string | null,
      status: PromoPathStatus,
      role?: string | null,
      startLevel?: number | null,
      suggestedLPs?: Array< string > | null,
      targetLevel?: number | null,
      totalRequired?: number | null,
      categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          createdAt: string,
          id: string,
          updatedAt: string,
          description: string,
          isDefault?: string | null,
          name: string,
          role: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        createdAt: string,
        updatedAt: string,
        description: string,
        isDefault?: string | null,
        name: string,
        role: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCandidatePathMutationVariables = {
  input: UpdatePromoPathInput,
  condition?: ModelPromoPathConditionInput | null,
};

export type UpdateCandidatePathMutation = {
  updatePromoPath?:  {
    __typename: "PromoPath",
    id: string,
    createdAt: string,
    updatedAt: string,
    categoryIds?: Array< string > | null,
    allowAttachments?: boolean | null,
    status: PromoPathStatus,
    name?: string | null,
    manager?: string | null,
    candidate?: string | null,
    role?: string | null,
    startLevel?: number | null,
    suggestedLPs?: Array< string > | null,
    targetLevel?: number | null,
    template?:  {
      __typename: "PromoPath",
      createdAt: string,
      id: string,
      updatedAt: string,
      allowAttachments?: boolean | null,
      categoryIds?: Array< string > | null,
      name?: string | null,
      status: PromoPathStatus,
      role?: string | null,
      startLevel?: number | null,
      suggestedLPs?: Array< string > | null,
      targetLevel?: number | null,
      totalRequired?: number | null,
      categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          createdAt: string,
          id: string,
          updatedAt: string,
          description: string,
          isDefault?: string | null,
          name: string,
          role: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        createdAt: string,
        updatedAt: string,
        description: string,
        isDefault?: string | null,
        name: string,
        role: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreatePromoPathMutationVariables = {
  input: CreatePromoPathInput,
  condition?: ModelPromoPathConditionInput | null,
};

export type CreatePromoPathMutation = {
  createPromoPath?:  {
    __typename: "PromoPath",
    id: string,
    createdAt: string,
    updatedAt: string,
    categoryIds?: Array< string > | null,
    allowAttachments?: boolean | null,
    status: PromoPathStatus,
    name?: string | null,
    manager?: string | null,
    candidate?: string | null,
    role?: string | null,
    startLevel?: number | null,
    suggestedLPs?: Array< string > | null,
    targetLevel?: number | null,
    descendants?:  {
      __typename: "ModelPromoPathConnection",
      items:  Array< {
        __typename: "PromoPath",
        id: string,
        candidate?: string | null,
        name?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        createdAt: string,
        updatedAt: string,
        description: string,
        isDefault?: string | null,
        name: string,
        role: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdatePromoPathMutationVariables = {
  input: UpdatePromoPathInput,
  condition?: ModelPromoPathConditionInput | null,
};

export type UpdatePromoPathMutation = {
  updatePromoPath?:  {
    __typename: "PromoPath",
    id: string,
    createdAt: string,
    updatedAt: string,
    categoryIds?: Array< string > | null,
    allowAttachments?: boolean | null,
    status: PromoPathStatus,
    name?: string | null,
    manager?: string | null,
    candidate?: string | null,
    role?: string | null,
    startLevel?: number | null,
    suggestedLPs?: Array< string > | null,
    targetLevel?: number | null,
    descendants?:  {
      __typename: "ModelPromoPathConnection",
      items:  Array< {
        __typename: "PromoPath",
        id: string,
        candidate?: string | null,
        name?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        createdAt: string,
        updatedAt: string,
        description: string,
        isDefault?: string | null,
        name: string,
        role: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCandidatePathMutationVariables = {
  input: DeletePromoPathInput,
  condition?: ModelPromoPathConditionInput | null,
};

export type DeleteCandidatePathMutation = {
  deletePromoPath?:  {
    __typename: "PromoPath",
    id: string,
    name?: string | null,
    template?:  {
      __typename: "PromoPath",
      name?: string | null,
    } | null,
    manager?: string | null,
    candidate?: string | null,
    _version: number,
  } | null,
};

export type DeletePromoPathMutationVariables = {
  input: DeletePromoPathInput,
  condition?: ModelPromoPathConditionInput | null,
};

export type DeletePromoPathMutation = {
  deletePromoPath?:  {
    __typename: "PromoPath",
    id: string,
    name?: string | null,
    manager?: string | null,
    candidate?: string | null,
    _version: number,
  } | null,
};

export type CreateCategoryMutationVariables = {
  input: CreateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type CreateCategoryMutation = {
  createCategory?:  {
    __typename: "Category",
    id: string,
    createdAt: string,
    updatedAt: string,
    description: string,
    isDefault?: string | null,
    name: string,
    role: string,
    status: CategoryStatus,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCategoryMutationVariables = {
  input: UpdateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type UpdateCategoryMutation = {
  updateCategory?:  {
    __typename: "Category",
    id: string,
    createdAt: string,
    updatedAt: string,
    description: string,
    isDefault?: string | null,
    name: string,
    role: string,
    status: CategoryStatus,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCategoryMutationVariables = {
  input: DeleteCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type DeleteCategoryMutation = {
  deleteCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    _version: number,
  } | null,
};

export type CreateUserProfileMutationVariables = {
  input: CreateUserProfileInput,
  condition?: ModelUserProfileConditionInput | null,
};

export type CreateUserProfileMutation = {
  createUserProfile?:  {
    __typename: "UserProfile",
    alias: string,
    createdAt: string,
    updatedAt: string,
    personId?: string | null,
    email: string,
    hasLoggedIn?: boolean | null,
    name?: string | null,
    jobTitle?: string | null,
    jobLevel: number,
    manager: string,
    managerProfile?:  {
      __typename: "UserProfile",
      alias: string,
      email: string,
      name?: string | null,
    } | null,
    isManager: boolean,
    orgName?: string | null,
    preferences?:  {
      __typename: "UserPreferences",
      landingPage?: string | null,
      visualMode?: VisualMode | null,
      density?: Density | null,
    } | null,
    status?: UserProfileStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateUserProfileMutationVariables = {
  input: UpdateUserProfileInput,
  condition?: ModelUserProfileConditionInput | null,
};

export type UpdateUserProfileMutation = {
  updateUserProfile?:  {
    __typename: "UserProfile",
    alias: string,
    createdAt: string,
    updatedAt: string,
    personId?: string | null,
    email: string,
    hasLoggedIn?: boolean | null,
    name?: string | null,
    jobTitle?: string | null,
    jobLevel: number,
    manager: string,
    managerProfile?:  {
      __typename: "UserProfile",
      alias: string,
      email: string,
      name?: string | null,
    } | null,
    isManager: boolean,
    orgName?: string | null,
    preferences?:  {
      __typename: "UserPreferences",
      landingPage?: string | null,
      visualMode?: VisualMode | null,
      density?: Density | null,
    } | null,
    status?: UserProfileStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteUserProfileMutationVariables = {
  input: DeleteUserProfileInput,
  condition?: ModelUserProfileConditionInput | null,
};

export type DeleteUserProfileMutation = {
  deleteUserProfile?:  {
    __typename: "UserProfile",
    alias: string,
    _version: number,
  } | null,
};

export type CreateWorkSummaryFilesMutationVariables = {
  input: CreateWorkSummaryFilesInput,
  condition?: ModelWorkSummaryFilesConditionInput | null,
};

export type CreateWorkSummaryFilesMutation = {
  createWorkSummaryFiles?:  {
    __typename: "WorkSummaryFiles",
    id: string,
    workSummaryID: string,
    fileRecordID: string,
    workSummary:  {
      __typename: "WorkSummary",
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      coOwners?: Array< string > | null,
      alias: string,
      categoryIds?: Array< string > | null,
      content: string,
      leadershipPrinciples?: Array< string > | null,
      status?: WorkSummaryStatus | null,
      statusReason?: WorkSummaryStatusReason | null,
      submittedAt?: string | null,
      title: string,
      type: WorkSummaryType,
      candidatePathId?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    fileRecord:  {
      __typename: "FileRecord",
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      fileName: string,
      s3Key: string,
      status: FileRecordStatus,
      fileSizeBytes: number,
      fileDateModified: number,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateWorkSummaryFilesMutationVariables = {
  input: UpdateWorkSummaryFilesInput,
  condition?: ModelWorkSummaryFilesConditionInput | null,
};

export type UpdateWorkSummaryFilesMutation = {
  updateWorkSummaryFiles?:  {
    __typename: "WorkSummaryFiles",
    id: string,
    workSummaryID: string,
    fileRecordID: string,
    workSummary:  {
      __typename: "WorkSummary",
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      coOwners?: Array< string > | null,
      alias: string,
      categoryIds?: Array< string > | null,
      content: string,
      leadershipPrinciples?: Array< string > | null,
      status?: WorkSummaryStatus | null,
      statusReason?: WorkSummaryStatusReason | null,
      submittedAt?: string | null,
      title: string,
      type: WorkSummaryType,
      candidatePathId?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    fileRecord:  {
      __typename: "FileRecord",
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      fileName: string,
      s3Key: string,
      status: FileRecordStatus,
      fileSizeBytes: number,
      fileDateModified: number,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteWorkSummaryFilesMutationVariables = {
  input: DeleteWorkSummaryFilesInput,
  condition?: ModelWorkSummaryFilesConditionInput | null,
};

export type DeleteWorkSummaryFilesMutation = {
  deleteWorkSummaryFiles?:  {
    __typename: "WorkSummaryFiles",
    id: string,
    workSummaryID: string,
    fileRecordID: string,
    _version: number,
  } | null,
};

export type CreateSharedUserFilesMutationVariables = {
  input: CreateSharedUserFilesInput,
  condition?: ModelSharedUserFilesConditionInput | null,
};

export type CreateSharedUserFilesMutation = {
  createSharedUserFiles?:  {
    __typename: "SharedUserFiles",
    id: string,
    fileRecordID: string,
    userProfileAlias: string,
    fileRecord:  {
      __typename: "FileRecord",
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      fileName: string,
      s3Key: string,
      status: FileRecordStatus,
      fileSizeBytes: number,
      fileDateModified: number,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    userProfile:  {
      __typename: "UserProfile",
      alias: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateSharedUserFilesMutationVariables = {
  input: UpdateSharedUserFilesInput,
  condition?: ModelSharedUserFilesConditionInput | null,
};

export type UpdateSharedUserFilesMutation = {
  updateSharedUserFiles?:  {
    __typename: "SharedUserFiles",
    id: string,
    fileRecordID: string,
    userProfileAlias: string,
    fileRecord:  {
      __typename: "FileRecord",
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      fileName: string,
      s3Key: string,
      status: FileRecordStatus,
      fileSizeBytes: number,
      fileDateModified: number,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    userProfile:  {
      __typename: "UserProfile",
      alias: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteSharedUserFilesMutationVariables = {
  input: DeleteSharedUserFilesInput,
  condition?: ModelSharedUserFilesConditionInput | null,
};

export type DeleteSharedUserFilesMutation = {
  deleteSharedUserFiles?:  {
    __typename: "SharedUserFiles",
    id: string,
    fileRecordID: string,
    userProfileAlias: string,
    _version: number,
  } | null,
};

export type CreateCommentMutationVariables = {
  input: CreateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type CreateCommentMutation = {
  createComment?:  {
    __typename: "Comment",
    createdAt: string,
    id: string,
    owner?: string | null,
    updatedAt: string,
    type: CommentType,
    content: string,
    workSummaryId?: string | null,
    author:  {
      __typename: "UserProfile",
      alias: string,
      name?: string | null,
    },
    status: CommentStatus,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCommentMutationVariables = {
  input: UpdateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type UpdateCommentMutation = {
  updateComment?:  {
    __typename: "Comment",
    createdAt: string,
    id: string,
    owner?: string | null,
    updatedAt: string,
    type: CommentType,
    content: string,
    workSummaryId?: string | null,
    author:  {
      __typename: "UserProfile",
      alias: string,
      name?: string | null,
    },
    status: CommentStatus,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCommentMutationVariables = {
  input: DeleteCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type DeleteCommentMutation = {
  deleteComment?:  {
    __typename: "Comment",
    id: string,
    _version: number,
    workSummaryId?: string | null,
  } | null,
};

export type CreateAuditTrailsMutationVariables = {
  input: CreateAuditTrailsInput,
  condition?: ModelAuditTrailsConditionInput | null,
};

export type CreateAuditTrailsMutation = {
  createAuditTrails?:  {
    __typename: "AuditTrails",
    id: string,
    modifiedBy?: string | null,
    changeJustification?: string | null,
    recordId: string,
    recordType: AuditTrailRecordType,
    rawOldRecord?: string | null,
    rawNewRecord: string,
    humanReadableLog: Array< string >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAuditTrailsMutationVariables = {
  input: UpdateAuditTrailsInput,
  condition?: ModelAuditTrailsConditionInput | null,
};

export type UpdateAuditTrailsMutation = {
  updateAuditTrails?:  {
    __typename: "AuditTrails",
    id: string,
    modifiedBy?: string | null,
    changeJustification?: string | null,
    recordId: string,
    recordType: AuditTrailRecordType,
    rawOldRecord?: string | null,
    rawNewRecord: string,
    humanReadableLog: Array< string >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAuditTrailsMutationVariables = {
  input: DeleteAuditTrailsInput,
  condition?: ModelAuditTrailsConditionInput | null,
};

export type DeleteAuditTrailsMutation = {
  deleteAuditTrails?:  {
    __typename: "AuditTrails",
    id: string,
    modifiedBy?: string | null,
    changeJustification?: string | null,
    recordId: string,
    recordType: AuditTrailRecordType,
    rawOldRecord?: string | null,
    rawNewRecord: string,
    humanReadableLog: Array< string >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type GetDirectReportsExternalQueryVariables = {
  alias: string,
};

export type GetDirectReportsExternalQuery = {
  getDirectReports:  {
    __typename: "ManagerProfile",
    alias: string,
    directReports?:  Array< {
      __typename: "DirectReport",
      alias: string,
      hireDate: string,
      personId: string,
      jobLevel: number,
      isManager: boolean,
      firstName: string,
      lastName: string,
      email: string,
    } > | null,
  },
};

export type GetDirectReportsQueryVariables = {
  manager: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetDirectReportsQuery = {
  listProfilesByManager?:  {
    __typename: "ModelUserProfileConnection",
    items:  Array< {
      __typename: "UserProfile",
      alias: string,
      email: string,
      hireDate?: string | null,
      isManager: boolean,
      jobLevel: number,
      name?: string | null,
      _deleted?: boolean | null,
      _version: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  alias: string,
};

export type GetUserQuery = {
  getUser:  {
    __typename: "PeopleProfile",
    alias: string,
    jobLevel: number,
    hrJobTitle: string,
    isManager: boolean,
    firstName: string,
    lastName: string,
    email: string,
    managerAlias?: string | null,
    managerName?: string | null,
  },
};

export type FindUserQueryVariables = {
  alias: string,
};

export type FindUserQuery = {
  findUser?:  {
    __typename: "PeopleProfile",
    alias: string,
    jobLevel: number,
    hrJobTitle: string,
    isManager: boolean,
    firstName: string,
    lastName: string,
    email: string,
    managerAlias?: string | null,
    managerName?: string | null,
  } | null,
};

export type GetFileDownloadUrlQueryVariables = {
  referenceId?: string | null,
  fileRecordType?: FileRecordType | null,
  fileRecordId?: string | null,
};

export type GetFileDownloadUrlQuery = {
  getFileDownloadUrl:  {
    __typename: "DownloadableFile",
    referenceId?: string | null,
    fileRecordType?: FileRecordType | null,
    fileRecordId?: string | null,
    presignedURL?: string | null,
  },
};

export type GetWorkSummaryQueryVariables = {
  id: string,
  peerReviewFilter?: ModelPeerReviewFilterInput | null,
};

export type GetWorkSummaryQuery = {
  getWorkSummary?:  {
    __typename: "WorkSummary",
    createdAt: string,
    id: string,
    coOwners?: Array< string > | null,
    updatedAt: string,
    alias: string,
    candidatePathId?: string | null,
    content: string,
    contentVersion?: number | null,
    leadershipPrinciples?: Array< string > | null,
    status?: WorkSummaryStatus | null,
    statusReason?: WorkSummaryStatusReason | null,
    submittedAt?: string | null,
    title: string,
    type: WorkSummaryType,
    categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        name: string,
      } | null >,
    } | null,
    documents?:  {
      __typename: "ModelWorkSummaryFilesConnection",
      items:  Array< {
        __typename: "WorkSummaryFiles",
        id: string,
        fileRecord:  {
          __typename: "FileRecord",
          createdAt: string,
          id: string,
          owner?: string | null,
          updatedAt: string,
          fileDateModified: number,
          fileName: string,
          fileSizeBytes: number,
          status: FileRecordStatus,
          s3Key: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    peerReviews?:  {
      __typename: "ModelPeerReviewConnection",
      items:  Array< {
        __typename: "PeerReview",
        createdAt: string,
        id: string,
        coOwners?: Array< string > | null,
        updatedAt: string,
        reviewerAlias: string,
        reviewer:  {
          __typename: "UserProfile",
          name?: string | null,
        },
        reviewStatus?: PeerReviewStatus | null,
        reviewType: PeerReviewType,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListWorkSummariesByAliasQueryVariables = {
  alias: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWorkSummaryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWorkSummariesByAliasQuery = {
  listWorkSummariesByAlias?:  {
    __typename: "ModelWorkSummaryConnection",
    items:  Array< {
      __typename: "WorkSummary",
      createdAt: string,
      id: string,
      coOwners?: Array< string > | null,
      updatedAt: string,
      alias: string,
      candidatePathId?: string | null,
      leadershipPrinciples?: Array< string > | null,
      status?: WorkSummaryStatus | null,
      statusReason?: WorkSummaryStatusReason | null,
      submittedAt?: string | null,
      title: string,
      type: WorkSummaryType,
      categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
        } | null >,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
        } | null >,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListWorkSummariesByAliasAdminQueryVariables = {
  alias: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWorkSummaryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWorkSummariesByAliasAdminQuery = {
  listWorkSummariesByAlias?:  {
    __typename: "ModelWorkSummaryConnection",
    items:  Array< {
      __typename: "WorkSummary",
      createdAt: string,
      id: string,
      coOwners?: Array< string > | null,
      updatedAt: string,
      alias: string,
      candidatePathId?: string | null,
      status?: WorkSummaryStatus | null,
      statusReason?: WorkSummaryStatusReason | null,
      submittedAt?: string | null,
      title: string,
      type: WorkSummaryType,
      categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
        } | null >,
      } | null,
      peerReviews?:  {
        __typename: "ModelPeerReviewConnection",
        items:  Array< {
          __typename: "PeerReview",
          id: string,
          reviewerAlias: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
      candidatePath?:  {
        __typename: "PromoPath",
        id: string,
        name?: string | null,
        manager?: string | null,
        _version: number,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListWorkSummariesByAliasAndCategoryQueryVariables = {
  alias: string,
  categoryId: string,
  sortDirection?: ModelSortDirection | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWorkSummariesByAliasAndCategoryQuery = {
  listWorkSummariesByAlias?:  {
    __typename: "ModelWorkSummaryConnection",
    items:  Array< {
      __typename: "WorkSummary",
      createdAt: string,
      id: string,
      coOwners?: Array< string > | null,
      updatedAt: string,
      alias: string,
      candidatePathId?: string | null,
      leadershipPrinciples?: Array< string > | null,
      status?: WorkSummaryStatus | null,
      statusReason?: WorkSummaryStatusReason | null,
      submittedAt?: string | null,
      title: string,
      type: WorkSummaryType,
      categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          status: CategoryStatus,
        } | null >,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
        } | null >,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListWorkSummariesByCandidatePathQueryVariables = {
  candidatePathId: string,
  submittedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWorkSummaryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWorkSummariesByCandidatePathQuery = {
  listWorkSummariesByCandidatePath?:  {
    __typename: "ModelWorkSummaryConnection",
    items:  Array< {
      __typename: "WorkSummary",
      createdAt: string,
      id: string,
      coOwners?: Array< string > | null,
      updatedAt: string,
      alias: string,
      candidatePathId?: string | null,
      leadershipPrinciples?: Array< string > | null,
      status?: WorkSummaryStatus | null,
      statusReason?: WorkSummaryStatusReason | null,
      submittedAt?: string | null,
      title: string,
      type: WorkSummaryType,
      categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
        } | null >,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
        } | null >,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFileInfoQueryVariables = {
  id: string,
};

export type GetFileInfoQuery = {
  getFileRecord?:  {
    __typename: "FileRecord",
    createdAt: string,
    id: string,
    owner?: string | null,
    updatedAt: string,
    fileDateModified: number,
    fileName: string,
    fileSizeBytes: number,
    status: FileRecordStatus,
    s3Key: string,
    storageIdKey: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type GetFileRecordQueryVariables = {
  id: string,
};

export type GetFileRecordQuery = {
  getFileRecord?:  {
    __typename: "FileRecord",
    createdAt: string,
    id: string,
    owner?: string | null,
    updatedAt: string,
    fileDateModified: number,
    fileName: string,
    fileSizeBytes: number,
    status: FileRecordStatus,
    s3Key: string,
    storageIdKey: string,
    workSummaries?:  {
      __typename: "ModelWorkSummaryFilesConnection",
      items:  Array< {
        __typename: "WorkSummaryFiles",
        id: string,
        workSummaryID: string,
        workSummary:  {
          __typename: "WorkSummary",
          title: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelSharedUserFilesConnection",
      items:  Array< {
        __typename: "SharedUserFiles",
        id: string,
        userProfileAlias: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListFilesByOwnerQueryVariables = {
  owner: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFileRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFilesByOwnerQuery = {
  listFilesByOwner?:  {
    __typename: "ModelFileRecordConnection",
    items:  Array< {
      __typename: "FileRecord",
      createdAt: string,
      id: string,
      owner?: string | null,
      updatedAt: string,
      fileDateModified: number,
      fileName: string,
      fileSizeBytes: number,
      status: FileRecordStatus,
      s3Key: string,
      storageIdKey: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCandidatePromoPathQueryVariables = {
  id: string,
};

export type GetCandidatePromoPathQuery = {
  getPromoPath?:  {
    __typename: "PromoPath",
    id: string,
    createdAt: string,
    updatedAt: string,
    allowAttachments?: boolean | null,
    candidate?: string | null,
    categoryIds?: Array< string > | null,
    status: PromoPathStatus,
    manager?: string | null,
    name?: string | null,
    role?: string | null,
    startLevel?: number | null,
    suggestedLPs?: Array< string > | null,
    targetDate?: string | null,
    targetLevel?: number | null,
    templateId?: string | null,
    totalCompleted?: number | null,
    totalRequired?: number | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        createdAt: string,
        id: string,
        updatedAt: string,
        description: string,
        isDefault?: string | null,
        name: string,
        role: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    template?:  {
      __typename: "PromoPath",
      createdAt: string,
      id: string,
      updatedAt: string,
      allowAttachments?: boolean | null,
      categoryIds?: Array< string > | null,
      name?: string | null,
      status: PromoPathStatus,
      role?: string | null,
      startLevel?: number | null,
      suggestedLPs?: Array< string > | null,
      targetLevel?: number | null,
      totalRequired?: number | null,
      categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          createdAt: string,
          id: string,
          updatedAt: string,
          description: string,
          isDefault?: string | null,
          name: string,
          role: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    workSummaries?:  {
      __typename: "ModelWorkSummaryConnection",
      items:  Array< {
        __typename: "WorkSummary",
        id: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPromoPathsByCandidateQueryVariables = {
  candidate: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPromoPathFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPromoPathsByCandidateQuery = {
  listPromoPathsByCandidate?:  {
    __typename: "ModelPromoPathConnection",
    items:  Array< {
      __typename: "PromoPath",
      id: string,
      createdAt: string,
      updatedAt: string,
      allowAttachments?: boolean | null,
      candidate?: string | null,
      status: PromoPathStatus,
      manager?: string | null,
      name?: string | null,
      role?: string | null,
      startLevel?: number | null,
      suggestedLPs?: Array< string > | null,
      targetDate?: string | null,
      targetLevel?: number | null,
      templateId?: string | null,
      totalCompleted?: number | null,
      totalRequired?: number | null,
      categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          createdAt: string,
          id: string,
          updatedAt: string,
          description: string,
          isDefault?: string | null,
          name: string,
          role: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
      template?:  {
        __typename: "PromoPath",
        createdAt: string,
        id: string,
        updatedAt: string,
        allowAttachments?: boolean | null,
        categoryIds?: Array< string > | null,
        status: PromoPathStatus,
        name?: string | null,
        role?: string | null,
        startLevel?: number | null,
        suggestedLPs?: Array< string > | null,
        targetLevel?: number | null,
        totalRequired?: number | null,
        categories?:  {
          __typename: "ModelCategoryConnection",
          items:  Array< {
            __typename: "Category",
            createdAt: string,
            id: string,
            updatedAt: string,
            description: string,
            isDefault?: string | null,
            name: string,
            role: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      workSummaries?:  {
        __typename: "ModelWorkSummaryConnection",
        items:  Array< {
          __typename: "WorkSummary",
          id: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCandidatePathsByManagerQueryVariables = {
  manager: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPromoPathFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCandidatePathsByManagerQuery = {
  listPromoPathsByManagerAndType?:  {
    __typename: "ModelPromoPathConnection",
    items:  Array< {
      __typename: "PromoPath",
      id: string,
      createdAt: string,
      updatedAt: string,
      allowAttachments?: boolean | null,
      candidate?: string | null,
      status: PromoPathStatus,
      manager?: string | null,
      name?: string | null,
      role?: string | null,
      startLevel?: number | null,
      suggestedLPs?: Array< string > | null,
      targetDate?: string | null,
      targetLevel?: number | null,
      templateId?: string | null,
      totalCompleted?: number | null,
      totalRequired?: number | null,
      categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          createdAt: string,
          id: string,
          updatedAt: string,
          description: string,
          isDefault?: string | null,
          name: string,
          role: string,
          status: CategoryStatus,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
      template?:  {
        __typename: "PromoPath",
        createdAt: string,
        id: string,
        updatedAt: string,
        allowAttachments?: boolean | null,
        status: PromoPathStatus,
        name?: string | null,
        role?: string | null,
        startLevel?: number | null,
        suggestedLPs?: Array< string > | null,
        targetLevel?: number | null,
        totalRequired?: number | null,
        categories?:  {
          __typename: "ModelCategoryConnection",
          items:  Array< {
            __typename: "Category",
            createdAt: string,
            id: string,
            updatedAt: string,
            description: string,
            isDefault?: string | null,
            name: string,
            role: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      workSummaries?:  {
        __typename: "ModelWorkSummaryConnection",
        items:  Array< {
          __typename: "WorkSummary",
          createdAt: string,
          id: string,
          coOwners?: Array< string > | null,
          updatedAt: string,
          alias: string,
          candidatePathId?: string | null,
          leadershipPrinciples?: Array< string > | null,
          status?: WorkSummaryStatus | null,
          statusReason?: WorkSummaryStatusReason | null,
          submittedAt?: string | null,
          title: string,
          type: WorkSummaryType,
          categories?:  {
            __typename: "ModelCategoryConnection",
            items:  Array< {
              __typename: "Category",
              id: string,
              name: string,
              status: CategoryStatus,
            } | null >,
          } | null,
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
            } | null >,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCandidatePathsByTemplateQueryVariables = {
  templateId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPromoPathFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCandidatePathsByTemplateQuery = {
  listPromoPathsByTemplateAndType?:  {
    __typename: "ModelPromoPathConnection",
    items:  Array< {
      __typename: "PromoPath",
      id: string,
      createdAt: string,
      updatedAt: string,
      allowAttachments?: boolean | null,
      candidate?: string | null,
      status: PromoPathStatus,
      manager?: string | null,
      name?: string | null,
      role?: string | null,
      startLevel?: number | null,
      suggestedLPs?: Array< string > | null,
      targetDate?: string | null,
      targetLevel?: number | null,
      templateId?: string | null,
      totalCompleted?: number | null,
      totalRequired?: number | null,
      categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          createdAt: string,
          id: string,
          updatedAt: string,
          description: string,
          isDefault?: string | null,
          name: string,
          role: string,
          status: CategoryStatus,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
      workSummaries?:  {
        __typename: "ModelWorkSummaryConnection",
        items:  Array< {
          __typename: "WorkSummary",
          createdAt: string,
          id: string,
          coOwners?: Array< string > | null,
          updatedAt: string,
          alias: string,
          candidatePathId?: string | null,
          leadershipPrinciples?: Array< string > | null,
          status?: WorkSummaryStatus | null,
          statusReason?: WorkSummaryStatusReason | null,
          submittedAt?: string | null,
          title: string,
          type: WorkSummaryType,
          categories?:  {
            __typename: "ModelCategoryConnection",
            items:  Array< {
              __typename: "Category",
              id: string,
              name: string,
            } | null >,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPromoPathQueryVariables = {
  id: string,
};

export type GetPromoPathQuery = {
  getPromoPath?:  {
    __typename: "PromoPath",
    createdAt: string,
    id: string,
    updatedAt: string,
    allowAttachments?: boolean | null,
    status: PromoPathStatus,
    name?: string | null,
    role?: string | null,
    startLevel?: number | null,
    suggestedLPs?: Array< string > | null,
    targetLevel?: number | null,
    totalRequired?: number | null,
    descendants?:  {
      __typename: "ModelPromoPathConnection",
      items:  Array< {
        __typename: "PromoPath",
        id: string,
        candidate?: string | null,
        name?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        createdAt: string,
        id: string,
        updatedAt: string,
        description: string,
        isDefault?: string | null,
        name: string,
        role: string,
        status: CategoryStatus,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPromoPathsByOwnerQueryVariables = {
  manager: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPromoPathFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPromoPathsByOwnerQuery = {
  listPromoPathsByManagerAndType?:  {
    __typename: "ModelPromoPathConnection",
    items:  Array< {
      __typename: "PromoPath",
      createdAt: string,
      id: string,
      updatedAt: string,
      allowAttachments?: boolean | null,
      categoryIds?: Array< string > | null,
      status: PromoPathStatus,
      name?: string | null,
      role?: string | null,
      startLevel?: number | null,
      suggestedLPs?: Array< string > | null,
      targetLevel?: number | null,
      descendants?:  {
        __typename: "ModelPromoPathConnection",
        items:  Array< {
          __typename: "PromoPath",
          id: string,
          candidate?: string | null,
          name?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
      categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          createdAt: string,
          id: string,
          updatedAt: string,
          description: string,
          isDefault?: string | null,
          name: string,
          role: string,
          status: CategoryStatus,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCategoryQueryVariables = {
  id: string,
};

export type GetCategoryQuery = {
  getCategory?:  {
    __typename: "Category",
    createdAt: string,
    id: string,
    updatedAt: string,
    description: string,
    isDefault?: string | null,
    name: string,
    role: string,
    status: CategoryStatus,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCategoriesByRoleQueryVariables = {
  role: string,
  isDefault?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCategoriesByRoleQuery = {
  listCategoriesByRole?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      createdAt: string,
      id: string,
      updatedAt: string,
      description: string,
      isDefault?: string | null,
      name: string,
      role: string,
      status: CategoryStatus,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserIdentityIdQueryVariables = {
  alias: string,
};

export type GetUserIdentityIdQuery = {
  getUserProfile?:  {
    __typename: "UserProfile",
    identityId?: string | null,
  } | null,
};

export type GetUserProfileWithIdentityQueryVariables = {
  alias: string,
};

export type GetUserProfileWithIdentityQuery = {
  getUserProfile?:  {
    __typename: "UserProfile",
    alias: string,
    createdAt: string,
    updatedAt: string,
    email: string,
    hasLoggedIn?: boolean | null,
    hireDate?: string | null,
    identityId?: string | null,
    isManager: boolean,
    jobLevel: number,
    jobTitle?: string | null,
    manager: string,
    managerProfile?:  {
      __typename: "UserProfile",
      alias: string,
      email: string,
      name?: string | null,
    } | null,
    name?: string | null,
    orgName?: string | null,
    personId?: string | null,
    preferences?:  {
      __typename: "UserPreferences",
      landingPage?: string | null,
      visualMode?: VisualMode | null,
      density?: Density | null,
    } | null,
    status?: UserProfileStatus | null,
    sharedFiles?:  {
      __typename: "ModelSharedUserFilesConnection",
      items:  Array< {
        __typename: "SharedUserFiles",
        id: string,
        fileRecord:  {
          __typename: "FileRecord",
          createdAt: string,
          id: string,
          owner?: string | null,
          updatedAt: string,
          fileDateModified: number,
          fileName: string,
          fileSizeBytes: number,
          status: FileRecordStatus,
          s3Key: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type GetUserProfileQueryVariables = {
  alias: string,
};

export type GetUserProfileQuery = {
  getUserProfile?:  {
    __typename: "UserProfile",
    alias: string,
    createdAt: string,
    updatedAt: string,
    email: string,
    hasLoggedIn?: boolean | null,
    hireDate?: string | null,
    isManager: boolean,
    jobLevel: number,
    jobTitle?: string | null,
    manager: string,
    managerProfile?:  {
      __typename: "UserProfile",
      alias: string,
      email: string,
      name?: string | null,
    } | null,
    name?: string | null,
    orgName?: string | null,
    personId?: string | null,
    preferences?:  {
      __typename: "UserPreferences",
      landingPage?: string | null,
      visualMode?: VisualMode | null,
      density?: Density | null,
    } | null,
    status?: UserProfileStatus | null,
    sharedFiles?:  {
      __typename: "ModelSharedUserFilesConnection",
      items:  Array< {
        __typename: "SharedUserFiles",
        id: string,
        fileRecord:  {
          __typename: "FileRecord",
          createdAt: string,
          id: string,
          owner?: string | null,
          updatedAt: string,
          fileDateModified: number,
          fileName: string,
          fileSizeBytes: number,
          status: FileRecordStatus,
          s3Key: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type GetWorkSummaryFilesQueryVariables = {
  id: string,
};

export type GetWorkSummaryFilesQuery = {
  getWorkSummaryFiles?:  {
    __typename: "WorkSummaryFiles",
    id: string,
    workSummaryID: string,
    fileRecordID: string,
    workSummary:  {
      __typename: "WorkSummary",
      id: string,
      title: string,
      documents?:  {
        __typename: "ModelWorkSummaryFilesConnection",
        items:  Array< {
          __typename: "WorkSummaryFiles",
          id: string,
          fileRecordID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    fileRecord:  {
      __typename: "FileRecord",
      id: string,
      storageIdKey: string,
      workSummaries?:  {
        __typename: "ModelWorkSummaryFilesConnection",
        items:  Array< {
          __typename: "WorkSummaryFiles",
          id: string,
          workSummaryID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelSharedUserFilesConnection",
        items:  Array< {
          __typename: "SharedUserFiles",
          id: string,
          userProfileAlias: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type GetSharedUserFilesQueryVariables = {
  id: string,
};

export type GetSharedUserFilesQuery = {
  getSharedUserFiles?:  {
    __typename: "SharedUserFiles",
    id: string,
    fileRecordID: string,
    userProfileAlias: string,
    fileRecord:  {
      __typename: "FileRecord",
      createdAt: string,
      id: string,
      owner?: string | null,
      updatedAt: string,
      fileDateModified: number,
      fileName: string,
      fileSizeBytes: number,
      status: FileRecordStatus,
      s3Key: string,
      workSummaries?:  {
        __typename: "ModelWorkSummaryFilesConnection",
        items:  Array< {
          __typename: "WorkSummaryFiles",
          id: string,
          workSummaryID: string,
          fileRecordID: string,
          workSummary:  {
            __typename: "WorkSummary",
            createdAt: string,
            id: string,
            coOwners?: Array< string > | null,
            updatedAt: string,
            alias: string,
            candidatePathId?: string | null,
            categoryIds?: Array< string > | null,
            content: string,
            leadershipPrinciples?: Array< string > | null,
            status?: WorkSummaryStatus | null,
            statusReason?: WorkSummaryStatusReason | null,
            submittedAt?: string | null,
            title: string,
            type: WorkSummaryType,
            categories?:  {
              __typename: "ModelCategoryConnection",
              items:  Array< {
                __typename: "Category",
                createdAt: string,
                id: string,
                updatedAt: string,
                description: string,
                isDefault?: string | null,
                name: string,
                role: string,
                _version: number,
                _deleted?: boolean | null,
                _lastChangedAt: number,
                owner?: string | null,
              } | null >,
            } | null,
            documents?:  {
              __typename: "ModelWorkSummaryFilesConnection",
              items:  Array< {
                __typename: "WorkSummaryFiles",
                id: string,
                workSummaryID: string,
                fileRecordID: string,
                createdAt: string,
                updatedAt: string,
                _version: number,
                _deleted?: boolean | null,
                _lastChangedAt: number,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          fileRecord:  {
            __typename: "FileRecord",
            createdAt: string,
            id: string,
            owner?: string | null,
            updatedAt: string,
            fileDateModified: number,
            fileName: string,
            fileSizeBytes: number,
            status: FileRecordStatus,
            s3Key: string,
            workSummaries?:  {
              __typename: "ModelWorkSummaryFilesConnection",
              items:  Array< {
                __typename: "WorkSummaryFiles",
                id: string,
                workSummaryID: string,
                fileRecordID: string,
                createdAt: string,
                updatedAt: string,
                _version: number,
                _deleted?: boolean | null,
                _lastChangedAt: number,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            users?:  {
              __typename: "ModelSharedUserFilesConnection",
              items:  Array< {
                __typename: "SharedUserFiles",
                id: string,
                fileRecordID: string,
                userProfileAlias: string,
                createdAt: string,
                updatedAt: string,
                _version: number,
                _deleted?: boolean | null,
                _lastChangedAt: number,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelSharedUserFilesConnection",
        items:  Array< {
          __typename: "SharedUserFiles",
          id: string,
          fileRecordID: string,
          userProfileAlias: string,
          fileRecord:  {
            __typename: "FileRecord",
            createdAt: string,
            id: string,
            owner?: string | null,
            updatedAt: string,
            fileDateModified: number,
            fileName: string,
            fileSizeBytes: number,
            status: FileRecordStatus,
            s3Key: string,
            workSummaries?:  {
              __typename: "ModelWorkSummaryFilesConnection",
              items:  Array< {
                __typename: "WorkSummaryFiles",
                id: string,
                workSummaryID: string,
                fileRecordID: string,
                createdAt: string,
                updatedAt: string,
                _version: number,
                _deleted?: boolean | null,
                _lastChangedAt: number,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            users?:  {
              __typename: "ModelSharedUserFilesConnection",
              items:  Array< {
                __typename: "SharedUserFiles",
                id: string,
                fileRecordID: string,
                userProfileAlias: string,
                createdAt: string,
                updatedAt: string,
                _version: number,
                _deleted?: boolean | null,
                _lastChangedAt: number,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          userProfile:  {
            __typename: "UserProfile",
            alias: string,
            createdAt: string,
            owner?: string | null,
            updatedAt: string,
            email: string,
            hasLoggedIn?: boolean | null,
            personId?: string | null,
            preferences?:  {
              __typename: "UserPreferences",
              landingPage?: string | null,
              visualMode?: VisualMode | null,
              density?: Density | null,
            } | null,
            sharedFiles?:  {
              __typename: "ModelSharedUserFilesConnection",
              items:  Array< {
                __typename: "SharedUserFiles",
                id: string,
                fileRecordID: string,
                userProfileAlias: string,
                createdAt: string,
                updatedAt: string,
                _version: number,
                _deleted?: boolean | null,
                _lastChangedAt: number,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    userProfile:  {
      __typename: "UserProfile",
      alias: string,
      createdAt: string,
      owner?: string | null,
      updatedAt: string,
      email: string,
      hasLoggedIn?: boolean | null,
      personId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        landingPage?: string | null,
        visualMode?: VisualMode | null,
        density?: Density | null,
      } | null,
      sharedFiles?:  {
        __typename: "ModelSharedUserFilesConnection",
        items:  Array< {
          __typename: "SharedUserFiles",
          id: string,
          fileRecordID: string,
          userProfileAlias: string,
          fileRecord:  {
            __typename: "FileRecord",
            createdAt: string,
            id: string,
            owner?: string | null,
            updatedAt: string,
            fileDateModified: number,
            fileName: string,
            fileSizeBytes: number,
            status: FileRecordStatus,
            s3Key: string,
            workSummaries?:  {
              __typename: "ModelWorkSummaryFilesConnection",
              items:  Array< {
                __typename: "WorkSummaryFiles",
                id: string,
                workSummaryID: string,
                fileRecordID: string,
                createdAt: string,
                updatedAt: string,
                _version: number,
                _deleted?: boolean | null,
                _lastChangedAt: number,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            users?:  {
              __typename: "ModelSharedUserFilesConnection",
              items:  Array< {
                __typename: "SharedUserFiles",
                id: string,
                fileRecordID: string,
                userProfileAlias: string,
                createdAt: string,
                updatedAt: string,
                _version: number,
                _deleted?: boolean | null,
                _lastChangedAt: number,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          userProfile:  {
            __typename: "UserProfile",
            alias: string,
            createdAt: string,
            owner?: string | null,
            updatedAt: string,
            email: string,
            hasLoggedIn?: boolean | null,
            personId?: string | null,
            preferences?:  {
              __typename: "UserPreferences",
              landingPage?: string | null,
              visualMode?: VisualMode | null,
              density?: Density | null,
            } | null,
            sharedFiles?:  {
              __typename: "ModelSharedUserFilesConnection",
              items:  Array< {
                __typename: "SharedUserFiles",
                id: string,
                fileRecordID: string,
                userProfileAlias: string,
                createdAt: string,
                updatedAt: string,
                _version: number,
                _deleted?: boolean | null,
                _lastChangedAt: number,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type GetPeerReviewQueryVariables = {
  id: string,
};

export type GetPeerReviewQuery = {
  getPeerReview?:  {
    __typename: "PeerReview",
    createdAt: string,
    id: string,
    coOwners?: Array< string > | null,
    updatedAt: string,
    requiredBy: string,
    candidatePathId?: string | null,
    reviewerAlias: string,
    reviewer:  {
      __typename: "UserProfile",
      name?: string | null,
    },
    reviewStatus?: PeerReviewStatus | null,
    reviewType: PeerReviewType,
    content?: string | null,
    reviewedContentVersion?: number | null,
    candidatePath?:  {
      __typename: "PromoPath",
      id: string,
      candidate?: string | null,
      manager?: string | null,
      name?: string | null,
      targetDate?: string | null,
      targetLevel?: number | null,
      template?:  {
        __typename: "PromoPath",
        id: string,
        name?: string | null,
        targetLevel?: number | null,
      } | null,
    } | null,
    workSummary?:  {
      __typename: "WorkSummary",
      id: string,
      alias: string,
      content: string,
      contentVersion?: number | null,
      status?: WorkSummaryStatus | null,
      title: string,
      type: WorkSummaryType,
      categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          description: string,
        } | null >,
      } | null,
      documents?:  {
        __typename: "ModelWorkSummaryFilesConnection",
        items:  Array< {
          __typename: "WorkSummaryFiles",
          id: string,
          fileRecord:  {
            __typename: "FileRecord",
            createdAt: string,
            id: string,
            owner?: string | null,
            updatedAt: string,
            fileDateModified: number,
            fileName: string,
            fileSizeBytes: number,
            status: FileRecordStatus,
            s3Key: string,
            storageIdKey: string,
            _deleted?: boolean | null,
          },
          _deleted?: boolean | null,
        } | null >,
      } | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPeerReviewsByWorkSummaryQueryVariables = {
  workSummaryId: string,
  reviewType: ModelPeerReviewTypeInput,
  sortDirection?: ModelSortDirection | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPeerReviewsByWorkSummaryQuery = {
  getWorkSummary?:  {
    __typename: "WorkSummary",
    contentVersion?: number | null,
    peerReviews?:  {
      __typename: "ModelPeerReviewConnection",
      items:  Array< {
        __typename: "PeerReview",
        createdAt: string,
        id: string,
        coOwners?: Array< string > | null,
        updatedAt: string,
        reviewerAlias: string,
        reviewer:  {
          __typename: "UserProfile",
          name?: string | null,
        },
        reviewStatus?: PeerReviewStatus | null,
        reviewType: PeerReviewType,
        content?: string | null,
        reviewedContentVersion?: number | null,
        requiredBy: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListPeerReviewsByCandidatePathQueryVariables = {
  candidatePathId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPeerReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPeerReviewsByCandidatePathQuery = {
  listPeerReviewsByCandidatePath?:  {
    __typename: "ModelPeerReviewConnection",
    items:  Array< {
      __typename: "PeerReview",
      createdAt: string,
      id: string,
      coOwners?: Array< string > | null,
      updatedAt: string,
      content?: string | null,
      reviewerAlias: string,
      reviewStatus?: PeerReviewStatus | null,
      reviewType: PeerReviewType,
      reviewedContentVersion?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPeerReviewsByReviewerQueryVariables = {
  reviewerAlias: string,
  reviewStatus?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPeerReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPeerReviewsByReviewerQuery = {
  listPeerReviewsByReviewer?:  {
    __typename: "ModelPeerReviewConnection",
    items:  Array< {
      __typename: "PeerReview",
      createdAt: string,
      id: string,
      coOwners?: Array< string > | null,
      updatedAt: string,
      requiredBy: string,
      content?: string | null,
      reviewerAlias: string,
      reviewer:  {
        __typename: "UserProfile",
        name?: string | null,
      },
      reviewStatus?: PeerReviewStatus | null,
      reviewType: PeerReviewType,
      reviewedContentVersion?: number | null,
      candidatePath?:  {
        __typename: "PromoPath",
        id: string,
        candidate?: string | null,
        name?: string | null,
        manager?: string | null,
        targetLevel?: number | null,
        template?:  {
          __typename: "PromoPath",
          id: string,
          name?: string | null,
          targetLevel?: number | null,
        } | null,
      } | null,
      workSummary?:  {
        __typename: "WorkSummary",
        id: string,
        title: string,
        alias: string,
        content: string,
        type: WorkSummaryType,
        categories?:  {
          __typename: "ModelCategoryConnection",
          items:  Array< {
            __typename: "Category",
            id: string,
            name: string,
          } | null >,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
          } | null >,
        } | null,
        contentVersion?: number | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCommentQueryVariables = {
  id: string,
};

export type GetCommentQuery = {
  getComment?:  {
    __typename: "Comment",
    createdAt: string,
    id: string,
    updatedAt: string,
    type: CommentType,
    content: string,
    workSummaryId?: string | null,
    status: CommentStatus,
    author:  {
      __typename: "UserProfile",
      alias: string,
      name?: string | null,
    },
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCommentsByWorkSummaryAndTypeQueryVariables = {
  workSummaryId: string,
  type: CommentType,
  status: CommentStatus,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommentsByWorkSummaryAndTypeQuery = {
  listCommentsByWorkSummary?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      createdAt: string,
      id: string,
      updatedAt: string,
      type: CommentType,
      content: string,
      status: CommentStatus,
      workSummaryId?: string | null,
      author:  {
        __typename: "UserProfile",
        alias: string,
        name?: string | null,
      },
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListAuditTrailsByTypeAndIdQueryVariables = {
  recordId: string,
  recordType: AuditTrailRecordType,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAuditTrailsByTypeAndIdQuery = {
  listAuditTrailsByTypeAndId?:  {
    __typename: "ModelAuditTrailsConnection",
    items:  Array< {
      __typename: "AuditTrails",
      id: string,
      modifiedBy?: string | null,
      recordId: string,
      recordType: AuditTrailRecordType,
      humanReadableLog: Array< string >,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
  } | null,
};
