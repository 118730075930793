import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { RESOURCES } from '@/common/constants';
import { getNameDefinition } from '@/common/labels';
import {
  ActionDefinition,
  TableHeaderParams,
  PromoTableDefinition,
  ManagerPathItem,
  HeaderActionSetParams,
  ItemAction,
} from '@/models';
import { PromoButton } from '@/components/common/Buttons';
import TableHeader from '@/components/common/table/TableHeader';
import { CreatePromoPathPage, EditPromoPathPage } from '@/common/pages';

const Resource = RESOURCES.PROMOPATH;
const ResourceName = getNameDefinition(Resource);

const CounterText = (items: readonly ManagerPathItem[]) => {
  const totalItems = items.length ?? 0;
  // Per AWS Style Guide always set to 0 if no items.
  if (!totalItems) {
    return '(0)';
  }
  return `(${totalItems})`;
};

export function getCreateAction(): ActionDefinition {
  return {
    action: 'create',
    targetType: 'route',
    actionParams: { page: CreatePromoPathPage },
  };
}

export function getDeleteAction(onDelete: () => void): ActionDefinition {
  return {
    action: 'delete',
    targetType: 'function',
    actionParams: {
      doAction: onDelete,
    },
  };
}

export function getEditAction(): ActionDefinition {
  return {
    action: 'edit',
    targetType: 'route',
    actionParams: { page: EditPromoPathPage },
  };
}

/** Params - {@link HeaderActionSetParams} */
export const ActionSet = ({ selectedItem, actionDefs }: HeaderActionSetParams<ManagerPathItem>) => {
  const actionToIsDisabled: Partial<{ [K in ItemAction]: boolean }> = {
    create: false,
    edit: !selectedItem,
    delete: !selectedItem,
  };
  const actions: ItemAction[] = ['edit', 'delete', 'create'];
  // Action buttons have a specific order of precedence, which the caller doesn't need to know about
  // so deliberately order based on the setup specific to this action set.
  const orderedActionDefs: ActionDefinition[] = [];
  actions.forEach((action) => {
    let actionDef = actionDefs.find((def) => def.action === action);
    if (actionDef) {
      if (actionDef.action === 'edit') {
        actionDef = {
          ...actionDef,
          actionParams: {
            ...actionDef.actionParams,
            itemId: selectedItem?.id,
          },
        };
      }
      orderedActionDefs.push(actionDef);
    }
  });

  return (
    <SpaceBetween direction="horizontal" size="s">
      {orderedActionDefs.map((def) => (
        <PromoButton
          key={`promopath.action.${def.action}`}
          action={def.action}
          actionParams={def.actionParams}
          isDisabled={actionToIsDisabled[def.action]}
          resourceName={ResourceName}
          targetType={def.targetType}
        />
      ))}
    </SpaceBetween>
  );
};

/**
 * Table header component for a `PromoPath` table.
 * Header text consists of the `tableKey` from {@link PromoTableDefinition}, and the total item count.
 * When all available summaries are being shown, the header will indicate the total `Approved` summaries.
 *
 * Returns a {@link https://refresh.polaris.a2z.com/components/header/?tabId=playground Polaris Header}
 */
const PromoPathHeader = ({
  items,
  ...params
}: TableHeaderParams<PromoTableDefinition, ManagerPathItem>): JSX.Element => {
  return <TableHeader {...params} counter={CounterText(items || [])} actionSet={ActionSet} />;
};

export const getHeaderComponent = () => PromoPathHeader;
