/* eslint-disable no-underscore-dangle */
import { useCallback, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  PeerReview as PeerReviewModel,
  ListPeerReviewsByReviewerQuery,
  ListPeerReviewsByWorkSummaryQueryVariables,
  GetPeerReviewQueryVariables,
  GetPeerReviewQuery,
  CreatePeerReviewInput,
  UpdatePeerReviewInput,
  CreatePeerReviewMutation,
  UpdatePeerReviewMutation,
  PeerReviewStatus,
  DeletePeerReviewInput,
  DeletePeerReviewMutation,
  FileRecord as FileRecordModel,
  Category,
  ListPeerReviewsByWorkSummaryQuery,
  ListPeerReviewsByReviewerQueryVariables,
  ModelStringKeyConditionInput,
  PeerReviewType,
} from './API';
import {
  getPeerReview as getPeerReviewQuery,
  listPeerReviewsByReviewer,
  listPeerReviewsByWorkSummary,
} from '../graphql/queries';
import {
  OptionalNumber,
  OptionalString,
  PeerReviewBase,
  PeerReviewResource,
  PeerReviewRequest,
  PeerReviewItem,
  Nullable,
} from '@/models';
import { getConcreteModels, useApiMutation, useApiQuery } from '@/backend/api';
import { getCompactId, getDateFromAPIValueUTC } from '@/common/utils';
import {
  createPeerReview as createPeerReviewMutation,
  updatePeerReview as updatePeerReviewMutation,
  deletePeerReview as deletePeerReviewMutation,
} from '../graphql/mutations';
import { FEDERATED_USER_PREFIX, RESOURCES } from '@/common/constants';
import { getFileRecordItemFromModel } from './file-record';
import { getCategoryItemFromModel } from './category';
import { QueryKeys } from './queryKeys';
import {
  PeerReviewActions,
  UsePeerReviewActionsResult,
  UsePeerReviewResult,
  UsePeerReviewsResult,
  UseWorkSummaryPeerReviewsResult,
} from './models';
import { useNotifications } from '@/contexts';
import { useAmazonUserMap } from './amzn-people';

const ITEM_TYPE = RESOURCES.PEER_REVIEW.resourceName;

type LinkedFileModel = NonNullable<
  NonNullable<NonNullable<PeerReviewModel['workSummary']>['documents']>
>['items'][number];

type ResourceModal =
  | PeerReviewModel
  | NonNullable<CreatePeerReviewMutation['createPeerReview']>
  | NonNullable<UpdatePeerReviewMutation['updatePeerReview']>;

type RequestModel = NonNullable<
  NonNullable<NonNullable<ListPeerReviewsByWorkSummaryQuery['getWorkSummary']>['peerReviews']>['items'][number]
>;

type ItemModel = NonNullable<NonNullable<ListPeerReviewsByReviewerQuery['listPeerReviewsByReviewer']>['items'][number]>;

type Model = ResourceModal | ItemModel | RequestModel;

function isValidRequest(model: Nullable<RequestModel>): boolean {
  return !!model && !model._deleted && model.reviewStatus !== PeerReviewStatus.DELETED;
}

function isValidItem(model: Nullable<ItemModel>): boolean {
  return isValidRequest(model) && !!model?.workSummary;
}

function isValidResource(model: Nullable<ResourceModal>): boolean {
  return isValidItem(model) && (!!model?.candidatePath || !model?.workSummary?.categories?.items?.length);
}

function getBaseFromModel(model: Model): PeerReviewBase {
  return {
    id: model.id,
    content: model.content ?? '',
    reviewerAlias: model.reviewerAlias,
    createdAt: getDateFromAPIValueUTC(model.createdAt),
    reviewStatus: model.reviewStatus || PeerReviewStatus.PENDING,
    updatedAt: getDateFromAPIValueUTC(model.updatedAt),
    reviewType: model.reviewType,
    requiredBy: getDateFromAPIValueUTC(model.requiredBy),
    version: model._version ?? 1,
  };
}

function getPeerReviewRequestFromModel(model: ItemModel): PeerReviewRequest {
  const base = getBaseFromModel(model);
  return {
    userProfileExists: true,
    isVerified: true,
    requestId: base.id,
    requestStatus: base.reviewStatus,
    alias: base.reviewerAlias,
    reviewType: base.reviewType,
    version: base.version,
  };
}

function getItemFromModel(model: ItemModel, workSummaryContentVersion?: OptionalNumber): PeerReviewItem {
  return {
    ...getBaseFromModel(model),
    workSummaryId: model.workSummary?.id || '',
    workSummaryTitle: model.workSummary?.title,
    candidateAlias: model.workSummary?.alias || model.candidatePath?.candidate || '',
    candidateManagerAlias: model.candidatePath?.manager ?? '',
    commentCount: model.workSummary?.comments?.items.length ?? 0,
    reviewerName: model.reviewer.name,
    reviewedVersion: model.reviewedContentVersion,
    latestVersion: workSummaryContentVersion,
  };
}

function getResourceFromModel(model: ResourceModal): PeerReviewResource {
  return {
    ...getBaseFromModel(model),
    reviewerName: model.reviewer.name,
    reviewedVersion: model.reviewedContentVersion,
    workSummaryId: model.workSummary?.id ?? '',
    workSummaryDetail: model.workSummary?.content ?? '',
    latestVersion: model.workSummary?.contentVersion ?? 1,
    workSummaryTitle: model.workSummary?.title,
    workSummaryType: model.workSummary?.type,
    workSummaryCategories: (model.workSummary?.categories?.items || [])
      .filter((category): category is Category => !!category)
      .map((category) => getCategoryItemFromModel(category))
      .sort((a, b) => (a.label > b.label ? 1 : -1)),
    candidateAlias: model.workSummary?.alias ?? model.candidatePath?.candidate ?? '',
    candidatePromoPathTargetLevel: model.candidatePath?.targetLevel ?? model.candidatePath?.template?.targetLevel ?? 5,
    candidateManagerAlias: model.candidatePath?.manager ?? '',
    candidatePromoPathName: model.candidatePath?.name ?? model.candidatePath?.template?.name ?? '',
    commentCount: model.workSummary?.comments?.items.length ?? 0,
    documents: (model.workSummary?.documents?.items ?? [])
      .filter((doc): doc is LinkedFileModel => !!doc?.fileRecord && !doc._deleted && !doc.fileRecord._deleted)
      .map((doc) => ({
        ...getFileRecordItemFromModel(doc?.fileRecord as FileRecordModel),
        workSummaryFileId: doc?.id,
      }))
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
  };
}

export function usePeerReview(id: OptionalString): UsePeerReviewResult {
  const { getItem } = useApiQuery();
  const queryClient = useQueryClient();

  const getQueryParams = useCallback(
    (peerReviewId: OptionalString) => ({
      meta: { errorMessage: `Error fetching peer review: ${getCompactId(peerReviewId)}` },
      queryKey: QueryKeys.peerReview.id(peerReviewId),
      queryFn: async () => {
        if (!peerReviewId) return null;
        const data = await getItem<GetPeerReviewQueryVariables, GetPeerReviewQuery>({
          query: getPeerReviewQuery,
          input: { id: peerReviewId },
        });
        const models = getConcreteModels<ResourceModal>(data?.getPeerReview, isValidResource);
        return models?.length ? getResourceFromModel(models[0]) : null;
      },
    }),
    [getItem]
  );

  const query = useQuery({
    ...getQueryParams(id),
    enabled: !!id,
  });

  const getPeerReview = useCallback(
    async (peerReviewId: OptionalString) => queryClient.fetchQuery({ ...getQueryParams(peerReviewId) }),
    [queryClient, getQueryParams]
  );

  return {
    getPeerReview,
    peerReview: query.data,
    isPeerReviewLoading: !!id && query.isLoading,
  };
}

export function useWorkSummaryPeerReviews(
  workSummaryId: OptionalString,
  reviewType: PeerReviewType
): UseWorkSummaryPeerReviewsResult {
  const { getItems } = useApiQuery();

  const query = useQuery({
    meta: { errorMessage: `Error fetching peer reviews for work summary : ${getCompactId(workSummaryId)}` },
    queryKey: QueryKeys.workSummary.id(workSummaryId).peerReviews.type(reviewType),
    queryFn: async () => {
      if (!workSummaryId) return undefined;
      const data = await getItems<ListPeerReviewsByWorkSummaryQueryVariables, ListPeerReviewsByWorkSummaryQuery>({
        query: listPeerReviewsByWorkSummary,
        input: { workSummaryId, reviewType: { eq: reviewType } },
      });
      return {
        models: getConcreteModels<RequestModel>(data?.getWorkSummary?.peerReviews?.items, isValidRequest) ?? [],
        contentVersion: data?.getWorkSummary?.contentVersion ?? 1,
      };
    },
    enabled: !!workSummaryId,
  });

  const peerReviews = useMemo(
    () => query.data?.models?.map((item) => getItemFromModel(item, query.data?.contentVersion)) ?? [],
    [query.data?.contentVersion, query.data?.models]
  );

  const peerReviewRequests = useMemo(
    () => query.data?.models?.map((item) => getPeerReviewRequestFromModel(item)) ?? [],
    [query.data?.models]
  );

  return {
    peerReviews,
    peerReviewRequests,
    isPeerReviewsLoading: !!workSummaryId && query.isLoading,
  };
}

export function usePeerReviews(alias: OptionalString, statuses?: PeerReviewStatus[]): UsePeerReviewsResult {
  const { getItems } = useApiQuery();

  const query = useQuery({
    meta: { errorMessage: `Error fetching peer reviews for: ${getCompactId(alias)}@` },
    queryKey: QueryKeys.peerReview.alias(alias).status(statuses),
    queryFn: async () => {
      if (!alias) return [];
      let reviewStatus: ModelStringKeyConditionInput | undefined;
      if (statuses?.length === 1) {
        reviewStatus = { eq: statuses[0] };
      }
      if (statuses?.length && statuses.length > 1) {
        reviewStatus = { between: [statuses.sort()[0], statuses.sort()[1]] };
      }
      const data = await getItems<ListPeerReviewsByReviewerQueryVariables, ListPeerReviewsByReviewerQuery>({
        query: listPeerReviewsByReviewer,
        input: { reviewerAlias: alias, reviewStatus },
      });
      const models = getConcreteModels<ItemModel>(data?.listPeerReviewsByReviewer?.items, isValidItem);
      return models?.map((item) => getItemFromModel(item)) ?? [];
    },
    enabled: !!alias,
  });

  return {
    peerReviews: query.data ?? [],
    isPeerReviewsLoading: !!alias && query.isLoading,
  };
}

export function useHydratedPeerReviewRequests(workSummaryId: OptionalString, reviewType: PeerReviewType) {
  const { peerReviewRequests, isPeerReviewsLoading } = useWorkSummaryPeerReviews(workSummaryId, reviewType);

  const aliases = useMemo(
    () => peerReviewRequests.map((req) => req.alias).filter((alias): alias is string => !!alias),
    [peerReviewRequests]
  );
  const { aliasUserMap, isAliasUserMapLoading } = useAmazonUserMap(aliases);

  const requests = useMemo(
    () =>
      peerReviewRequests.map((req) => ({
        ...req,
        email: req.alias ? aliasUserMap.get(req.alias)?.email : '',
        name: req.alias ? `${aliasUserMap.get(req.alias)?.firstName} ${aliasUserMap.get(req.alias)?.lastName}` : '',
      })),
    [peerReviewRequests, aliasUserMap]
  );

  return {
    peerReviewRequests: requests,
    isRequestsLoading: isAliasUserMapLoading || isPeerReviewsLoading,
  };
}

export function usePeerReviewActions(id?: OptionalString): UsePeerReviewActionsResult {
  const { createItem, updateItem, deleteItem } = useApiMutation();
  const { getPeerReview } = usePeerReview(id);
  const { addNotification } = useNotifications();
  const queryClient = useQueryClient();

  type CreateParams = Parameters<PeerReviewActions['create']>[0];
  type UpdateParams = Parameters<PeerReviewActions['update']>[0];
  type DeleteParams = Parameters<PeerReviewActions['delete']>[0];

  const invalidateQueries = useCallback(async () => {
    const invalidations = [queryClient.invalidateQueries(QueryKeys.peerReview.all)];
    await Promise.allSettled(invalidations);
  }, [queryClient]);

  const createMutation = useMutation({
    mutationFn: async (params: CreateParams) => {
      const coOwners = [`${FEDERATED_USER_PREFIX}${params.reviewerAlias}`];
      const input: CreatePeerReviewInput = { ...params, coOwners };
      const data = await createItem<CreatePeerReviewMutation>(createPeerReviewMutation, input, ITEM_TYPE);
      return data?.createPeerReview ? getResourceFromModel(data?.createPeerReview as PeerReviewModel) : null;
    },
    onSuccess: (result) => {
      addNotification({ type: 'success', header: `Successfully created peer review: ${getCompactId(result?.id)}` });
      invalidateQueries();
    },
  });

  const updateMutation = useMutation({
    mutationFn: async (params: UpdateParams) => {
      const peerReview = await getPeerReview(params.id ?? id);
      if (!peerReview) return null;
      const input: UpdatePeerReviewInput = { ...params, id: peerReview.id, _version: peerReview.version };
      const data = await updateItem<UpdatePeerReviewMutation>(updatePeerReviewMutation, input, ITEM_TYPE);
      return data?.updatePeerReview ? getResourceFromModel(data?.updatePeerReview as PeerReviewModel) : null;
    },
    onSuccess: (result) => {
      addNotification({ type: 'success', header: `Successfully updated peer review: ${getCompactId(result?.id)}` });
      invalidateQueries();
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async (peerReviewId: DeleteParams) => {
      const peerReview = await getPeerReview(peerReviewId ?? id);
      if (!peerReview) return null;
      const updateInput: UpdatePeerReviewInput = {
        id: peerReview.id,
        reviewStatus: PeerReviewStatus.DELETED,
        _version: peerReview.version,
      };
      const updateResult = await updateItem<UpdatePeerReviewMutation>(updatePeerReviewMutation, updateInput, ITEM_TYPE);
      const input: DeletePeerReviewInput = { id: peerReview.id, _version: updateResult?.updatePeerReview?._version };
      const data = await deleteItem<DeletePeerReviewMutation>(deletePeerReviewMutation, input, ITEM_TYPE);
      return data?.deletePeerReview ?? null;
    },
    onSuccess: (result) => {
      addNotification({ type: 'success', header: `Successfully deleted peer review: ${getCompactId(result?.id)}` });
      invalidateQueries();
    },
  });

  const onCreate = useCallback(async (params: CreateParams) => createMutation.mutateAsync(params), [createMutation]);
  const onUpdate = useCallback(async (params: UpdateParams) => updateMutation.mutateAsync(params), [updateMutation]);
  const onDelete = useCallback(async (prId?: DeleteParams) => deleteMutation.mutateAsync(prId), [deleteMutation]);

  const actions = useMemo(
    () => ({ create: onCreate, update: onUpdate, delete: onDelete }),
    [onCreate, onUpdate, onDelete]
  );

  return {
    actions,
    isMutating: createMutation.isLoading || updateMutation.isLoading || deleteMutation.isLoading,
  };
}
