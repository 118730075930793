/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';
import { API, graphqlOperation, Hub, Logger } from 'aws-amplify';
import { APPSYNC_MAX_ITEMS, AUTH_OVERRIDE_HEADER } from '@/common/constants';
import { useAppContext } from '@/contexts';
import { Nullable, OptionalNumber, OptionalString } from '@/models';

const logger = new Logger('API');

interface GraphQLResult<T> {
  data?: T;
  errors?: { message: string }[];
}

interface ItemInputParams {
  [k: string]: any;
}

interface DeleteItemParams {
  id: string;
  [k: string]: string | number | null;
}

export type AbstractModel<T> = T | null | undefined;

export function getConcreteModels<T extends Record<string, any>>(
  value: AbstractModel<T> | Array<AbstractModel<T>>,
  isValidModel: (v: AbstractModel<T>) => boolean
): NonNullable<T>[] | undefined {
  let models: AbstractModel<T>[];
  if (!value) {
    return undefined;
  }
  if (!Array.isArray(value)) {
    models = [value];
  } else {
    models = value;
  }
  return models.filter((model): model is T => isValidModel(model));
}

export function getErrorMessageFromException(ex: unknown): string {
  let message: string;
  if (!ex) {
    message = '';
  } else if (typeof ex === 'object' && 'errors' in ex && Array.isArray(ex.errors) && ex.errors.length) {
    message = ex.errors[0]?.message ?? '';
  } else {
    message = String(ex);
  }
  return message;
}

interface GraphQLOperationParams {
  query: string;
  input: Record<string, any>;
  errorMessage?: string;
  runAsAlias?: string;
}

async function runGraphqlOperation<T>(params: GraphQLOperationParams): Promise<T | undefined> {
  const { query, input, errorMessage, runAsAlias } = params;
  const headers = runAsAlias ? { [AUTH_OVERRIDE_HEADER]: runAsAlias } : undefined;
  let response: GraphQLResult<T> | undefined;
  try {
    response = (await API.graphql(graphqlOperation(query, input), headers)) as GraphQLResult<T>;
  } catch (ex) {
    logger.error('Error with graphql API call.', ex);
    if (errorMessage) {
      Hub.dispatch('PromoNotification', {
        event: 'error',
        message: `Failed to ${errorMessage}.`,
        data: getErrorMessageFromException(ex),
      });
    } else {
      throw ex;
    }
  }
  return response?.errors?.length ? undefined : response?.data;
}

interface GetItemParams<InputType> {
  query: string;
  input: InputType;
}

interface GetItemsInputBase {
  limit?: OptionalNumber;
  nextToken?: OptionalString;
}

interface GetItemsParams<InputType extends GetItemsInputBase> {
  query: string;
  input: InputType;
}

export function useApiMutation() {
  const { spoofUser } = useAppContext();

  const createItem = useCallback(
    async <T>(query: string, input: ItemInputParams, itemType?: string): Promise<Nullable<T>> => {
      const errorMessage = `create ${itemType || 'item'}`;
      return runGraphqlOperation({ query, input: { input }, errorMessage, runAsAlias: spoofUser?.alias });
    },
    [spoofUser]
  );

  const updateItem = useCallback(
    async <T>(query: string, input: ItemInputParams, itemType?: string): Promise<Nullable<T>> => {
      const errorMessage = `update ${itemType || 'item'}`;
      return runGraphqlOperation({ query, input: { input }, errorMessage, runAsAlias: spoofUser?.alias });
    },
    [spoofUser]
  );

  const deleteItem = useCallback(
    async <T>(query: string, input: DeleteItemParams, itemType?: string): Promise<Nullable<T>> => {
      const errorMessage = `delete ${itemType || 'item'}`;
      return runGraphqlOperation({ query, input: { input }, errorMessage, runAsAlias: spoofUser?.alias });
    },
    [spoofUser]
  );

  return { createItem, deleteItem, updateItem };
}

export function useApiQuery() {
  const { spoofUser } = useAppContext();

  const getItem = useCallback(
    async <InputType extends Record<string, any>, ReturnType>(
      params: GetItemParams<InputType>
    ): Promise<Nullable<ReturnType>> => {
      return runGraphqlOperation({ ...params, runAsAlias: spoofUser?.alias });
    },
    [spoofUser]
  );

  const getItems = useCallback(
    async <InputType extends GetItemsInputBase, ReturnType>(
      params: GetItemsParams<InputType>
    ): Promise<Nullable<ReturnType>> => {
      const { query, input } = params;

      const requestParams: InputType = {
        ...input,
        limit: input.limit ?? APPSYNC_MAX_ITEMS,
        nextToken: input.nextToken || null,
      };
      return runGraphqlOperation({ query, input: requestParams, runAsAlias: spoofUser?.alias });
    },
    [spoofUser]
  );

  const getExternalItems = useCallback(
    async <InputType extends Record<string, string>, ReturnType>(
      params: GetItemsParams<InputType>
    ): Promise<Nullable<ReturnType>> => {
      return runGraphqlOperation(params);
    },
    []
  );

  return { getItem, getItems, getExternalItems };
}
