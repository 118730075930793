/* eslint-disable no-underscore-dangle */
import { useCallback, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  ListPromoPathsByCandidateQuery,
  ListPromoPathsByCandidateQueryVariables,
  ListCandidatePathsByManagerQuery,
  ListCandidatePathsByManagerQueryVariables,
  Category as CategoryModel,
  PromoPath as PromoPathModel,
  WorkSummary as WorkSummaryModel,
  GetCandidatePromoPathQuery,
  GetCandidatePromoPathQueryVariables,
  CreatePromoPathInput,
  CreateCandidatePathMutation,
  UpdatePromoPathInput,
  UpdateCandidatePathMutation,
  PathDefinitionType,
  PromoPathStatus,
  ListWorkSummariesByCandidatePathQueryVariables,
  WorkSummaryStatus,
  ListWorkSummariesByCandidatePathQuery,
  WorkSummaryStatusReason,
  UpdateWorkSummaryMutation,
  UpdateWorkSummaryInput,
  DeleteCandidatePathMutation,
  DeletePromoPathInput,
} from './API';
import {
  listCandidatePathsByCandidate,
  listCandidatePathsByManager,
  getCandidatePromoPath as getCandidatePromoPathQuery,
  listWorkSummariesByCandidatePath,
} from '../graphql/queries';
import {
  createCandidatePath as createCandidatePathMutation,
  updateCandidatePath as updateCandidatePathMutation,
  deleteCandidatePath as deleteCandidatePathMutation,
  updateWorkSummary as updateWorkSummaryMutation,
} from '../graphql/mutations';
import { CandidatePathItem, CandidatePathResource, OptionalString, WorkSummaryItem } from '@/common/models';
import { useApiMutation, useApiQuery } from '@/backend/api';
import { getCategoryResourceFromModel } from './category';
import { getWorkSummaryItemFromModel } from './work-summary';
import { QueryKeys } from './queryKeys';
import { getCompactId, getDateFromAPIValueUTC } from '@/common/utils';
import {
  CandidatePathActions,
  UseCandidatePathActionsResult,
  UseCandidatePathParams,
  UseCandidatePathResult,
  UseCandidatePromoPathListResult,
  UseCandidateWorkSummariesResult,
} from './models';
import { useNotifications } from '@/contexts';
import { FEDERATED_USER_PREFIX } from '@/common/constants';

const ITEM_TYPE = 'candidate path';

type CandidatePathRequired = Required<Pick<PromoPathModel, 'candidate' | 'manager' | 'targetDate' | 'totalRequired'>>;
type CandidatePromoPathModel = PromoPathModel & {
  [Property in keyof CandidatePathRequired]: Exclude<CandidatePathRequired[Property], null>;
};

function calcPctComplete(model: CandidatePromoPathModel) {
  const totalRequired = model.totalRequired ?? model.template?.totalRequired ?? 0;
  if (!model.totalCompleted || !totalRequired) {
    return typeof model.totalCompleted === 'number' && typeof model.totalRequired === 'number' ? 0 : undefined;
  }
  return (model.totalCompleted / totalRequired) * 100;
}

function pathAllowsAttachments(promoPath: CandidatePromoPathModel) {
  if (promoPath.allowAttachments !== undefined) {
    return promoPath.allowAttachments as boolean;
  }
  if (promoPath.template?.allowAttachments !== undefined) {
    return promoPath.template.allowAttachments as boolean;
  }
  return true;
}

function coalesceCategories(promoPath: CandidatePromoPathModel): CategoryModel[] {
  const pathCategories = (promoPath.categories?.items || []).filter((item) => item !== null) as CategoryModel[];
  const pathCategoryNames = pathCategories
    .filter((category) => category !== null)
    .map((category) => (category as CategoryModel).name);
  const templateCategories = (promoPath.template?.categories?.items || []).filter(
    (category) => category !== null && !pathCategoryNames.includes(category.name)
  ) as CategoryModel[];
  return [...pathCategories, ...templateCategories];
}

function getItemFromModel(model: CandidatePromoPathModel): CandidatePathItem {
  return {
    id: model.id,
    createdAt: getDateFromAPIValueUTC(model.createdAt),
    updatedAt: getDateFromAPIValueUTC(model.updatedAt),
    candidate: model.candidate,
    manager: model.manager,
    totalCompleted: model.totalCompleted,
    totalRequired: model.totalRequired,
    progressPct: calcPctComplete(model),
    name: model.name ?? model.template?.name ?? '-',
    startLevel: model.startLevel ?? model.template?.startLevel ?? 4,
    targetLevel: model.targetLevel ?? model.template?.targetLevel ?? 5,
    allowsAttachments: pathAllowsAttachments(model),
    targetQuarter: model.targetDate,
    suggestedLPs: model.suggestedLPs?.length ? model.suggestedLPs : model.template?.suggestedLPs ?? [],
    role: model.role ?? model.template?.role ?? '',
    version: model._version ?? 1,
    status: model.status,
    type: PathDefinitionType.CANDIDATE,
  };
}

function getResourceFromModel(model: CandidatePromoPathModel): CandidatePathResource {
  return {
    ...getItemFromModel(model),
    categories: coalesceCategories(model)
      .map((item) => getCategoryResourceFromModel(item))
      .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)),
  };
}

function parseWorkSummariesFromPathModel(model: CandidatePromoPathModel): WorkSummaryItem[] {
  return (model?.workSummaries?.items ?? [])
    .filter((itemModel): itemModel is WorkSummaryModel => !!itemModel)
    .map((itemModel) => getWorkSummaryItemFromModel(itemModel));
}

export function useCandidatePromoPathList(manager: OptionalString): UseCandidatePromoPathListResult {
  const { getItems } = useApiQuery();
  const query = useQuery({
    queryKey: QueryKeys.candidatePath.manager(manager),
    queryFn: async () => {
      if (!manager) return [];
      const data = await getItems<ListCandidatePathsByManagerQueryVariables, ListCandidatePathsByManagerQuery>({
        query: listCandidatePathsByManager,
        input: { manager },
      });
      const items = data?.listPromoPathsByManagerAndType?.items.filter(
        (item): item is CandidatePromoPathModel => !!item
      );
      return (items ?? []).map((item) => getItemFromModel(item));
    },
    enabled: !!manager,
  });

  const candidateToPath = useMemo(
    () => new Map((query.data ?? []).map((item) => [item.candidate, item])),
    [query.data]
  );

  return {
    candidateToPath,
    candidatePaths: query.data ?? [],
    isCandidatePathsLoading: !!manager && query.isLoading,
  };
}

export function useCandidateWorkSummaries(alias: OptionalString): UseCandidateWorkSummariesResult {
  const { getItems } = useApiQuery();
  const queryClient = useQueryClient();
  const queryKey = QueryKeys.candidatePath.manager(alias);

  const query = useQuery({
    queryKey,
    meta: { errorMessage: `Error fetching candidate work summaries for ${alias}@` },
    queryFn: async () => {
      if (!alias) return [];
      const data = await getItems<ListCandidatePathsByManagerQueryVariables, ListCandidatePathsByManagerQuery>({
        query: listCandidatePathsByManager,
        input: { manager: alias },
      });
      return (data?.listPromoPathsByManagerAndType?.items || [])
        .filter((item): item is CandidatePromoPathModel => item !== null)
        .map((model) => parseWorkSummariesFromPathModel(model))
        .flat(2);
    },
    enabled: !!alias,
  });

  const invalidateResults = useCallback(() => queryClient.invalidateQueries(queryKey), [queryClient, queryKey]);

  return {
    workSummaries: query.data ?? [],
    isWorkSummariesLoading: !!alias && query.isLoading,
    refresh: invalidateResults,
  };
}

export function useCandidatePath({ id, alias }: UseCandidatePathParams): UseCandidatePathResult {
  const { getItems, getItem } = useApiQuery();
  const queryClient = useQueryClient();

  const getQueryParams = useCallback(
    (candidatePathId: OptionalString) => ({
      meta: { errorMessage: `Error fetching candidate promo path: ${getCompactId(candidatePathId)}` },
      queryKey: QueryKeys.candidatePath.id(candidatePathId).all,
      queryFn: async () => {
        if (!candidatePathId) return null;
        const result = await getItem<GetCandidatePromoPathQueryVariables, GetCandidatePromoPathQuery>({
          query: getCandidatePromoPathQuery,
          input: { id: candidatePathId },
        });
        return result?.getPromoPath ? getResourceFromModel(result?.getPromoPath as CandidatePromoPathModel) : null;
      },
    }),
    [getItem]
  );

  const queryById = useQuery({
    ...getQueryParams(id),
    enabled: !!id,
  });

  const queryByAlias = useQuery({
    meta: { errorMessage: `Error fetching promo path for candidate: ${alias}@` },
    queryKey: QueryKeys.candidatePath.alias(alias),
    queryFn: async () => {
      if (!alias) return null;
      const result = await getItems<ListPromoPathsByCandidateQueryVariables, ListPromoPathsByCandidateQuery>({
        query: listCandidatePathsByCandidate,
        input: { candidate: alias, status: { eq: PromoPathStatus.ASSIGNED } },
      });
      const item = result?.listPromoPathsByCandidate?.items?.[0];
      return item ? getResourceFromModel(item as CandidatePromoPathModel) : null;
    },
    enabled: !!alias && !id,
  });

  const candidatePath = useMemo(() => {
    if (id && queryById.data) {
      return queryById.data;
    }
    if (alias && queryByAlias.data) {
      return queryByAlias.data;
    }
    return undefined;
  }, [queryByAlias.data, queryById.data, id, alias]);

  const isCandidatePathLoading = useMemo(
    () => (!!id && queryById.isLoading) || (!!alias && queryByAlias.isLoading),
    [id, alias, queryByAlias.isLoading, queryById.isLoading]
  );

  const onFetchWorkSummaries = useCallback(
    async () =>
      queryClient.fetchQuery({
        meta: { errorMessage: 'Failed to fetch work summaries for candidate path' },
        queryKey: QueryKeys.candidatePath.id(id).workSummaries,
        queryFn: async () => {
          if (!id) return [];
          const data = await getItems<
            ListWorkSummariesByCandidatePathQueryVariables,
            ListWorkSummariesByCandidatePathQuery
          >({
            query: listWorkSummariesByCandidatePath,
            input: {
              candidatePathId: id,
              filter: { status: { ne: WorkSummaryStatus.DELETED } },
            },
          });
          return (data?.listWorkSummariesByCandidatePath?.items ?? []).filter((x): x is WorkSummaryModel => !!x);
        },
      }),
    [getItems, id, queryClient]
  );

  const getCandidatePath = useCallback(
    async (candidatePathId: OptionalString) => queryClient.fetchQuery({ ...getQueryParams(candidatePathId) }),
    [getQueryParams, queryClient]
  );

  const onFetchLatest = useCallback(
    async () => (id ? await queryById.refetch() : await queryByAlias.refetch()).data,
    [id, queryById, queryByAlias]
  );

  return {
    candidatePath,
    getCandidatePath,
    isCandidatePathLoading,
    fetchLatest: onFetchLatest,
    fetchWorkSummaries: onFetchWorkSummaries,
  };
}

export function useCandidatePathActions(id?: OptionalString): UseCandidatePathActionsResult {
  const { createItem, updateItem, deleteItem } = useApiMutation();
  const { getCandidatePath, fetchWorkSummaries, fetchLatest } = useCandidatePath({ id });
  const { addNotification } = useNotifications();
  const queryClient = useQueryClient();

  type CreateParams = Parameters<CandidatePathActions['create']>[0];
  type UpdateParams = Parameters<CandidatePathActions['update']>[0];

  const invalidateQueries = useCallback(
    async (pathId: OptionalString, candidate: OptionalString, manager: OptionalString) => {
      const invalidations = [
        queryClient.invalidateQueries(QueryKeys.candidatePath.id(pathId).all),
        queryClient.invalidateQueries(QueryKeys.candidatePath.alias(candidate)),
        queryClient.invalidateQueries(QueryKeys.candidatePath.manager(manager)),
      ];
      await Promise.allSettled(invalidations);
    },
    [queryClient]
  );

  const createMutation = useMutation({
    mutationFn: async (params: CreateParams) => {
      const input: CreatePromoPathInput = {
        ...params,
        pathType: PathDefinitionType.CANDIDATE,
        status: PromoPathStatus.ASSIGNED,
      };
      const data = await createItem<CreateCandidatePathMutation>(createCandidatePathMutation, input, ITEM_TYPE);
      return data?.createPromoPath ? getResourceFromModel(data?.createPromoPath as CandidatePromoPathModel) : null;
    },
    onSuccess: (result) => {
      addNotification({
        type: 'success',
        header: `Successfully created candidate path: ${result?.name} - ${result?.candidate}@`,
      });
      invalidateQueries(result?.id, result?.candidate, result?.manager);
    },
  });

  const updateMutation = useMutation({
    mutationFn: async (params: UpdateParams) => {
      const candidatePath = await getCandidatePath(params.id ?? id);
      if (!candidatePath) return null;
      let owner: OptionalString;
      if (params.manager && params.manager !== candidatePath.manager) {
        owner = `${FEDERATED_USER_PREFIX}${params.manager}`;
      }
      const input: UpdatePromoPathInput = {
        ...params,
        owner,
        id: candidatePath.id,
        pathType: params.status && !params.pathType ? PathDefinitionType.CANDIDATE : params.pathType,
        _version: candidatePath.version,
      };
      const data = await updateItem<UpdateCandidatePathMutation>(updateCandidatePathMutation, input, ITEM_TYPE);
      return data?.updatePromoPath ? getResourceFromModel(data?.updatePromoPath as CandidatePromoPathModel) : null;
    },
    onSuccess: (result) => {
      addNotification({
        type: 'success',
        header: `Successfully updated candidate path: ${result?.name} - ${result?.candidate}@`,
      });
      invalidateQueries(result?.id, result?.candidate, result?.manager);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async () => {
      const candidatePath = await fetchLatest();
      if (!candidatePath) return null;
      const workSummaries = await fetchWorkSummaries();
      await Promise.all(
        workSummaries.map(async (workSummary) => {
          const input: UpdateWorkSummaryInput = {
            id: workSummary.id,
            candidatePathId: null,
            status: WorkSummaryStatus.DRAFT,
            statusReason: WorkSummaryStatusReason.PROMO_PATH_ASSIGNMENT_CHANGE,
            _version: workSummary._version,
          };
          await updateItem<UpdateWorkSummaryMutation>(updateWorkSummaryMutation, input);
        })
      );

      const updateParams: UpdatePromoPathInput = {
        id: candidatePath.id,
        pathType: PathDefinitionType.CANDIDATE,
        status: PromoPathStatus.DELETED,
        _version: candidatePath.version,
      };
      const updateResult = await updateItem<UpdateCandidatePathMutation>(
        updateCandidatePathMutation,
        updateParams,
        ITEM_TYPE
      );
      const input: DeletePromoPathInput = { id: candidatePath.id, _version: updateResult?.updatePromoPath?._version };
      const data = await deleteItem<DeleteCandidatePathMutation>(deleteCandidatePathMutation, input, ITEM_TYPE);
      return data?.deletePromoPath ?? null;
    },
    onSuccess: (result) => {
      addNotification({
        type: 'success',
        header: `Successfully deleted candidate path: ${result?.name ?? result?.template?.name} - ${result?.candidate}@`,
      });
      invalidateQueries(result?.id, result?.candidate, result?.manager);
    },
  });

  const onCreate = useCallback(async (params: CreateParams) => createMutation.mutateAsync(params), [createMutation]);
  const onUpdate = useCallback(async (params: UpdateParams) => updateMutation.mutateAsync(params), [updateMutation]);
  const onDelete = useCallback(async () => deleteMutation.mutateAsync(), [deleteMutation]);

  const actions = useMemo(
    () => ({ create: onCreate, update: onUpdate, delete: onDelete }),
    [onCreate, onUpdate, onDelete]
  );

  return {
    actions,
    isMutating: createMutation.isLoading || updateMutation.isLoading || deleteMutation.isLoading,
  };
}
