import {
  PropertyFilterOperatorExtended,
  PropertyFilterProperty,
} from '@amzn/awsui-collection-hooks/dist/mjs/interfaces';
import Calendar from '@amzn/awsui-components-react/polaris/calendar';
import DateInput from '@amzn/awsui-components-react/polaris/date-input';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import PropertyFilter from '@amzn/awsui-components-react/polaris/property-filter';
import { getFilterLabels, getGroupValueLabel } from '@/common/labels';
import { Item, FilterParams, PromoTableDefinition } from '@/models';

/**
 * Helper that creates a `string` `PropertyFilterProperty` for use in table/card collections filters on a string field.
 * Sets standard string comparison operators: `=, !=, :, !:`
 * See usage guide for `ComparisonOperators` {@link https://refresh.polaris.a2z.com/components/property-filter/?tabId=usage here}
 * @param key The key of the item object which contains the values for filtering. Should match `id` from ColumnDefinition
 * @param label Display label for the property. Should match the `title` from the ColumnDefinition
 */
export const StringPropertyFilter = (
  key: string | undefined,
  label: string | undefined
): PropertyFilterProperty<string> | undefined => {
  if (!key || !label) {
    return undefined;
  }
  return {
    key,
    propertyLabel: label,
    groupValuesLabel: getGroupValueLabel(label),
    operators: ['=', '!=', ':', '!:'],
    defaultOperator: ':',
  };
};

/**
 * Helper that creates a `number` `PropertyFilterProperty` for use in table/card collections filters on a numeric field.
 * Sets standard number comparison operators: `=, !=, >=, <=, <, >`
 * See usage guide for `ComparisonOperators` {@link https://refresh.polaris.a2z.com/components/property-filter/?tabId=usage here}
 * @param key The key of the item object which contains the values for filtering. Should match `id` from ColumnDefinition
 * @param label Display label for the property. Should match the `title` from the ColumnDefinition
 * @param formatter (optional) function to use when rendering the numeric values for display.
 */
export const NumericPropertyFilter = (
  key: string | undefined,
  label: string | undefined,
  formatter?: (v: number) => string
): PropertyFilterProperty<number> | undefined => {
  if (!key || !label) {
    return undefined;
  }
  // There's 2 types of operators, the simple ones and an extended on which takes extra properties (i.e. formatter)
  let operators: PropertyFilterProperty<number>['operators'] = ['=', '!=', '>=', '<=', '<', '>'];
  if (formatter) {
    // The formatter should be operator agnostic, so simply apply it to all
    operators = operators.map((op) => ({ operator: op, format: formatter } as PropertyFilterOperatorExtended<number>));
  }

  return {
    key,
    operators,
    propertyLabel: label,
    groupValuesLabel: getGroupValueLabel(label),
    defaultOperator: '=',
  };
};

interface DateControlParams {
  value: string | null | undefined;
  onChange: (v: string | null | undefined) => void;
}

const DateControl = ({ value, onChange }: DateControlParams) => (
  <div className="date-form">
    <FormField>
      <DateInput value={value || ''} onChange={(event) => onChange(event.detail.value)} placeholder="YYYY/MM/DD" />
    </FormField>
    <Calendar
      value={value || ''}
      onChange={(event) => onChange(event.detail.value)}
      todayAriaLabel="Today"
      nextMonthAriaLabel="Next month"
      previousMonthAriaLabel="Previous month"
    />
  </div>
);

const dateFormatter = new Intl.DateTimeFormat(window.navigator.language);

/**
 * Helper that creates a `date` `PropertyFilterProperty` for use in table/card collections filters on a date/time field.
 * Sets standard date comparison operators: `=, !=, >=, <=, <, >`
 * See usage guide for `ComparisonOperators` {@link https://refresh.polaris.a2z.com/components/property-filter/?tabId=usage here}
 * @param key The key of the item object which contains the values for filtering. Should match `id` from ColumnDefinition
 * @param label Display label for the property. Should match the `title` from the ColumnDefinition
 */
export const DatePropertyFilter = (
  key: string | undefined,
  label: string | undefined
): PropertyFilterProperty<string> | undefined => {
  if (!key || !label) {
    return undefined;
  }
  return {
    key,
    propertyLabel: label,
    groupValuesLabel: getGroupValueLabel(label),
    operators: ['>=', '<=', '<', '>'].map(
      (op) =>
        ({
          operator: op,
          // Special case where polaris `collection-hooks` has a buildin function to compare dates.
          // See ref: https://refresh.polaris.a2z.com/get-started/dev-guides/collection-hooks/#using-with-react
          form: DateControl,
          match: 'date',
          format: (value) => dateFormatter.format(new Date(value)),
        } as PropertyFilterOperatorExtended<string>)
    ),
    defaultOperator: '=',
  };
};

/**
 * A generic {@link https://refresh.polaris.a2z.com/components/property-filter/?tabId=api PropertyFilter}
 * that can be used on a table, or other UI component.
 * The UI characteristics / behavior are customized from the {@link PromoTableDefinition}
 * and {@link Item} def for this component.
 *
 * See also: {@link FilterParams}
 */
export const BasePropertyFilter = <TableDef extends PromoTableDefinition, ItemDef extends Item>({
  tableDef,
  filteredItemsCount,
  filterState,
}: FilterParams<TableDef, ItemDef>) => (
  <PropertyFilter expandToViewport {...getFilterLabels(tableDef.resourceName, filteredItemsCount)} {...filterState} />
);
