/* eslint-disable no-underscore-dangle */
import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  ListFilesByOwnerQuery,
  ListFilesByOwnerQueryVariables,
  CreateFileRecordInput,
  CreateFileRecordMutation,
  GetFileRecordQuery,
  GetFileRecordQueryVariables,
  DeleteFileRecordInput,
  DeleteFileRecordMutation,
  FileRecord as FileRecordModel,
  UpdateFileRecordInput,
  UpdateFileRecordMutation,
  FileRecordStatus,
  GetFileDownloadUrlQueryVariables,
  GetFileDownloadUrlQuery,
  FileRecordType,
  GetFileInfoQueryVariables,
  GetFileInfoQuery,
} from './API';
import {
  listFilesByOwner,
  getFileRecord as getFileRecordQuery,
  getFileInfo as getFileInfoQuery,
  getFileDownloadUrl,
} from '../graphql/queries';
import {
  createFileRecord as createFileRecordMutation,
  deleteFileRecord as deleteFileRecordMutation,
  updateFileRecord,
} from '../graphql/mutations';
import { FileRecordItem, FileRecordResource, OptionalString } from '@/models';
import { getConcreteModels, AbstractModel, useApiQuery, useApiMutation } from '@/backend/api';
import { FEDERATED_USER_PREFIX, RESOURCES } from '@/common/constants';
import { getCompactId, getDateFromAPIValueUTC } from '@/common/utils';
import { QueryKeys } from './queryKeys';
import { FileRecordActions, UseFileRecordActionsResult, UseFileRecordResult, UseFileRecordsResult } from './models';
import { getDownloadLink } from '@/backend/storage';
import { useAppContext } from '@/contexts';
import { useCognitoAuth } from '@/common/hooks/use-auth';

const ITEM_TYPE = RESOURCES.FILERECORD.resourceName;

type Model = FileRecordModel | NonNullable<GetFileRecordQuery['getFileRecord']>;

type WorkSummariesRelation = NonNullable<Model['workSummaries']>['items'][number];

function isValidModel(model: AbstractModel<Model>): boolean {
  return !!model && model.status !== FileRecordStatus.DELETED && !model._deleted;
}

function isValidRelatedModel(model: AbstractModel<WorkSummariesRelation>): boolean {
  return !!model && !model._deleted;
}

function getItemFromModel(model: Model): FileRecordItem {
  return {
    id: model.id,
    name: model.fileName,
    size: model.fileSizeBytes,
    uploadedAt: getDateFromAPIValueUTC(model.updatedAt),
    fileDateModified: getDateFromAPIValueUTC(model.fileDateModified),
    version: model._version ?? 1,
  };
}

function getResourceFromModel(model: Model): FileRecordResource {
  const summaries = getConcreteModels<NonNullable<WorkSummariesRelation>>(
    model.workSummaries?.items,
    isValidRelatedModel
  );
  return {
    ...getItemFromModel(model),
    s3Key: model.s3Key,
    storageIdKey: model.storageIdKey,
    ownerAlias: model.owner?.replace(FEDERATED_USER_PREFIX, '') ?? '',
    workSummaries: (summaries ?? []).map((item) => ({
      id: item.workSummaryID,
      title: item.workSummary.title,
    })),
  };
}

function useFileInfo() {
  const { getItem } = useApiQuery();
  const queryClient = useQueryClient();

  const getQueryParams = useCallback(
    (fileRecordId: OptionalString) => ({
      meta: { errorMessage: `Error loading file: ${getCompactId(fileRecordId)}` },
      queryKey: QueryKeys.fileRecord.info(fileRecordId),
      queryFn: async () => {
        if (!fileRecordId) return null;
        const data = await getItem<GetFileInfoQueryVariables, GetFileInfoQuery>({
          query: getFileInfoQuery,
          input: { id: fileRecordId },
        });
        const models = getConcreteModels<Model>(data?.getFileRecord, isValidModel);
        return models?.length ? getResourceFromModel(models[0]) : null;
      },
    }),
    [getItem]
  );

  const getFileInfo = useCallback(
    async (fileRecordId: OptionalString) => queryClient.fetchQuery({ ...getQueryParams(fileRecordId) }),
    [getQueryParams, queryClient]
  );

  return { getFileInfo };
}

export function useFileRecord(id?: OptionalString): UseFileRecordResult {
  const { getItem } = useApiQuery();
  const queryClient = useQueryClient();

  const getQueryParams = useCallback(
    (fileRecordId: OptionalString) => ({
      meta: { errorMessage: `Error loading file: ${getCompactId(fileRecordId)}` },
      queryKey: QueryKeys.fileRecord.id(fileRecordId),
      queryFn: async () => {
        if (!fileRecordId) return null;
        const data = await getItem<GetFileRecordQueryVariables, GetFileRecordQuery>({
          query: getFileRecordQuery,
          input: { id: fileRecordId },
        });
        const models = getConcreteModels<Model>(data?.getFileRecord, isValidModel);
        return models?.length ? getResourceFromModel(models[0]) : null;
      },
    }),
    [getItem]
  );

  const query = useQuery({
    ...getQueryParams(id),
    enabled: !!id,
  });

  const getFileRecord = useCallback(
    async (fileRecordId: OptionalString) => queryClient.fetchQuery({ ...getQueryParams(fileRecordId) }),
    [getQueryParams, queryClient]
  );

  const onFetchLatest = useCallback(async () => (await query.refetch()).data, [query]);

  return {
    getFileRecord,
    fetchLatest: onFetchLatest,
    fileRecord: query.data,
    isFileRecordLoading: !!id && query.isLoading,
  };
}

export function useFileRecords(alias: OptionalString): UseFileRecordsResult {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { getItems } = useApiQuery();

  const query = useQuery({
    meta: { errorMessage: `Error loading files for user: ${alias}` },
    queryKey: QueryKeys.fileRecord.alias(alias),
    queryFn: async () => {
      const data = await getItems<ListFilesByOwnerQueryVariables, ListFilesByOwnerQuery>({
        query: listFilesByOwner,
        input: { owner: `${FEDERATED_USER_PREFIX}${alias}`, status: { eq: FileRecordStatus.ACTIVE } },
      });
      const models = getConcreteModels<Model>(data?.listFilesByOwner?.items, isValidModel);
      return models?.map((item) => getItemFromModel(item)) ?? [];
    },
    enabled: !!alias,
  });

  const refresh = useCallback(async () => {
    setIsRefreshing(true);
    try {
      await query.refetch({ cancelRefetch: false });
    } finally {
      setIsRefreshing(false);
    }
  }, [query]);

  return {
    refresh,
    fileRecords: query.data ?? [],
    isFileRecordsLoading: !!alias && (query.isLoading || isRefreshing),
    isFileRecordsRefreshing: isRefreshing,
  };
}

export function useFileRecordActions(id?: OptionalString): UseFileRecordActionsResult {
  const { session } = useCognitoAuth();
  const [isPreparingDownload, setIsPreparingDownload] = useState(false);
  const { currentUser } = useAppContext();
  const { getFileRecord } = useFileRecord();
  const { getFileInfo } = useFileInfo();
  const { getItem } = useApiQuery();
  const { createItem, updateItem, deleteItem } = useApiMutation();
  const queryClient = useQueryClient();

  type CreateParams = Parameters<FileRecordActions['create']>[0];
  type DeleteParams = Parameters<FileRecordActions['delete']>[0];
  type DownloadParams = Parameters<FileRecordActions['download']>[0];

  const invalidateQueries = useCallback(
    async (owner: OptionalString, fileRecordId?: OptionalString) => {
      const ownerAlias = owner?.replace(FEDERATED_USER_PREFIX, '');
      const invalidations = [
        queryClient.invalidateQueries(QueryKeys.fileRecord.alias(ownerAlias)),
        queryClient.invalidateQueries(QueryKeys.fileRecord.id(fileRecordId)),
      ];
      await Promise.allSettled(invalidations);
    },
    [queryClient]
  );

  const createMutation = useMutation({
    mutationFn: async (params: CreateParams) => {
      const input: CreateFileRecordInput = {
        ...params,
        status: FileRecordStatus.ACTIVE,
        storageIdKey: session?.identityId ?? '',
      };
      const data = await createItem<CreateFileRecordMutation>(createFileRecordMutation, input, ITEM_TYPE);
      return data?.createFileRecord ? getResourceFromModel(data.createFileRecord as FileRecordModel) : null;
    },
    onSuccess: (result) => invalidateQueries(result?.ownerAlias, result?.id),
  });

  const deleteMutation = useMutation({
    mutationFn: async (params: DeleteParams) => {
      const fileRecord = await getFileRecord(params?.id ?? id);
      if (!fileRecord) return null;

      const updateParams: UpdateFileRecordInput = {
        id: fileRecord.id,
        status: FileRecordStatus.DELETED,
        _version: fileRecord.version,
      };
      const updateResult = await updateItem<UpdateFileRecordMutation>(updateFileRecord, updateParams);
      const inputParams: DeleteFileRecordInput = {
        id: fileRecord.id,
        _version: updateResult?.updateFileRecord?._version ?? 1,
      };
      const data = await deleteItem<DeleteFileRecordMutation>(deleteFileRecordMutation, inputParams, ITEM_TYPE);
      return data?.deleteFileRecord ?? null;
    },
    onSuccess: (result) => invalidateQueries(result?.owner, result?.id),
  });

  const onDownload = useCallback(
    async (params?: DownloadParams) => {
      const { workSummaryFileId } = params ?? {};
      setIsPreparingDownload(true);
      let returnElement: HTMLAnchorElement | null = null;
      try {
        const fileRecord = await getFileInfo(params?.id ?? id);
        if (!fileRecord) return null;
        let downloadUrl: OptionalString;
        if (fileRecord.ownerAlias !== currentUser?.alias) {
          const urlResult = await getItem<GetFileDownloadUrlQueryVariables, GetFileDownloadUrlQuery>({
            query: getFileDownloadUrl,
            input: {
              referenceId: workSummaryFileId,
              fileRecordType: workSummaryFileId ? FileRecordType.WORKSUMMARY : undefined,
              fileRecordId: params?.id ?? id,
            },
          });
          downloadUrl = urlResult?.getFileDownloadUrl.presignedURL;
        } else if (fileRecord.s3Key && fileRecord.name) {
          downloadUrl = await getDownloadLink(fileRecord.s3Key, fileRecord.name);
        }
        if (downloadUrl) {
          const aElement = document.createElement('a');
          aElement.href = downloadUrl || '#';
          aElement.click();
          returnElement = aElement;
        }
      } finally {
        setIsPreparingDownload(false);
      }
      return returnElement;
    },
    [getFileInfo, id, getItem, currentUser?.alias]
  );

  const onCreate = useCallback(async (params: CreateParams) => createMutation.mutateAsync(params), [createMutation]);
  const onDelete = useCallback(async (params: DeleteParams) => deleteMutation.mutateAsync(params), [deleteMutation]);

  const actions: FileRecordActions = useMemo(
    () => ({ create: onCreate, delete: onDelete, download: onDownload }),
    [onCreate, onDelete, onDownload]
  );

  return {
    actions,
    isMutating: createMutation.isLoading || deleteMutation.isLoading,
    isPreparing: isPreparingDownload,
  };
}

export const getFileRecordItemFromModel = getItemFromModel;
