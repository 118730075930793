import { useCallback } from 'react';
import { DateTime } from 'luxon';
import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '@/api/queryKeys';
import setupCognitoSession from '@/backend/auth';
import { Nullable, OptionalString, PromoUserProfile } from '@/models';
import { AuthGroup } from '@/resources/rbac-constants';

const authGroups = Object.values(AuthGroup);

interface CognitoAuthQueryResult {
  user: PromoUserProfile;
  identityId: string;
  idTokenExpiration: number;
  accessTokenExpiration: number;
}

interface CognitoIDTokenIdentity {
  userId: string;
  providerName: string;
  providerType: string;
  issuer: string | null;
  primary: 'true' | 'false';
  dateCreated: string;
}

interface CognitoTokenPayload {
  exp: number;
  auth_time: number;
  iat: number;
  iss: string;
  jti: string;
  sub: string;
}

interface CognitoIDTokenPayload extends CognitoTokenPayload {
  'cognito:groups': AuthGroup[];
  'custom:department_name': string;
  'custom:manager': string;
  'custom:is_manager': string;
  'custom:employee_id': string;
  'custom:hire_date': string;
  'custom:job_level': string;
  'custom:job_title': string;
  given_name: string;
  family_name: string;
  email: string;
  identities: CognitoIDTokenIdentity[];
  validPromoHubUser: 'true' | 'false';
}

interface CachedToken<T> {
  jwtToken: string;
  payload: T;
}

// These representations of a token serialized from a local cache
// aren't 100% true to all the keys available. Just the ones that mattered right now
// If there's something you're looking for, decode the JWT and update the types
interface CachedCognitoSession {
  idToken: CachedToken<CognitoIDTokenPayload>;
  accessToken: CachedToken<CognitoTokenPayload>;
}

function isCachedCognitoSession(value: CognitoUserSession | CachedCognitoSession): value is CachedCognitoSession {
  return 'clockDrift' in value;
}

function calculateRefreshTime(leewaySeconds: number, minimumPollTime: number, expirationTime: number) {
  const currentTime = Math.floor(Date.now() / 1000);
  const expirationWithLeeway = expirationTime - currentTime - leewaySeconds;
  return Math.max(expirationWithLeeway, minimumPollTime);
}

// The number of seconds we should attempt to refresh the tokens before they expire
const leewaySeconds = 60;
// The maximum number of seconds in-between attempts to refresh the tokens.
const minimumPollTime = 15;

// Get the number of seconds until we should need to get a new token
function getAuthRefreshTime(data: Nullable<CognitoAuthQueryResult>): number {
  if (data === undefined || data === null) return minimumPollTime;

  const minExpirationTime = Math.min(data.accessTokenExpiration, data.idTokenExpiration);

  return calculateRefreshTime(leewaySeconds, minimumPollTime, minExpirationTime);
}

function getAliasAndNameFromLDAP(value: OptionalString): { alias: string; name: string } {
  if (!value) return { alias: '', name: '' };
  // The LDAP names are kinda gross so we have to parse manually.
  // ex: cn=Geoff Hatch (geohatch),ou=people,ou=us,o=amazon.com
  const segments = value.split(',')[0].split('=')[1].split(' ');
  const alias = segments.slice(-1)[0].replace('(', '').replace(')', '');
  const name = segments.slice(0, -1).join(' ');
  return { alias, name };
}

export function getOrgAndJobTitle(jobTitle: OptionalString, department: OptionalString): readonly [string, string] {
  let normalizedJobTitle = jobTitle ?? '';
  let normalizedOrgName = department ?? '';
  const locationStartIex = jobTitle?.indexOf('(');
  if (locationStartIex) {
    normalizedJobTitle = jobTitle?.slice(0, locationStartIex).trim() ?? '';
    const location = jobTitle
      ?.slice(locationStartIex)
      .replace('(', '')
      .replace(')', '')
      .split('-')
      .filter((part) => !['AL', 'GL', 'M', 'VP'].includes(part))
      .join('-');
    normalizedOrgName = `${department} - ${location}`;
  }
  return [normalizedJobTitle, normalizedOrgName] as const;
}

function getHireDate(cognitoValue: OptionalString): OptionalString {
  if (!cognitoValue) {
    return undefined;
  }
  return DateTime.fromFormat(cognitoValue, 'dd-MMM-yy', { zone: 'utc' }).toISODate();
}

function hydratePermissionGroups(allGroups: string[], isManager: boolean, isValidUser: boolean): AuthGroup[] {
  const groups = new Set(allGroups.filter((grp): grp is AuthGroup => authGroups.includes(grp as unknown as AuthGroup)));
  if (isManager) {
    groups.add(AuthGroup.MANAGER);
  }
  if (isValidUser) {
    groups.add(AuthGroup.USER);
  }
  return [...groups];
}

function parseIdToken(payload: CognitoIDTokenPayload): PromoUserProfile {
  const isManager = parseInt(payload['custom:is_manager'], 10) === 0;
  const isValidUser = payload.validPromoHubUser === 'true';
  const tokenGroups = payload['cognito:groups'] ?? [];
  const [jobTitle, orgName] = getOrgAndJobTitle(payload['custom:job_title'], payload['custom:department_name']);
  return {
    isManager,
    isValidUser,
    jobTitle,
    orgName,
    alias: payload.identities[0].userId,
    email: payload.email,
    firstName: payload.given_name,
    groups: hydratePermissionGroups(tokenGroups, isManager, isValidUser),
    hireDate: getHireDate(payload['custom:hire_date']),
    jobLevel: parseInt(payload['custom:job_level'], 10),
    lastName: payload.family_name,
    manager: getAliasAndNameFromLDAP(payload['custom:manager']),
    name: `${payload.given_name} ${payload.family_name}`,
    personId: payload['custom:employee_id'],
  };
}

interface UseCognitoAuthResult {
  session: Nullable<CognitoAuthQueryResult>;
  isLoading: boolean;
  refetch: () => Promise<Nullable<CognitoAuthQueryResult>>;
}

export function useCognitoAuth(): UseCognitoAuthResult {
  const auth = useQuery({
    staleTime: 60 * 60 * 1000,
    cacheTime: 60 * 60 * 1000,
    queryKey: QueryKeys.cognito,
    queryFn: async (): Promise<CognitoAuthQueryResult> => {
      const data = await setupCognitoSession();
      let idToken: CognitoIDTokenPayload;
      let idTokenExpiration: number;
      let accessTokenExpiration: number;
      if (isCachedCognitoSession(data)) {
        idToken = data.idToken.payload;
        idTokenExpiration = data.idToken.payload.exp;
        accessTokenExpiration = data.accessToken.payload.exp;
      } else {
        idTokenExpiration = data.getIdToken().getExpiration();
        accessTokenExpiration = data.getAccessToken().getExpiration();
        idToken = data.getIdToken().payload as CognitoIDTokenPayload;
      }
      const credentials = await Auth.currentUserCredentials();
      return {
        accessTokenExpiration,
        idTokenExpiration,
        user: parseIdToken(idToken),
        identityId: credentials.identityId,
      };
    },
    refetchInterval: (data) => getAuthRefreshTime(data) * 1000,
    refetchIntervalInBackground: true,
  });

  const onRefetch = useCallback(async () => (await auth.refetch()).data, [auth]);

  return {
    session: auth.data,
    isLoading: auth.isLoading,
    refetch: onRefetch,
  };
}
