import { Mode } from '@amzn/awsui-global-styles';
import { DateTime, IANAZone, Zone } from 'luxon';
import abbreviations from '../resources/timezone/abbreviations.json';
import { Nullable, OptionalNumber, OptionalString } from './models';
import { CLOUDSCAPE_AUDO_I18N_LANGUAGES } from './constants';

const DEFAULT_FORMAT = 'yyyy-MM-dd HH:mm';
const today = DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

type AWSDateTime = OptionalString;
type AWSTimestamp = OptionalNumber;
type InputDate = Date | AWSDateTime | AWSTimestamp;

function getTimezoneAbbreviation(epochMilis: number, zone: IANAZone) {
  /*
    Use Luxon to get the time zone 'long' name.. i.e. Eastern Standard Time.
    Use the long name to lookup the abbreviation. We can do this w/ Luxon, but
    the value returned is locale-based and unpredictable. So using a preset list ensures consistency.

    The value is normalized based on the epoch date passed in.
      Ex: America/New_York Timezone - in December this returns Eastern Standard Time, and Eastern Daylight Time in July
  */
  const fullTimezoneName = zone.offsetName(epochMilis, { format: 'long' });
  let abbr = '';
  if (fullTimezoneName) {
    abbr = abbreviations[fullTimezoneName];
  }
  if (!abbr && fullTimezoneName) {
    // For locales where only 1 tz is used, the abbreviations data omits the 'Daylight' / 'Standard'
    // modifier, so we strip it here to ensure a match.
    abbr = abbreviations[fullTimezoneName.replace('Standard ', '').replace('Daylight ', '')];
  }
  if (!abbr) {
    // If we got this far and no luck, fall back to the Intl abbreviation from Luxon
    abbr = zone.offsetName(epochMilis, { format: 'short' }) ?? '';
  }
  // sanity check in case we only got an offset
  if (['+', '-'].includes(abbr.charAt(0))) {
    abbr = `UTC${abbr}`;
  }
  return abbr;
}

function formatDateTime(dateTime: Nullable<DateTime>, format?: string): OptionalString {
  if (!dateTime) {
    return undefined;
  }
  return dateTime.toFormat(format || DEFAULT_FORMAT);
}

function calibrateDateTime(value: InputDate, zone: string | Zone = 'local'): Nullable<DateTime> {
  // if no date, bail altogether
  if (!value) {
    return undefined;
  }
  if (typeof value === 'string') {
    return DateTime.fromISO(value, { zone });
  }
  if (typeof value === 'number') {
    return DateTime.fromMillis(value, { zone });
  }
  return DateTime.fromJSDate(value, { zone });
}

function formatDateTimeWithZone(value: InputDate): OptionalString {
  const dateTime = calibrateDateTime(value);
  if (!dateTime) {
    return undefined;
  }
  const abbreviation = getTimezoneAbbreviation(dateTime.toUnixInteger() * 1000, dateTime.zone as IANAZone);
  return `${formatDateTime(dateTime)} ${abbreviation}`;
}

export function getDurationFromNow(value: Nullable<Date>): OptionalString {
  const calibratedDateTime = calibrateDateTime(value);
  if (!calibratedDateTime) {
    return undefined;
  }
  const then = calibratedDateTime.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  return today.diff(then, ['years', 'months', 'days']).toHuman({ unitDisplay: 'short', signDisplay: 'never' });
}

/**
 * Converts an AWSDateTime or AWSTimestamp value from the AppSync API to a localized `Date` object.
 * @param value AWSDateTime or AWSTimestamp from AppSync API. Ex: `2022-11-23T16:57:20.501Z`
 */
export function getDateFromAPIValueUTC(value: AWSDateTime | AWSTimestamp): Nullable<Date> {
  const calibratedDateTime = calibrateDateTime(value, 'utc');
  if (!calibratedDateTime) {
    return undefined;
  }
  return calibratedDateTime.setZone('local').toJSDate();
}

export function getFormattedDate(value: Nullable<Date>): OptionalString {
  return formatDateTimeWithZone(value);
}

export function getAWSDateAsQuarter(awsDate: OptionalString): OptionalString {
  if (!awsDate) {
    return undefined;
  }
  const format = 'Qq, yyyy';
  return formatDateTime(calibrateDateTime(awsDate), format);
}

export function getFormattedAWSTimestamp(awsTimestamp: OptionalNumber): OptionalString {
  if (!awsTimestamp) {
    return undefined;
  }
  return formatDateTimeWithZone(awsTimestamp);
}

export function getSystemTheme(): Mode {
  if (typeof window !== 'undefined' && typeof document !== 'undefined' && typeof localStorage !== 'undefined') {
    const mql = window.matchMedia('(prefers-color-scheme: dark)');
    const hasMediaQueryPreference = typeof mql.matches === 'boolean';
    if (hasMediaQueryPreference) {
      return mql.matches ? Mode.Dark : Mode.Light;
    }
    return Mode.Light;
  }
  return Mode.Light;
}

export function getCompactId(value: OptionalString) {
  return value?.slice(Math.max(0, value.length - 8)) ?? '';
}

export function getSupportedLocale() {
  const locale = document.documentElement.lang;
  return CLOUDSCAPE_AUDO_I18N_LANGUAGES.includes(locale) ? locale : 'en';
}

//in-source test suites
if (import.meta.vitest) {
  const { it, expect, describe } = import.meta.vitest
    describe('getDurationFromNow', () => {
      it('should return formatted date', () => {
        const todayPlusOneYear = new Date();
        todayPlusOneYear.setFullYear(todayPlusOneYear.getFullYear() + 1);
        expect(getDurationFromNow(todayPlusOneYear)).toBe('1 yr, 0 mths, 0 days');
      });
    });
    describe('getSupportedLocale', () => {
      it('should return supported locale', () => {
        expect(getSupportedLocale()).toBe(CLOUDSCAPE_AUDO_I18N_LANGUAGES.includes(document.documentElement.lang) ? document.documentElement.lang : 'en');
      });
    });
    describe('getCompactId', () => {
      it('should return compact id', () => {
        expect(getCompactId('123456789')).toBe('23456789');
        expect(getCompactId('123456789123456789')).toBe('23456789');
      })
    })
  }


