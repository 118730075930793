import { CollectionPreferencesProps } from '@amzn/awsui-components-react/polaris/collection-preferences';
import { noop } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { applyMode, applyDensity, Density, Mode } from '@amzn/awsui-global-styles';
import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import { VisualMode, Density as DensityMode, UserProfileStatus } from '@/api/API';
import { useProfileActions, useUserProfile } from '@/api/user-profile';
import { getSystemTheme } from '@/common/utils';
import { AppContext } from './models';
import { AuthGroup } from '../resources/rbac-constants';
import { Nullable, PromoUserProfile, TableUserPreferences } from '@/models';
import useMap from '@/common/hooks/use-map';
import { useCognitoAuth } from '@/common/hooks/use-auth';
import { QueryKeys } from '@/api/queryKeys';

const Context = React.createContext<AppContext>({
  tablePreferences: new Map(),
  setTablePreferences: noop,
});

const defaultMode = getSystemTheme();

function useAuthenticatedProfile() {
  const { session, isLoading } = useCognitoAuth();
  const { getProfile } = useUserProfile();
  const { createProfile, updateProfile } = useProfileActions();

  const profileQuery = useQuery({
    queryKey: QueryKeys.profile.authenticated(session?.user.alias),
    queryFn: async () => {
      if (!session?.user) return null;
      let profile = await getProfile(session?.user.alias, true);
      const { user, identityId } = session;
      if (!profile) {
        profile = await createProfile({
          identityId,
          alias: user.alias,
          email: user.email,
          name: `${user.firstName} ${user.lastName}`,
          isManager: user.isManager,
          jobLevel: user.jobLevel,
          jobTitle: user.jobTitle,
          manager: user.manager.alias,
          orgName: user.orgName,
          personId: user.personId,
        });
      }
      const isFirstLogin = !profile?.hasLoggedIn;
      let updateParams: Parameters<typeof updateProfile>[0] = { alias: user.alias };
      if (isFirstLogin || profile?.status !== UserProfileStatus.ACTIVE) {
        updateParams = { ...updateParams, hasLoggedIn: true, status: UserProfileStatus.ACTIVE };
      }
      if (
        profile?.manager?.alias !== user.manager?.alias ||
        profile?.isManager !== user.isManager ||
        profile?.jobLevel !== user.jobLevel ||
        profile?.jobTitle !== user.jobTitle ||
        profile?.orgName !== user.orgName ||
        !profile?.identityId ||
        !profile.status
      ) {
        updateParams = {
          ...updateParams,
          identityId,
          isManager: user.isManager,
          jobLevel: user.jobLevel,
          jobTitle: user.jobTitle,
          manager: user.manager.alias,
          orgName: user.orgName,
          status: UserProfileStatus.ACTIVE,
        };
      }
      if (Object.keys(updateParams).length > 1) {
        profile = await updateProfile(updateParams);
      }
      if (profile) profile.groups = session.user.groups;
      return { profile, isFirstLogin };
    },
    enabled: !!session?.user.alias && !isLoading,
  });

  return {
    profile: profileQuery.data?.profile,
    isFirstLogin: profileQuery.data?.isFirstLogin,
    isProfileLoading: profileQuery.isLoading,
  };
}

function setDisplayMode(pref: Nullable<VisualMode>) {
  const mode: Mode = !pref || pref === VisualMode.AUTO ? defaultMode : (pref.toLowerCase() as Mode);
  applyMode(mode, document.documentElement);
  applyMode(mode, document.body);
}

function setContentDensity(pref: Nullable<DensityMode>) {
  const density: Density = pref ? (pref.toLowerCase() as Density) : Density.Comfortable;
  applyDensity(density, document.documentElement);
  applyDensity(density, document.body);
}

// Context provider for the WorkSummariesList component.
// Allows tracking and updating state of various tables on the page.
export const AppContextProvider = ({ children }) => {
  const { profile, isProfileLoading, isFirstLogin } = useAuthenticatedProfile();
  const cognitoAuth = useCognitoAuth();
  const [tablePreferences, tablePreferenceActions] = useMap<string, TableUserPreferences>();
  const [spoofUser, setSpoofUser] = useState<Nullable<PromoUserProfile>>();
  const [themePref, setThemePref] = useState<VisualMode>(VisualMode.AUTO);
  const [densityPref, setDensityPref] = useState<DensityMode>(DensityMode.COMFORTABLE);
  const { getProfile } = useUserProfile();

  const isFinishedLoading = !(isProfileLoading || cognitoAuth.isLoading);

  const onSetSpoofAlias = useCallback(
    (alias: string) => {
      if (isFinishedLoading) {
        getProfile(alias).then((data) => {
          if (!data) return;
          const groups = [AuthGroup.USER];
          if (data.isManager) {
            groups.push(AuthGroup.MANAGER);
          }
          const user: PromoUserProfile = { ...data, groups };
          setSpoofUser((prevUser) => (prevUser?.alias === user?.alias ? prevUser : user));
        });
      }
    },
    [isFinishedLoading, getProfile]
  );

  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
    if (themePref === VisualMode.AUTO) {
      applyMode(event.matches ? Mode.Dark : Mode.Light);
    }
  });

  useEffect(() => {
    if (profile?.preferences?.density) {
      setDensityPref(profile?.preferences?.density);
    }

    if (profile?.preferences?.visualMode) {
      setThemePref(profile?.preferences?.visualMode);
    }
  }, [profile?.preferences?.density, profile?.preferences?.visualMode]);

  useEffect(() => {
    setDisplayMode(themePref);
  }, [themePref]);

  useEffect(() => {
    setContentDensity(densityPref);
  }, [densityPref]);

  const setTablePreferences = useCallback(
    (tableKey: string, prefs: CollectionPreferencesProps.Preferences) => {
      const { pageSize, visibleContent, custom } = prefs;
      const current = tablePreferences.get(tableKey);
      if (
        !(
          (current?.visibleContent ?? []).every((opt) => (visibleContent ?? []).includes(opt)) &&
          (visibleContent ?? []).every((opt) => (current?.visibleContent ?? []).includes(opt)) &&
          current?.pageSize === pageSize &&
          current?.custom === custom
        )
      ) {
        tablePreferenceActions.set(tableKey, { custom, pageSize, visibleContent: (visibleContent ?? []).slice() });
      }
    },
    [tablePreferenceActions, tablePreferences]
  );
  const context: AppContext = useMemo(
    () => ({
      spoofUser,
      tablePreferences,
      setTablePreferences,
      isFinishedLoading,
      currentUser: profile,
      updateDensity: setDensityPref,
      updateVisualMode: setThemePref,
      visualModePreference: themePref,
      densityPreference: densityPref,
      setSpoofAlias: onSetSpoofAlias,
    }),
    [
      tablePreferences,
      setTablePreferences,
      densityPref,
      themePref,
      profile,
      onSetSpoofAlias,
      spoofUser,
      isFinishedLoading,
    ]
  );
  return <Context.Provider value={context}>{children}</Context.Provider>;
};

/** custom hook to provide easy access/use of the context */
export function useAppContext() {
  return useContext(Context);
}
