import { PaginationProps } from '@amzn/awsui-components-react/polaris/pagination';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { capitalize } from 'lodash';
import { Item, ResourceDefinition, ResourceNameDefinition } from '@/models';

export function getExternalLinkAriaLabel(): string {
  return 'Opens in a new tab';
}

export function getGroupValueLabel(baseLabel: string): string {
  return `${baseLabel} value`;
}

/** Formats label text based on the {@link ResourceNameDefinition} and total number of items chosen. */
function formatColumnHeader(count: number, name: ResourceNameDefinition): string {
  return `${count} ${count === 1 ? name.shortName : name.shortNamePlural} selected`;
}

/** Formats label text based on whether the active item is the selected one. */
function formatSelectionText<ItemDef extends Item>(
  selectedItems: readonly ItemDef[],
  currentItem: ItemDef,
  comparatorKey: keyof ItemDef
): string {
  const isItemSelected = selectedItems.length > 0 && selectedItems[0][comparatorKey] === currentItem[comparatorKey];
  return `${currentItem.id} is ${isItemSelected ? '' : 'not'} selected`;
}

function pluralize(value: string): string {
  let valuePlural = `${value}es`;
  if (value.endsWith('y')) {
    valuePlural = `${value.slice(0, -1)}ies`;
  } else if (
    value.endsWith('e') ||
    value.endsWith('ck') ||
    value.endsWith('t') ||
    value.endsWith('th') ||
    value.endsWith('l') ||
    value.endsWith('w') ||
    value.endsWith('m')
  ) {
    valuePlural = `${value}s`;
  }
  return valuePlural;
}

/** Takes the given {@link ResourceDefinition} and returns a {@link ResourceNameDefinition} */
export function getNameDefinition(resource: ResourceDefinition): ResourceNameDefinition {
  const fullName = resource.resourceName.toLowerCase();
  const shortName = resource.resourceShortName?.toLowerCase() || fullName;
  const fullNamePlural = pluralize(fullName);
  const shortNamePlural = pluralize(shortName);
  return { fullName, shortName, fullNamePlural, shortNamePlural };
}

export function getFilterCountTextLabel(itemCount?: number): string {
  return `${itemCount} match${itemCount === 1 ? '' : 'es'}`;
}

/** Create text labels for use with the table component. */
export function getFilterLabels(name: ResourceNameDefinition, itemCount?: number) {
  return {
    countText: getFilterCountTextLabel(itemCount),
  };
}

/** Creates pagination label text for a table. Text is customized based on total page count. */
export function getPaginationLabels(pageCount: number): PaginationProps.Labels {
  return {
    nextPageLabel: 'Next page',
    previousPageLabel: 'Previous page',
    pageLabel: (pageNumber: number) => `Page ${pageNumber} of ${pageCount || 'all'} pages`,
  };
}

/** Creates aria label text for a table; specifically for use in selection state of rows/columns. */
export function getSelectionLabels<ItemDef extends Item>(resource: ResourceDefinition): TableProps.AriaLabels<ItemDef> {
  const name = getNameDefinition(resource);
  return {
    itemSelectionLabel: ({ selectedItems }, row) =>
      formatSelectionText(selectedItems, row, resource.idKey as keyof ItemDef),
    allItemsSelectionLabel: ({ selectedItems }) => formatColumnHeader(selectedItems.length, name),
    selectionGroupLabel: `${capitalize(name.fullNamePlural)} selection`,
  };
}

export function getLabelFromEnum(value: string) {
  return capitalize(value.replaceAll('_', ' '));
}

export function getReadableFromEnum(value: string): string {
  return value.replace('_', ' ').toLowerCase();
}


// in-source test suites
if (import.meta.vitest) {
  const { it, expect, describe } = import.meta.vitest
  describe('labels', () => {
    it('should return a readable label from an enum', () => {
      expect(getReadableFromEnum('TEST_ENUM')).toEqual('test enum')
    })

    it('getLabelFromEnum should return a readable label from an enum', () => {
      expect(getLabelFromEnum('TEST_ENUM')).toEqual('Test enum')
    })

    it('getSelectionLabels should return a readable label from a ResourceDefinition', () => {
      const resource = {
        idKey: 'id',
        resourceName: 'test resource',
        resourceShortName: 'test resource short',
      }
      const labels = getSelectionLabels(resource)
      expect(labels.selectionGroupLabel).toEqual('Test resources selection')
    })

    it('should return pagination labels', () => {
      const labels = getPaginationLabels(5)
      expect(labels.nextPageLabel).toEqual('Next page')
      expect(labels.previousPageLabel).toEqual('Previous page')
      expect(labels.pageLabel?.(1)).toEqual('Page 1 of 5 pages')
      expect(labels.pageLabel?.(3)).toEqual('Page 3 of 5 pages')
    })

    it('should return filter labels', () => {
      const labels = getFilterLabels({
        fullName: 'test name',
        shortName: 'test short name',
        fullNamePlural: 'test name plural',
        shortNamePlural: 'test short name plural',
      }, 5)
      expect(labels.countText).toEqual('5 matches')

    })

    it('should return a name definition', () => {
      const name = getNameDefinition({
        idKey: 'id',
        resourceName: 'test resource',
        resourceShortName: 'trs',
      })
      expect(name.fullName).toEqual('test resource')
      expect(name.shortName).toEqual('trs')
      expect(name.fullNamePlural).toEqual('test resources')
      expect(name.shortNamePlural).toEqual('trses')
    })
  })
}
