/* eslint-disable no-underscore-dangle */
import { useCallback, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  CreatePromoPathInput,
  UpdatePromoPathInput,
  CreatePromoPathMutation,
  UpdatePromoPathMutation,
  GetPromoPathQueryVariables,
  GetPromoPathQuery,
  ListPromoPathsByOwnerQuery,
  ListPromoPathsByOwnerQueryVariables,
  Category as CategoryModel,
  PromoPath,
  DeletePromoPathInput,
  DeletePromoPathMutation,
  PathDefinitionType,
  PromoPathStatus,
} from './API';
import { listPromoPathsByOwner, getPromoPath as getPromoPathQuery } from '../graphql/queries';
import {
  createPromoPath as createPromoPathMutation,
  updatePromoPath as updatePromoPathMutation,
  deletePromoPath as deletePromoPathMutation,
} from '../graphql/mutations';
import { ManagerPathItem, OptionalString, ManagerPathResource } from '@/models';
import { useApiMutation, useApiQuery } from '@/backend/api';
import { RESOURCES } from '@/common/constants';
import { getCategoryResourceFromModel } from './category';
import { QueryKeys } from './queryKeys';
import { getCompactId, getDateFromAPIValueUTC } from '@/common/utils';
import { useNotifications } from '@/contexts';
import {
  ManagerPromoPathActions,
  UseManagerPromoPathActionsResult,
  UseManagerPromoPathResult,
  UseManagerPromoPathsResult,
} from './models';

const ITEM_TYPE = RESOURCES.PROMOPATH.resourceName;

type PromoPathRequired = Required<Pick<PromoPath, 'name' | 'role' | 'manager' | 'startLevel' | 'targetLevel'>>;
type PromoPathModel = PromoPath & {
  [Property in keyof PromoPathRequired]: Exclude<PromoPathRequired[Property], null>;
};

function getItemFromModel(model: PromoPathModel): ManagerPathItem {
  return {
    id: model.id,
    createdAt: getDateFromAPIValueUTC(model.createdAt),
    updatedAt: getDateFromAPIValueUTC(model.updatedAt),
    name: model.name,
    suggestedLPs: model.suggestedLPs ?? [],
    role: model.role,
    startLevel: model.startLevel,
    targetLevel: model.targetLevel,
    allowsAttachments: typeof model.allowAttachments === 'boolean' ? model.allowAttachments : true,
    candidateCount: model.descendants?.items.length ?? 0,
    version: model._version ?? 1,
    status: model.status,
    managerAlias: model.manager,
    type: PathDefinitionType.MANAGER,
  };
}

function getResourceFromModel(model: PromoPathModel): ManagerPathResource {
  return {
    ...getItemFromModel(model),
    totalRequired: model.totalRequired,
    categories: (model.categories?.items || [])
      .filter((item) => item !== null)
      .map((item) => getCategoryResourceFromModel(item as CategoryModel)),
  };
}

export function useManagerPromoPaths(alias: OptionalString): UseManagerPromoPathsResult {
  const { getItems } = useApiQuery();

  const query = useQuery({
    meta: { errorMessage: `Error loading promo path templates for: ${alias}@` },
    queryKey: QueryKeys.promoPath.manager(alias),
    queryFn: async () => {
      if (!alias) return [];
      const data = await getItems<ListPromoPathsByOwnerQueryVariables, ListPromoPathsByOwnerQuery>({
        query: listPromoPathsByOwner,
        input: { manager: alias },
      });
      return (data?.listPromoPathsByManagerAndType?.items ?? [])
        .filter((item): item is PromoPathModel => !!item)
        .map((item) => getItemFromModel(item));
    },
    enabled: !!alias,
  });

  return {
    promoPaths: query.data ?? [],
    isPromoPathsLoading: !!alias && query.isLoading,
  };
}

export function useManagerPromoPath(id: OptionalString): UseManagerPromoPathResult {
  const { getItem } = useApiQuery();

  const query = useQuery({
    meta: { errorMessage: `Error fetching promo path: ${getCompactId(id)}` },
    queryKey: QueryKeys.promoPath.id(id).all,
    queryFn: async () => {
      if (!id) return null;
      const data = await getItem<GetPromoPathQueryVariables, GetPromoPathQuery>({
        query: getPromoPathQuery,
        input: { id },
      });
      return data?.getPromoPath ? getResourceFromModel(data.getPromoPath as PromoPathModel) : null;
    },
    enabled: !!id,
  });

  const onFetchLatest = useCallback(async () => (await query.refetch()).data, [query]);

  return {
    promoPath: query.data,
    isPromoPathLoading: !!id && query.isLoading,
    fetchLatest: onFetchLatest,
  };
}

export function useManagerPromoPathActions(id?: OptionalString): UseManagerPromoPathActionsResult {
  const { createItem, updateItem, deleteItem } = useApiMutation();
  const { fetchLatest } = useManagerPromoPath(id);
  const { addNotification } = useNotifications();
  const queryClient = useQueryClient();

  type CreateParams = Parameters<ManagerPromoPathActions['create']>[0];
  type UpdateParams = Parameters<ManagerPromoPathActions['update']>[0];

  const invalidateQueries = useCallback(
    async (promoPathId: OptionalString, alias: OptionalString) => {
      const invalidations = [
        queryClient.invalidateQueries(QueryKeys.promoPath.id(promoPathId).all),
        queryClient.invalidateQueries(QueryKeys.promoPath.manager(alias)),
      ];
      await Promise.allSettled(invalidations);
    },
    [queryClient]
  );

  const createMutation = useMutation({
    mutationFn: async (params: CreateParams) => {
      const input: CreatePromoPathInput = {
        ...params,
        pathType: PathDefinitionType.MANAGER,
        status: PromoPathStatus.ACTIVE,
      };
      const data = await createItem<CreatePromoPathMutation>(createPromoPathMutation, input, ITEM_TYPE);
      return data?.createPromoPath ? getResourceFromModel(data?.createPromoPath as PromoPathModel) : null;
    },
    onSuccess: (result) => {
      addNotification({ type: 'success', header: `Successfully created promo path: ${result?.name}` });
      invalidateQueries(result?.id, result?.managerAlias);
    },
  });

  const updateMutation = useMutation({
    mutationFn: async (params: UpdateParams) => {
      const promoPath = await fetchLatest();
      if (!promoPath) return null;
      const input: UpdatePromoPathInput = {
        ...params,
        id: promoPath.id,
        pathType: params.status && !params.pathType ? PathDefinitionType.MANAGER : params.pathType,
        _version: promoPath.version,
      };
      const data = await updateItem<UpdatePromoPathMutation>(updatePromoPathMutation, input, ITEM_TYPE);
      return data?.updatePromoPath ? getResourceFromModel(data?.updatePromoPath as PromoPathModel) : null;
    },
    onSuccess: (result) => {
      addNotification({ type: 'success', header: `Successfully updated promo path: ${result?.name}` });
      invalidateQueries(result?.id, result?.managerAlias);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async () => {
      const promoPath = await fetchLatest();
      if (!promoPath) return null;
      const updateParams: UpdatePromoPathInput = {
        id: promoPath.id,
        pathType: PathDefinitionType.MANAGER,
        status: PromoPathStatus.DELETED,
        _version: promoPath.version,
      };
      const updateResult = await updateItem<UpdatePromoPathMutation>(updatePromoPathMutation, updateParams, ITEM_TYPE);
      const input: DeletePromoPathInput = { id: promoPath.id, _version: updateResult?.updatePromoPath?._version };
      const data = await deleteItem<DeletePromoPathMutation>(deletePromoPathMutation, input, ITEM_TYPE);
      return data?.deletePromoPath ?? null;
    },
    onSuccess: (result) => {
      addNotification({ type: 'success', header: `Successfully deleted promo path: ${result?.name}` });
      invalidateQueries(result?.id, result?.manager);
    },
  });

  const onCreate = useCallback(async (params: CreateParams) => createMutation.mutateAsync(params), [createMutation]);
  const onUpdate = useCallback(async (params: UpdateParams) => updateMutation.mutateAsync(params), [updateMutation]);
  const onDelete = useCallback(async () => deleteMutation.mutateAsync(), [deleteMutation]);

  const actions = useMemo(
    () => ({ create: onCreate, update: onUpdate, delete: onDelete }),
    [onCreate, onUpdate, onDelete]
  );

  return {
    actions,
    isMutating: createMutation.isLoading || updateMutation.isLoading || deleteMutation.isLoading,
  };
}
